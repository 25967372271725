import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { matchPath, useHistory, useLocation } from 'react-router';

import B2BPlacementTestWarningModal from '../components/Login/B2BPlacementTestWarningModal';
import { AUTH_COOKIE_DOMAIN, AUTH_TOKEN_KEY, AUTH_TOKEN_KEY_B2C, AWEB_URL } from '../constants';
import { LOGIN, LOGOUT, SET_PURCHASER } from '../graphql/mutations';
import { PURCHASE, PURCHASE_ADDRESS, PURCHASES } from '../graphql/queries';
import { getParams } from '../helpers/router';
import { isSsr } from '../helpers/ssr';
import { paths } from '../paths';
import { AuthContext } from '../store/Auth';
import ModalContext from '../store/Modal/ModalContext';
import { useBasketCookie, useRouter } from './index';

const useAuth = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const apolloClient = useApolloClient();
  const { showModal, hideModal } = useContext(ModalContext);
  const location = useLocation();
  const {
    generatePath,
    currentRoute: { params },
  } = useRouter();
  const { getBasket } = useBasketCookie();
  const history = useHistory();
  const registrationId = getBasket();
  const { registrationId: registrationIdQueryParam } = getParams(location.search);
  const [, setCookie, removeCookie] = useCookies([AUTH_TOKEN_KEY, AUTH_TOKEN_KEY_B2C]);

  const resetQueries = async () => {
    if (registrationIdQueryParam || registrationId) {
      await apolloClient.mutate({
        mutation: SET_PURCHASER,
        variables: {
          registrationId: registrationIdQueryParam ? registrationIdQueryParam : registrationId,
        },
        refetchQueries: [{ query: PURCHASES, fetchPolicy: 'network-only' }],
        awaitRefetchQueries: true,
      });
    }

    if (registrationId) {
      let promises = [];
      promises.push(
        apolloClient.query({
          query: PURCHASE,
          variables: { registrationId },
          fetchPolicy: 'network-only',
        })
      );
      promises.push(
        apolloClient.query({
          query: PURCHASE_ADDRESS,
          variables: { registrationId },
          fetchPolicy: 'network-only',
        })
      );
      let [purchase, purchaseAddress] = await Promise.all(promises);
      promises = [];
      const { data } = purchase;
      promises.push(
        apolloClient.writeQuery({
          query: PURCHASE,
          variables: { registrationId },
          data: {
            ...data,
          },
        })
      );

      if (purchaseAddress) {
        const { data } = purchaseAddress;
        promises.push(
          apolloClient.writeQuery({
            query: PURCHASE_ADDRESS,
            variables: { registrationId },
            data: {
              ...data,
            },
          })
        );
      }

      return Promise.all(promises);
    }
  };

  const login = async ({ userData }) => {
    const {
      data: { authResult },
    } = await apolloClient.mutate({
      mutation: LOGIN,
      variables: userData,
    });

    if (authResult.__typename === 'Error') {
      throw new Error(authResult.reason);
    }

    return handleLogin({ user: authResult.user, token: authResult.token });
  };

  const displayB2BPlacementTestWarningModal = (referrer) => {
    showModal(B2BPlacementTestWarningModal, {
      onClose: hideModal,
      onAccept: () => {
        hideModal();
        history.push(generatePath(paths.REGISTRATION, {}, encodeURI(`?referrer=${referrer}`)));
      },
    });
  };

  const handleLogin = ({ user, token }) => {
    setCookie(AUTH_TOKEN_KEY, token, { domain: AUTH_COOKIE_DOMAIN });

    if (user.isB2B) {
      const { referrer } = getParams(location.search);
      const pathIsPracticeTest = matchPath(referrer, {
        path: paths.PRACTICE_TEST,
      });
      if (pathIsPracticeTest?.isExact) {
        displayB2BPlacementTestWarningModal(referrer);

        return;
      }

      return (window.location = AWEB_URL);
    }

    setCookie(AUTH_TOKEN_KEY_B2C, token);

    return resetQueries();
  };

  const getTargetLocation = ({ targetLocation }) => {
    if (targetLocation) {
      return targetLocation;
    }

    const { registrationId: registrationIdQueryParam } = getParams(location.search);

    // if logged in or account created after purchase finished as anonymous
    if (registrationIdQueryParam) {
      return generatePath(paths.ACCOUNT_PURCHASES, params);
    }

    // if has registrationId cookie, redirect to buying process summary
    const registrationIdCookie = getBasket();
    if (registrationIdCookie) {
      return generatePath(paths.PURCHASE_SUMMARY, {
        registrationId: registrationIdCookie,
      });
    }

    return generatePath(paths.ACCOUNT_INCOMING_APPOINTMENTS_SESSIONS);
  };

  const logout = async (withRedirect = true, accountDeletionRequested = false) => {
    removeCookie('registrationId');
    removeCookie(AUTH_TOKEN_KEY, { domain: AUTH_COOKIE_DOMAIN, path: '/' });
    removeCookie(AUTH_TOKEN_KEY_B2C, { path: '/' });

    await apolloClient.mutate({
      mutation: LOGOUT,
    });

    if (isSsr) {
      return;
    }

    if (accountDeletionRequested) {
      window.location = `${generatePath(paths.HOME)}?account-deleted=true`;
      return;
    }

    if (withRedirect) {
      window.location = generatePath(paths.HOME);
    }
  };

  return {
    logout,
    login,
    handleLogin,
    getTargetLocation,
    isAuthenticated,
  };
};

export default useAuth;
