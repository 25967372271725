import 'flatpickr/dist/flatpickr.css';
import './DatePickerField.scss';

import flatpickr from 'flatpickr';
import French from 'flatpickr/dist/l10n/fr';
import Polish from 'flatpickr/dist/l10n/pl';
import pick from 'lodash/pick';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SITE_LOCALES } from '../../../../constants';
import { formatDate } from '../../../../helpers/Formatter/DateFormatter';
import { allowedTextFieldProps, TextField, TextFieldProps } from '../Text';

interface DatepickerFieldOptions {
  /**
   * The language of datepicker field
   */
  language?: string;
  /**
   * The method called when the value changes of datepicker field
   */
  setFieldValue: (value: Date) => void;
  /**
   * The value of datepicker field
   */
  value?: string | Date;
  /**
   * The default date of datepicker field
   */
  defaultDate?: Date;
  /**
   * The minimum date of datepicker field
   */
  minDate?: string | Date;
  /**
   * The maximum date of datepicker field
   */
  maxDate?: string | Date;
}

export interface DatepickerFieldProps extends DatepickerFieldOptions, Omit<TextFieldProps, 'value'> {}

const castStringToDate = (value?: string | Date): null | Date =>
  value ? (typeof value === 'string' ? new Date(value) : value) : null;

export const DatePickerField: React.FC<DatepickerFieldProps> = React.forwardRef<HTMLInputElement, DatepickerFieldProps>(
  (
    {
      language: defaultLanguage,
      defaultDate = new Date(),
      minDate,
      maxDate,
      setFieldValue,
      value: defaultValue,
      ...props
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const language = defaultLanguage || i18n.language;
    const allowedProps = pick(props, allowedTextFieldProps);
    const calendar = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const textFieldRef = ref ?? useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<Date>(castStringToDate(defaultValue));

    const dateToString = useMemo(() => {
      let valueStr = '';
      if (value) {
        valueStr = formatDate({
          language,
          date: value,
          format: 'DD/MM/YYYY',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }
      return valueStr;
    }, [language, value]);

    useEffect(() => {
      setValue(castStringToDate(defaultValue));
    }, [defaultValue]);

    useEffect(() => {
      switch (language) {
        case SITE_LOCALES.FRENCH:
          flatpickr.localize(French.default);
          break;
        case SITE_LOCALES.POLISH:
          flatpickr.localize(Polish.default);
          break;
      }

      calendar.current = flatpickr((textFieldRef as RefObject<HTMLInputElement>).current, {
        defaultDate: value ? value.toISOString() : defaultDate.toISOString(),
        minDate: minDate ? minDate : null,
        shorthandCurrentMonth: true,
        disableMobile: true,
        locale: language as flatpickr.Options.LocaleKey,
        dateFormat: 'd/m/Y',
        onChange: (selectedDates) => {
          if (selectedDates?.[0]) {
            setFieldValue(selectedDates[0]);
            closeCalendar();
          }
        },
        maxDate: maxDate ? maxDate : null,
      });

      return () => {
        calendar.current.destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const openCalendarOnInputFocus = (): NodeJS.Timeout =>
      setTimeout(() => {
        calendar.current.open();
      }, 0);

    const closeCalendar = (): NodeJS.Timeout =>
      setTimeout(() => {
        calendar.current.close();
      }, 0);

    return (
      <TextField {...allowedProps} defaultValue={dateToString} ref={textFieldRef} onFocus={openCalendarOnInputFocus} />
    );
  }
);
