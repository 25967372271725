import { gql } from '@apollo/client';

import { Image, Link, Meta, TestTypeFormatContent } from '../fragments';

export const TEST_TYPE_FAMILIES = gql`
  query TEST_TYPE_FAMILIES(
    $country: String!
    $lang: String!
    $testTypeIds: [ID]
    $withTestTypes: Boolean = true
    $forPartners: Boolean = false
  ) {
    testTypeFamilies(country: $country, lang: $lang, testTypeIds: $testTypeIds, forPartners: $forPartners) {
      id
      name
      shortDescription(country: $country, lang: $lang)
      order
      color
      testTypes @include(if: $withTestTypes) {
        id
        program @skip(if: $forPartners)
        format @skip(if: $forPartners)
        shortDescription: programShortDescription(country: $country, lang: $lang)
        pbtContent {
          ...TestTypeFormatContent
        }
        cbtContent {
          ...TestTypeFormatContent
        }
        isEproctored
        scoreReport {
          url
        }
      }
    }
  }
  ${TestTypeFormatContent}
`;

export const TEST_TYPE_FAMILY = gql`
  query TEST_TYPE_FAMILY($slug: String!, $lang: String!, $country: String!) {
    testTypeFamily(slug: $slug, lang: $lang, country: $country) {
      id
      publicationDate
      slug
      marketingHook
      name
      baseline
      description(country: $country, lang: $lang)
      images {
        ...Image
      }
      doYouKnowBox(country: $country, lang: $lang)
      ifNoSessionContent {
        shortText
        link {
          ...Link
        }
      }
      links {
        ...Link
      }
      testimonial {
        title
        subtitle
      }
      testTypes {
        id
        isEproctored
      }
      sections {
        title
        description(country: $country, lang: $lang)
      }
      informationBoxContent(country: $country, lang: $lang)
      informationBoxFiles {
        ...Link
      }
      color
      profiles(lang: $lang) {
        slug
        title
        marketingHook
        description: shortDescription(country: $country, lang: $lang)
        images {
          ...Image
        }
        scenarios {
          editorialContentHighlighted {
            ... on Testimonial {
              quote: title
              author: subtitle
              images {
                ...Image
              }
              smallImage {
                ...Image
              }
            }
          }
        }
      }
      meta {
        ...Meta
      }
      redirectLink {
        ...Link
      }
    }
  }
  ${Image}
  ${Link}
  ${Meta}
`;
