import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';
import BaseModal from '../Base/Modal/BaseModal';

const B2BPlacementTestWarningModal = ({ onClose, onAccept }) => {
  const buttons = (
    <div data-cypress={'b2b-placement-test-warning-modal'}>
      <Button name="button" className={'button--transparent-light'} onClick={onClose}>
        <Trans>common.cancel</Trans>
      </Button>
      <Button name="button" onClick={onAccept}>
        <Trans>b2b-placement-test-warning-modal.create-new-account</Trans>
      </Button>
    </div>
  );

  return (
    <BaseModal isOpen onClose={onClose} title={<Trans>b2b-placement-test-warning-modal.title</Trans>} footer={buttons}>
      <Trans>b2b-placement-test-warning-modal.description</Trans>
    </BaseModal>
  );
};

B2BPlacementTestWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default B2BPlacementTestWarningModal;
