import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import { Icon } from '@ets-global/b2c-website-ui';
import { Tooltip } from '../../../../components/Base';

import ErrorMessage from '../../../../components/Base/ErrorMessage/ErrorMessage';

import './styles.scss';

const CheckboxField = ({
  value,
  label,
  icon,
  className,
  hasError,
  errorMessage,
  required,
  tooltipContent,
  ...props
}) => {
  const allowedCheckboxFieldProps = pick(props, [
    'id',
    'name',
    'disabled',
    'onChange',
    'onBlur',
    'onFocus',
    'data-cypress',
  ]);

  return (
    <div
      className={classNames(
        'checkbox',
        { 'checkbox--disabled': props.disabled, 'checkbox--error': hasError },
        className,
      )}
    >
      <input className={'checkbox__element'} type="checkbox" checked={value} {...allowedCheckboxFieldProps} />
      <label htmlFor={props.id} className={'checkbox__label'}>
        <span>
          <span className={classNames({ 'label-required': required })}>{label}</span>
          {icon && !tooltipContent && <Icon name={icon} />}
          {tooltipContent && (
            <Tooltip className={'checkbox__tooltip'} position={'top-right'} icon={icon} width={'400px'}>
              <p>{tooltipContent}</p>
            </Tooltip>
          )}
        </span>
      </label>
      {hasError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

CheckboxField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
};

CheckboxField.defaultProps = {
  value: 0,
  onChange: () => {},
};

export default CheckboxField;
