import React from 'react';
import { useLocation } from 'react-router';

import { LocalizedRedirect } from '../../components/Routing';
import { B2C_WEBSITE_URL } from '../../constants';
import { getParams } from '../../helpers/router';
import { useAuth } from '../../hooks';

function cantAccessLogged(WrappedComponent) {
  const withDisplayName = `cantAccessLogged(${getDisplayName(WrappedComponent)})`;

  const CantAccessLogged = (props) => {
    const location = useLocation();
    const { getTargetLocation, isAuthenticated } = useAuth();

    const { referrer } = getParams(location.search);
    const targetLocation = getTargetLocation({ targetLocation: referrer });
    const url = new URL(targetLocation, B2C_WEBSITE_URL);

    if (isAuthenticated) {
      return (
        <LocalizedRedirect
          to={{
            pathname: url.pathname,
            search: url.search.substring(1),
            state: { isLogged: true },
          }}
        />
      );
    }

    return <WrappedComponent targetLocation={targetLocation} {...props} />;
  };

  CantAccessLogged.displayName = withDisplayName;

  return CantAccessLogged;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default cantAccessLogged;
