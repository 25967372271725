import './styles.scss';

import classNames from 'classnames';
import PrismicDOM from 'prismic-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { isUrlExternal } from '../../../@ets-global/helper/urlChecker';
import BrokenLinkError from '../../../helpers/Errors/BrokenLinkError';
import { replaceExponentSymbol } from '../../../helpers/Formatter/StringFormatter';
import { useRouter } from '../../../hooks';

const prismicLinkResolver =
  (generatePrismicDocumentTypeOrCustomLinkPath, urlAbsolute = false) =>
  (doc) =>
    generatePrismicDocumentTypeOrCustomLinkPath({
      documentType: doc.type.toUpperCase(),
      slug: doc.uid,
      customLinkParams: doc.params,
      absolute: urlAbsolute,
    });

const PrismicRichText = ({ richText, className, urlAbsolute = false, onlyText = false, forceTargetBlank = false }) => {
  const { generatePrismicDocumentTypeOrCustomLinkPath } = useRouter();

  const htmlSerializer = (type, element, content, children) => {
    const Elements = PrismicDOM.RichText.Elements;

    switch (type) {
      // Add target blank if external links
      case Elements.hyperlink: {
        let linkUrl = '';
        let target = '';

        try {
          const linkResolver = prismicLinkResolver(generatePrismicDocumentTypeOrCustomLinkPath, urlAbsolute);
          linkUrl = PrismicDOM.Link.url(element.data, linkResolver);
          target = isUrlExternal(linkUrl) || forceTargetBlank ? 'target="_blank" rel="noopener"' : '';
        } catch (error) {
          if (!error instanceof BrokenLinkError) {
            throw error;
          }
        }

        return `<a class="link rte__link" href="${linkUrl}" ${target}>${replaceExponentSymbol(content)}</a>`;
      }
      case Elements.paragraph:
        return onlyText ? children.join('') : `<p class="rte__paragraph">${children.join('')}</p>`;
      case Elements.strong:
        return `<strong class="rte__strong">${children.join('')}</strong>`;
      case Elements.list:
        return `<ul class="rte__list">${children.join('')}</ul>`;
      case Elements.listItem:
        return `<li class="rte__list-item">${children.join('')}</li>`;
      case Elements.span:
        return content ? content.replace(/\n/g, '<br />') : '';
      default:
        return null;
    }
  };

  if (!richText) {
    return;
  }

  if (onlyText) {
    return (
      <span
        className={classNames('rte', className)}
        dangerouslySetInnerHTML={{
          __html: replaceExponentSymbol(PrismicDOM.RichText.asHtml(richText, null, htmlSerializer)),
        }}
      />
    );
  }

  return (
    <div
      className={classNames('rte', className)}
      dangerouslySetInnerHTML={{
        __html: replaceExponentSymbol(PrismicDOM.RichText.asHtml(richText, null, htmlSerializer)),
      }}
    />
  );
};

PrismicRichText.propType = {
  richText: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default PrismicRichText;
