import React, { useContext } from 'react';

import { LocalizedRedirect } from '../../components/Routing';
import { setParam } from '../../helpers/router';
import { paths } from '../../paths';
import { AuthContext } from '../../store/Auth';

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

function authenticated(WrappedComponent) {
  const withDisplayName = `authenticated(${getDisplayName(WrappedComponent)})`;

  const Authenticated = (props) => {
    const { isAuthenticated } = useContext(AuthContext);

    if (!isAuthenticated) {
      return (
        <LocalizedRedirect
          to={{
            pathname: paths.LOGIN,
            search: setParam({
              search: props.location.search,
              key: 'referrer',
              value: props.location.pathname,
            }),
          }}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };

  Authenticated.displayName = withDisplayName;

  return Authenticated;
}

export default authenticated;
