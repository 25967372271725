import { gql } from '@apollo/client';

export const CONFIRM_PURCHASE = gql`
  mutation CONFIRM_PURCHASE(
    $registrationId: String!
    $locale: String!
    $backUrl: String!
    $cancelUrl: String!
    $confirmationUrl: String!
    $initializePayment: Boolean
    $consent: CookieConsentInput
  ) {
    confirmPurchase(
      registrationId: $registrationId
      locale: $locale
      backUrl: $backUrl
      cancelUrl: $cancelUrl
      confirmationUrl: $confirmationUrl
      initializePayment: $initializePayment
      consent: $consent
    ) {
      ... on Payment {
        validationUrl
      }
      ... on PurchaseCanNotBeConfirmed {
        errors
        errorList
        errorCodes
        parameters
      }
    }
  }
`;
