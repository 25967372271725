import { gql } from '@apollo/client';

import { CANDIDATE_FRAGMENT } from './Account/appointmentsSessions';
import { PURCHASE_LINE_FRAGMENT, PURCHASE_TAX_FRAGMENT } from './Account/purchases';
import { PREPTOOL_VERSION_FRAGMENT } from './prepToolsVersions';
import { VOUCHER_FRAGMENT } from './voucher';

export const PRODUCT_AND_SERVICE_FRAGMENT = gql`
  fragment ProductAndService on ProductAndService {
    id
    name
    translationKey
    logisticCode
    price {
      value
      currency
    }
  }
`;

export const OPEN_SESSION = gql`
  query OPEN_SESSION($sessionId: ID!, $lang: String!, $country: String!, $discount: DiscountInput) {
    openSession(id: $sessionId, lang: $lang, discount: $discount) {
      id
      startAt
      scheduledAt
      endAt
      duration
      timeZone
      requiresDeliveryAddress
      testType {
        id
        program
        format
        isPhotoRequired
        isDateOfBirthRequired
        translationKey
        hideFromPublicLists
      }
      testTypeFamily(lang: $lang) {
        id
        name
        color
        shortDescription(country: $country, lang: $lang)
        testTypes(lang: $lang, country: $country) {
          id
          cbtContent {
            title
          }
          pbtContent {
            title
          }
        }
      }
      testingSite {
        name
        address {
          streetLine1
          streetLine2
          postalCode
          city
          coordinates {
            latitude
            longitude
          }
          country {
            iso2
          }
        }
        wheelchairAccess
      }
      price {
        value
        discountedPrice
        currency
      }
      businessName
      seatsAvailable
      preparationToolVersions(country: $country, lang: $lang, discount: $discount) {
        articleTranslationKey
        ...PreparationToolVersion
      }
    }
    includedProductsAndServices: productsAndServices(sessionId: $sessionId, type: INCLUDED) {
      ...ProductAndService
    }
    certificateProductsAndServices: productsAndServices(sessionId: $sessionId, type: CERTIFICATE) {
      ...ProductAndService
    }
    correctionProductsAndServices: productsAndServices(sessionId: $sessionId, type: CORRECTION) {
      ...ProductAndService
      superExpressScoring
    }
    discountProductsAndServices: productsAndServices(sessionId: $sessionId, type: DISCOUNT) {
      ...ProductAndService
    }
  }
  ${PRODUCT_AND_SERVICE_FRAGMENT}
  ${PREPTOOL_VERSION_FRAGMENT}
`;

export const PURCHASE = gql`
  query PURCHASE($registrationId: String!) {
    purchase(registrationId: $registrationId) {
      id
      registrationId
      status
      total
      totalWithTaxes
      openSession {
        id
      }
      purchaseLines {
        articleStock {
          id
        }
        ...PurchaseLineFragment
      }
      purchaseTaxes {
        ...PurchaseTaxFragment
      }
      candidates {
        ...CandidateFragment
      }
      voucher {
        ...Voucher
      }
      requiresDeliveryAddress
    }
  }
  ${PURCHASE_LINE_FRAGMENT}
  ${PURCHASE_TAX_FRAGMENT}
  ${CANDIDATE_FRAGMENT}
  ${VOUCHER_FRAGMENT}
`;

export const ADDRESS_FRAGMENT = gql`
  fragment ADDRESS_FRAGMENT on Address {
    id
    civility {
      id
      name
    }
    name
    firstname
    lastname
    email
    streetLine1
    streetLine2
    postalCode
    city
    state
    phoneNumber
    country {
      name
      iso2
    }
    socialSecurityNumber
  }
`;

export const CONFIRMED_PURCHASE = gql`
  query CONFIRMED_PURCHASE(
    $registrationId: String!
    $withOpenSession: Boolean = true
    $withPurchaseLines: Boolean = true
    $withBillingAddress: Boolean = false
  ) {
    confirmedPurchase(registrationId: $registrationId) {
      id
      registrationId
      total
      openSession @include(if: $withOpenSession) {
        id
        scheduledAt
        price {
          value
          currency
        }
        testingSite {
          name
        }
        testTypeFamily {
          id
          name
        }
      }
      purchaseLines @include(if: $withPurchaseLines) {
        articleStock {
          id
        }
        ...PurchaseLineFragment
      }
      billingAddress @include(if: $withBillingAddress) {
        ...ADDRESS_FRAGMENT
      }
      purchaseTaxes {
        amount
      }
    }
  }
  ${PURCHASE_LINE_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const CANCELLED_PURCHASE = gql`
  query CANCELLED_PURCHASE($registrationId: String!) {
    cancelledPurchase(registrationId: $registrationId) {
      id
    }
  }
`;

export const PURCHASE_ADDRESS = gql`
  query PURCHASE_ADDRESS($registrationId: String!) {
    purchase(registrationId: $registrationId) {
      id
      registrationId
      deliveryAddress {
        ...ADDRESS_FRAGMENT
      }
      billingAddress {
        ...ADDRESS_FRAGMENT
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const DELIVERY_ADDRESS_BY_PURCHASE_ID = gql`
  query DELIVERY_ADDRESS_BY_PURCHASE_ID($purchaseId: ID!) {
    purchase(purchaseId: $purchaseId) {
      id
      deliveryAddress {
        ...ADDRESS_FRAGMENT
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const QUOTE = gql`
  query QUOTE($registrationId: String!, $lang: String!) {
    quote(registrationId: $registrationId, lang: $lang)
  }
`;
