import './styles.scss';

import classNames from 'classnames';
import React from 'react';

export interface IconOptions {
  name:
    | 'arrow'
    | 'basket'
    | 'certificate'
    | 'chevron'
    | 'dday'
    | 'disabled'
    | 'download'
    | 'edit'
    | 'ellipsis'
    | 'euro'
    | 'eye-closed'
    | 'eye'
    | 'facebook'
    | 'goal'
    | 'graduate'
    | 'handicap'
    | 'happy'
    | 'info'
    | 'linkedin'
    | 'marker'
    | 'minus'
    | 'pdf'
    | 'pin'
    | 'plus'
    | 'plus-light'
    | 'public'
    | 'sad'
    | 'score'
    | 'search'
    | 'styled-arrow'
    | 'test'
    | 'tick'
    | 'toefl'
    | 'toeic'
    | 'trash'
    | 'twitter'
    | 'upload'
    | 'user'
    | 'public-program'
    | 'map'
    | 'list'
    | 'calendar'
    | 'arobase'
    | 'www'
    | 'phone'
    | 'print'
    | 'landingPagePrepTool'
    | 'landingPageTestType'
    | 'b2c'
    | 'b2b'
    | 'cbt'
    | 'pbt';
}

export interface IconProps extends IconOptions, Omit<React.SVGProps<SVGSVGElement>, 'name'> {}

const Icon: React.FC<IconProps> = ({ name, className, ...props }) => {
  const IconSVG: React.FC<{
    viewBox?: React.SVGProps<SVGSVGElement>['viewBox'];
    children: React.ReactNode;
  }> = ({ viewBox, children }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox}
      className={classNames('icon', `icon--${name}`, className)}
      {...props}
    >
      {children}
    </svg>
  );

  const icons = {
    arrow: (
      <IconSVG viewBox="0 0 16 8">
        <path d="M15.5 4.5a.5.5 0 0 0 0-1H.5a.5.5 0 1 0 0 1h15z" />
        <path d="M15.146 4.354a.5.5 0 1 0 .708-.708l-3.5-3.5a.5.5 0 1 0-.708.708l3.5 3.5z" />
        <path d="M15.854 4.354a.5.5 0 0 0-.708-.708l-3.5 3.5a.5.5 0 1 0 .708.708l3.5-3.5z" />
      </IconSVG>
    ),
    basket: (
      <IconSVG viewBox="0 0 17 15">
        <path d="M15.5 3H11v-.5C11 1.1 9.9 0 8.5 0S6 1.1 6 2.5V3H1.5C.6 3-.1 3.7 0 4.6l1.7 8.6c0 .1 0 .1.1.2.3.8 1 1.5 1.7 1.5h10.2c.7-.1 1.4-.9 1.6-1.7L17 4.6c.1-.9-.6-1.6-1.5-1.6zM7 2.5C7 1.7 7.7 1 8.5 1s1.5.7 1.5 1.5V3H7v-.5zm7.3 10.6c-.1.5-.5.9-.8.9h-10c-.2 0-.6-.4-.7-.8v-.1L1 4.4c0-.2.1-.4.5-.4h14c.4 0 .5.2.5.5l-1.7 8.6z" />
      </IconSVG>
    ),
    certificate: (
      <IconSVG viewBox="0 0 18 19">
        <path d="M3.5,4h9.1C12.8,4,13,3.8,13,3.5S12.8,3,12.5,3H3.5C3.2,3,3,3.2,3,3.5S3.2,4,3.5,4z" />
        <path d="M3.5,7.2h9.1C12.8,7.2,13,7,13,6.7c0-0.3-0.2-0.5-0.5-0.5H3.5C3.2,6.2,3,6.4,3,6.7C3,7,3.2,7.2,3.5,7.2z" />
        <path d="M17.9,17l-1.2-2.5l0.1-0.8l0.7-0.8c0.3-0.4,0.3-0.9,0-1.2l-0.7-0.8l-0.1-1c0-0.3-0.2-0.6-0.5-0.8V1.6c0-0.9-0.7-1.6-1.6-1.6H1.6C0.7,0,0,0.7,0,1.6v10.1c0,0.9,0.7,1.6,1.6,1.6h8.2l0.2,0.3l0.1,0.8L9,17c-0.1,0.3-0.1,0.6,0.1,0.8c0.2,0.2,0.5,0.3,0.7,0.3l0.8-0.1l0.4,0.7c0.1,0.2,0.4,0.4,0.7,0.4h0c0.3,0,0.5-0.2,0.7-0.4l1-2.1c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0l1,2.1c0.1,0.3,0.4,0.4,0.7,0.4h0c0.3,0,0.5-0.2,0.7-0.4l0.4-0.7l0.8,0.1c0.3,0.1,0.6-0.1,0.7-0.3C18,17.5,18.1,17.2,17.9,17zM16.7,12.2L16.1,13c-0.1,0.2-0.2,0.3-0.2,0.5l-0.1,1l-1,0.1c-0.2,0-0.4,0.1-0.5,0.2l-0.8,0.7l-0.8-0.7c-0.2-0.1-0.3-0.2-0.5-0.2l-1-0.1l-0.1-1c0-0.2-0.1-0.4-0.2-0.5l-0.7-0.8l0.7-0.8c0.1-0.2,0.2-0.3,0.2-0.5l0.1-1l1-0.1c0.2,0,0.4-0.1,0.5-0.2l0.8-0.7l0.8,0.7c0.2,0.1,0.3,0.2,0.5,0.2l1,0.1l0.1,1c0,0.2,0.1,0.4,0.2,0.6L16.7,12.2z M1,11.7V1.6C1,1.3,1.3,1,1.6,1h13.1c0.3,0,0.6,0.3,0.6,0.6v7.3l-0.4,0l-0.8-0.7c-0.4-0.3-0.9-0.3-1.2,0l-0.8,0.7l-1,0.1c-0.3,0-0.5,0.2-0.7,0.4H3.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h6.6l0,0.6l-0.7,0.8c-0.2,0.2-0.3,0.5-0.2,0.8H1.6C1.2,12.4,1,12.1,1,11.7z M11.6,17.8l-0.3-0.5c-0.1-0.2-0.4-0.4-0.7-0.4c0,0-0.1,0-0.1,0L10,17l0.7-1.6c0.1,0,0.2,0.1,0.3,0.1l1,0.1l0.4,0.4L11.6,17.8z M16.4,16.9c-0.3-0.1-0.7,0.1-0.8,0.4l-0.3,0.5l-0.9-1.8l0.4-0.3l1-0.1c0.1,0,0.2,0,0.3-0.1l0.7,1.6L16.4,16.9z M15,11.6c0.2,0,0.3,0.3,0.2,0.5l-0.5,0.6c-0.1,0.1-0.1,0.1-0.1,0.2l0.1,0.8c0,0.2-0.2,0.4-0.4,0.3l-0.7-0.3c-0.1,0-0.2,0-0.2,0L12.5,14c-0.2,0.1-0.4-0.1-0.4-0.3l0.1-0.8c0-0.1,0-0.2-0.1-0.2l-0.5-0.6c-0.1-0.2-0.1-0.4,0.2-0.5l0.8-0.2c0.1,0,0.1-0.1,0.2-0.1l0.4-0.7c0.1-0.2,0.4-0.2,0.5,0l0.4,0.7c0,0.1,0.1,0.1,0.2,0.1L15,11.6z" />
      </IconSVG>
    ),
    chevron: (
      <IconSVG viewBox="0 0 7 13">
        <path d="M5.8 6.5L.2.9C0 .7 0 .4.2.2c.2-.2.5-.2.7 0l6 6c.2.2.2.5 0 .7l-6 6c-.2.2-.5.2-.7 0s-.2-.5 0-.7l5.6-5.7z" />
      </IconSVG>
    ),
    dday: (
      <IconSVG viewBox="0 0 15.3 16.6">
        <path d="M5.3,3.2c0,1-0.3,1.8-0.9,2.4S3,6.4,1.8,6.4H0V0h2c1,0,1.9,0.3,2.4,0.8S5.3,2.2,5.3,3.2z M3.9,3.2c0-1.4-0.6-2.1-1.8-2.1H1.4v4.2H2C3.3,5.3,3.9,4.6,3.9,3.2z M5.2,11.2c0,1.1-0.3,1.9-0.9,2.4s-1.4,0.8-2.5,0.8H0.1V8h2c1,0,1.8,0.3,2.3,0.8S5.2,10.2,5.2,11.2z M4.4,11.2c0-0.8-0.2-1.5-0.6-1.9s-1-0.6-1.9-0.6H0.8v5.1h0.9c0.9,0,1.6-0.2,2-0.7S4.4,12,4.4,11.2z M9.5,14.4l-0.1-0.7l0,0c-0.2,0.3-0.5,0.5-0.7,0.6s-0.5,0.2-0.9,0.2c-0.5,0-0.9-0.1-1.1-0.4S6.3,13.5,6.3,13c0-1,0.8-1.5,2.3-1.5h0.8v-0.3c0-0.4-0.1-0.7-0.2-0.8s-0.4-0.3-0.8-0.3c-0.4,0-0.9,0.1-1.4,0.4L6.8,9.9C7,9.8,7.3,9.7,7.6,9.6s0.6-0.1,0.9-0.1c0.6,0,1,0.1,1.3,0.4s0.4,0.7,0.4,1.2v3.3C10.2,14.4,9.5,14.4,9.5,14.4z M7.8,13.9c0.5,0,0.8-0.1,1.1-0.4s0.4-0.6,0.4-1v-0.4H8.6c-0.6,0-1,0.1-1.3,0.3s-0.4,0.4-0.4,0.7c0,0.3,0.1,0.5,0.2,0.6S7.6,13.9,7.8,13.9z M10.8,9.6h0.8l1.1,2.7c0.2,0.6,0.4,1.1,0.4,1.4l0,0c0-0.1,0.1-0.4,0.2-0.8s0.5-1.5,1.2-3.3h0.8l-2.1,5.5c-0.2,0.5-0.4,0.9-0.7,1.2s-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.7-0.1v-0.6c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.9-0.3,1.1-0.8l0.3-0.7L10.8,9.6z" />
      </IconSVG>
    ),
    disabled: (
      <IconSVG viewBox="0 0 16 20">
        <g transform="translate(0 .5)">
          <path d="M14 9.857H8.857L8 5H4l1.429 8.286h6L12.57 19H16z" />
          <circle cx="6" cy="2" r="2" />
          <path d="M6.055 17.892c-2.703 0-4.902-2.112-4.902-4.708 0-1.71.957-3.205 2.381-4.03L3.327 8C1.357 8.96 0 10.921 0 13.184 0 16.391 2.717 19 6.055 19A6.125 6.125 0 0 0 11 16.53l-.355-1.707c-.695 1.79-2.487 3.07-4.59 3.07z" />
        </g>
      </IconSVG>
    ),
    download: (
      <IconSVG viewBox="-357.2 100.8 233.3 300">
        <path d="M-138.2,372.2H-343c-7.9,0-14.3,6.4-14.3,14.3s6.4,14.3,14.3,14.3h204.8c7.9,0,14.3-6.4,14.3-14.3S-130.3,372.2-138.2,372.2z" />
        <path d="M-248.3,340.3c2.8,2.8,6.4,4.2,10.1,4.2s7.3-1.4,10.1-4.2l93.8-93.8c5.6-5.6,5.6-14.6,0-20.2c-5.6-5.6-14.6-5.6-20.2,0l-69.4,69.4V115c0-7.9-6.4-14.3-14.3-14.3s-14.3,6.4-14.3,14.3v180.7l-69.4-69.4c-5.6-5.6-14.6-5.6-20.2,0c-5.6,5.6-5.6,14.6,0,20.2L-248.3,340.3z" />
      </IconSVG>
    ),
    edit: (
      <IconSVG viewBox="0 0 15 15">
        <path d="M.8 10.9L0 15l4.1-.8 7.4-7.4-3.3-3.3-7.4 7.4zM11.7 0L9.3 2.4l3.3 3.3L15 3.3 11.7 0z" />
        <path d="M.8 10.9L0 15l4.1-.8 7.4-7.4-3.3-3.3-7.4 7.4zM11.7 0L9.3 2.4l3.3 3.3L15 3.3 11.7 0z" />
      </IconSVG>
    ),
    ellipsis: (
      <IconSVG viewBox="-246 450 15 3">
        <path d="M-244.5 450c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM-232.5 450c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM-238.5 450c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z" />
      </IconSVG>
    ),
    euro: (
      <IconSVG viewBox="0 0 8 11">
        <path d="M5.4,1.1c-1.6,0-2.5,1-2.9,3h3.7V5H2.3l0,0.4v0.5l0,0.3h3.4v0.9H2.5c0.2,0.9,0.5,1.6,1,2s1.2,0.7,2,0.7c0.8,0,1.5-0.2,2.3-0.5v1.1C7,10.8,6.2,11,5.4,11c-1.2,0-2.1-0.3-2.8-1C1.9,9.4,1.4,8.4,1.2,7.2H0V6.2h1.1l0-0.3V5.6l0-0.6H0V4.1h1.2c0.2-1.3,0.6-2.3,1.4-3S4.2,0,5.4,0c1,0,1.9,0.2,2.7,0.7l-0.5,1C6.7,1.3,6,1.1,5.4,1.1z" />
      </IconSVG>
    ),
    'eye-closed': (
      <IconSVG viewBox="0 0 14.2 14.2">
        <path d="M13.9 6.6c-1.6-1.9-4.2-4-6.8-4s-5.2 2-6.9 4c-.1.3-.1.7 0 1 1.6 1.9 4.2 4 6.8 4s5.2-2.1 6.8-4c.3-.3.3-.7.1-1zM7.1 9.7c-1.4 0-2.6-1.1-2.6-2.6s1.1-2.6 2.6-2.6 2.6 1.1 2.6 2.6-1.2 2.6-2.6 2.6z" />
        <path d="M7.1 5.6c-.8 0-1.5.6-1.5 1.4S6.3 8.4 7 8.4 8.4 7.7 8.4 7c.1-.8-.6-1.4-1.3-1.4z" />
        <path d="M13.6 0l.6.6L.5 14.2l-.5-.6L13.6 0z" />
      </IconSVG>
    ),
    eye: (
      <IconSVG viewBox="0 0 13.9 9">
        <path d="M13.8 4C12.2 2.1 9.6 0 7 0 4.4 0 1.8 2 .1 4c-.1.3-.1.7 0 1 1.6 1.9 4.2 4 6.8 4s5.2-2.1 6.8-4c.3-.3.3-.7.1-1zM7 7.1C5.6 7.1 4.4 6 4.4 4.5S5.5 1.9 7 1.9 9.6 3 9.6 4.5 8.4 7.1 7 7.1z" />
        <path d="M7 3c-.8 0-1.5.6-1.5 1.4 0 .8.7 1.4 1.4 1.4s1.4-.7 1.4-1.4C8.4 3.6 7.7 3 7 3z" />
      </IconSVG>
    ),
    facebook: (
      <IconSVG viewBox="0 0 12 22">
        <path d="M7.8 22V12h3.5l.5-3.9h-4V5.6c0-1.1.3-1.9 2-1.9H12V.2C11.6.1 10.3 0 8.8 0 5.7 0 3.5 1.8 3.5 5.2v2.9H0V12h3.5v10h4.3z" />
      </IconSVG>
    ),
    goal: (
      <IconSVG viewBox="0 0 19 19">
        <path d="M13,9.5c0,1.9-1.6,3.5-3.5,3.5S6,11.4,6,9.5S7.6,6,9.5,6S13,7.6,13,9.5z M16,9.5C16,5.9,13.1,3,9.5,3S3,5.9,3,9.5S5.9,16,9.5,16S16,13.1,16,9.5z M15,9.5c0,3-2.5,5.5-5.5,5.5S4,12.5,4,9.5S6.5,4,9.5,4S15,6.5,15,9.5z M19,9.5C19,4.3,14.7,0,9.5,0S0,4.3,0,9.5S4.3,19,9.5,19S19,14.7,19,9.5z M18,9.5c0,4.7-3.8,8.5-8.5,8.5S1,14.2,1,9.5S4.8,1,9.5,1S18,4.8,18,9.5z" />
      </IconSVG>
    ),
    graduate: (
      <IconSVG viewBox="0 0 21.8 14">
        <path d="M10.9,10.2L0,5.1L10.9,0l10.9,5.1L10.9,10.2z M2.4,5.1l8.5,4l8.6-4l-8.6-4L2.4,5.1z M11.1,14c-1.7,0-3.4-0.6-5-1.8L5.8,12V7.6c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v3.9c2.8,2,5.6,2,8.5,0v-4c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V12l-0.2,0.2C14.4,13.4,12.7,14,11.1,14z M19.2,5.6h1V10h-1V5.6z M19.2,9.1h0.9l0.9,2.6c0,0-1.7,0.9-2.6,0L19.2,9.1z" />
      </IconSVG>
    ),
    handicap: (
      <IconSVG viewBox="0 0 14.7 19">
        <path d="M5.5,18C3,18,1,16,1,13.5c0-1.6,0.9-3.1,2.2-3.9L2.7,8.8C1,9.7,0,11.6,0,13.5c0,3,2.5,5.5,5.5,5.5c2.4,0,4.5-1.5,5.2-3.8l-0.9-0.3C9.2,16.8,7.4,18,5.5,18z" />
        <path d="M12.8,16l-2-5H4.9L4.7,9h3.8V8H4.6L4.2,5c0.1,0,0.2,0,0.3,0C5.9,5,7,3.9,7,2.5S5.9,0,4.5,0S2,1.1,2,2.5c0,0.9,0.5,1.7,1.2,2.1L4.1,12h6.1l2,5h2.5v-1H12.8z M3,2.5C3,1.7,3.7,1,4.5,1S6,1.7,6,2.5S5.3,4,4.5,4S3,3.3,3,2.5z" />
      </IconSVG>
    ),
    happy: (
      <IconSVG viewBox="0 0 21.44 21.44">
        <path d="M6.66 9.57a1.25 1.25 0 1 0-1.25 1.25 1.26 1.26 0 0 0 1.25-1.25z" />
        <path d="M10.72 0a10.72 10.72 0 1 0 10.72 10.72A10.73 10.73 0 0 0 10.72 0zm0 20.34a9.63 9.63 0 1 1 9.62-9.62 9.63 9.63 0 0 1-9.62 9.62z" />
        <path d="M10.72 16.28c2.73 0 4.74-1.22 5.47-3.21a.76.76 0 0 0-.13-.74.78.78 0 0 0-.71-.26.76.76 0 0 0-.58.49c-.51 1.41-1.86 2.22-4 2.22S7.33 14 6.65 12.51a.75.75 0 0 0-.7-.45.75.75 0 0 0-.67 1.06c.89 2 2.78 3.16 5.44 3.16zM15.53 8.32a1.25 1.25 0 1 0 1.25 1.25 1.26 1.26 0 0 0-1.25-1.25z" />
      </IconSVG>
    ),
    info: (
      <IconSVG viewBox="-8 -8 16 16">
        <path d="M-1.8 4.5c0-.1 0-.3.1-.6 0-.2.1-.3.1-.6L-.8 0v-.1-.1c0-.2-.3-.3-.4-.4L-2-.7v-.4s.9-.1 1.4-.1c.5-.1 1-.1 1.2-.2l.7-.1L.2 2.7c-.1.4-.2.7-.2.8-.1.4-.1.6-.1.7s0 .2.1.2h.2c.1 0 .3-.1.5-.4s.3-.4.5-.7l.3.2C1 4.2.7 4.7.4 4.9c-.4.4-.8.6-1.2.6-.3 0-.5-.1-.7-.2-.2-.2-.3-.4-.3-.8zM.9-4.8c.3 0 .5.1.7.3.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.2-.2.4-.3.7-.3z" />
        <path d="M0 8c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM0-7.5c-4.1 0-7.5 3.4-7.5 7.5S-4.1 7.5 0 7.5 7.5 4.1 7.5 0 4.1-7.5 0-7.5z" />
      </IconSVG>
    ),
    linkedin: (
      <IconSVG viewBox="0 0 19.7 20">
        <path d="M.4 6.6h4.5V20H.4V6.6zM2.6 0C4 0 5.2 1.1 5.2 2.4c0 1.3-1.2 2.4-2.6 2.4C1.2 4.8 0 3.7 0 2.4 0 1.1 1.2 0 2.6 0zm4.6 6.7H11v1.8h.1c.5-1.1 1.8-2.2 3.8-2.2 4 0 4.8 2.8 4.8 6.4V20h-4v-6.5c0-1.6 0-3.5-2.1-3.5s-2.4 1.7-2.4 3.4V20h-4V6.7z" />
      </IconSVG>
    ),
    marker: (
      <IconSVG viewBox="0 0 16 23">
        <path
          d="M8,0C3.6,0,0,3.5,0,7.7C0,11.8,5.2,19.3,8,23c2.8-3.7,8-11.2,8-15.3C16,3.5,12.4,0,8,0z M8,11.5
              c-2.1,0-3.8-1.7-3.8-3.7S5.9,4,8,4s3.8,1.7,3.8,3.7S10.1,11.5,8,11.5z"
        />
      </IconSVG>
    ),
    minus: (
      <IconSVG viewBox="0 0 3.5 1.1">
        <path d="M0 1.1V0h3.5v1.1H0z" />
      </IconSVG>
    ),
    pdf: (
      <IconSVG viewBox="0 0 16 20">
        <path
          d="M12.74 13.017c-.24.071-.593.08-.97.024a4.61 4.61 0 0 1-1.226-.367c.725-.106 1.287-.073 1.768.098.114.04.3.149.427.245zm-4.044-.668l-.088.024c-.195.053-.384.105-.567.151l-.247.063c-.496.126-1.003.255-1.504.409.19-.462.368-.928.54-1.384.129-.338.26-.683.395-1.023.069.114.14.228.215.342.34.52.767 1.002 1.256 1.418zM7.434 7.146c.032.57-.09 1.117-.27 1.642-.22-.65-.323-1.367-.047-1.946a1.12 1.12 0 0 1 .167-.269c.058.09.134.292.15.573zm-2.589 7.212c-.124.223-.25.432-.38.629-.314.474-.826.982-1.089.982-.026 0-.057-.004-.103-.053-.03-.03-.034-.053-.033-.083.009-.175.239-.485.572-.773a5.626 5.626 0 0 1 1.033-.702zm8.732-1.316c-.04-.581-1.013-.954-1.023-.958a3.67 3.67 0 0 0-1.249-.199c-.497 0-1.033.073-1.721.234a6.008 6.008 0 0 1-1.537-1.588 9.724 9.724 0 0 1-.469-.794c.334-.803.635-1.666.58-2.633-.044-.775-.391-1.295-.864-1.295-.325 0-.604.241-.831.718-.405.85-.299 1.937.316 3.235a45.156 45.156 0 0 0-.626 1.59c-.248.652-.503 1.326-.791 1.966-.807.321-1.47.71-2.023 1.188-.362.313-.798.79-.823 1.29a.827.827 0 0 0 .23.622.85.85 0 0 0 .63.28c.79 0 1.549-1.09 1.693-1.309.29-.439.561-.929.827-1.494.67-.244 1.384-.425 2.076-.601l.248-.063c.186-.048.38-.1.578-.155.21-.057.427-.116.647-.173.71.455 1.474.75 2.22.86.627.091 1.185.038 1.562-.16.34-.177.358-.452.35-.561zm1.53 4.996c0 1.064-.934 1.13-1.122 1.132H1.946c-1.055 0-1.118-.944-1.12-1.132V1.962C.826.897 1.76.832 1.946.83h8.133l.004.005v3.19c0 .64.385 1.852 1.843 1.852h3.153l.027.027v12.134zm-.748-12.889h-2.433c-1.054 0-1.118-.939-1.12-1.124v-2.46l3.553 3.584zm1.47 12.889V5.604L10.807.534V.511h-.024l-.404-.408H1.946C1.31.103.103.492.103 1.963v16.075c0 .643.385 1.86 1.843 1.86h12.04c.637-.001 1.843-.39 1.843-1.86z"
          fill="#EF2121"
          fillRule="nonzero"
          stroke="#EF2121"
          strokeWidth=".2"
        />
      </IconSVG>
    ),
    pin: (
      <IconSVG viewBox="0 0 10.5 15">
        <path d="M9.8 4.5C9.8 2 7.7 0 5.2 0S.8 2 .8 4.5c0 3.4 4.5 8.2 4.5 8.2s4.5-4.8 4.5-8.2zm-6 0c0-.8.6-1.5 1.4-1.5s1.5.7 1.5 1.5S6.1 6 5.2 6c-.8 0-1.4-.7-1.4-1.5zm-3.8 9V15h10.5v-1.5H0z" />
      </IconSVG>
    ),
    plus: (
      <IconSVG viewBox="0 0 6.1 6.2">
        <path className="st0" d="M2.5 6.2V3.6H0V2.5h2.5V0h1.1v2.5h2.5v1.1H3.6v2.6H2.5z" />
      </IconSVG>
    ),
    'plus-light': (
      <IconSVG viewBox="-241 243 16 16">
        <path d="M-232 250v-7h-2v7h-7v2h7v7h2v-7h7v-2z" />
      </IconSVG>
    ),
    public: (
      <IconSVG viewBox="0 0 24 15.2">
        <path d="M22.5 0h-21C.7 0 0 .6 0 1.4v12.3c0 .8.7 1.4 1.5 1.4h21c.8 0 1.5-.6 1.5-1.4V1.4c0-.8-.7-1.4-1.5-1.4zM5.4 3.9c.9 0 1.7.7 1.7 1.6s-.8 1.6-1.7 1.6-1.7-.7-1.7-1.6c0-.4.2-.8.5-1.1s.8-.5 1.2-.5zm3.4 6.6c0 .7-.7 1.2-1.5 1.2H3.6c-.8 0-1.5-.6-1.5-1.2V9.3c0-1 .6-1.9 1.5-2.3.5.5 1.2.9 1.9.9s1.4-.3 1.9-.9c.9.5 1.5 1.3 1.5 2.3v1.2zm12.6-.5h-8.2c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h8.2c.2 0 .4.2.4.4s-.2.4-.4.4zm0-2.1h-8.2c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h8.2c.2 0 .4.2.4.4s-.2.4-.4.4zm0-2.2h-8.2c-.2 0-.4-.2-.4-.4s.1-.3.3-.3h8.2c.2 0 .4.2.4.4s-.1.3-.3.3z" />
      </IconSVG>
    ),
    sad: (
      <IconSVG viewBox="0 0 21.4 21.4">
        <path d="M6.7 9.6c0-.7-.6-1.2-1.2-1.2s-1.3.5-1.3 1.2.6 1.2 1.2 1.2 1.3-.5 1.3-1.2z" />
        <path d="M10.7 0C4.8 0 0 4.8 0 10.7c0 5.9 4.8 10.7 10.7 10.7s10.7-4.8 10.7-10.7C21.4 4.8 16.6 0 10.7 0zm0 20.3c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6z" />
        <path d="M10.7 12.1c-2.7 0-4.7 1.2-5.5 3.2-.1.3 0 .5.1.7.2.2.4.3.7.3.3 0 .5-.2.6-.5.5-1.4 1.9-2.2 4.1-2.2 2.2 0 3.4.7 4.1 2.3.1.3.4.5.7.4.3 0 .5-.1.6-.3.1-.2.2-.5 0-.7-.8-2.1-2.7-3.2-5.4-3.2zM15.5 8.3c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2 1.2-.6 1.2-1.2-.5-1.2-1.2-1.2z" />
      </IconSVG>
    ),
    score: (
      <IconSVG viewBox="0 0 19.4 17.7">
        <path d="M9.4,12.5c0.6,1.1,0.9,2,0.8,2.8c-0.1,0.8-0.6,1.4-1.5,2c-0.3,0.2-0.6,0.3-0.8,0.4l-0.4-0.7c0.3-0.1,0.5-0.2,0.7-0.3c0.6-0.3,1-0.8,1.1-1.3s0-1.1-0.4-1.8l0,0c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.5,0.3-1,0.4-1.4,0.2c-0.5-0.1-0.8-0.5-1.2-1C5,13.6,4.9,13,5,12.5s0.5-1,1-1.3c0.4-0.2,0.8-0.3,1.2-0.3s0.8,0.2,1.2,0.4C8.8,11.6,9.2,12,9.4,12.5z M6.5,12C6.2,12.1,6,12.4,6,12.7s0,0.6,0.2,1c0.2,0.3,0.4,0.5,0.7,0.6c0.3,0.1,0.5,0,0.9-0.1C8,14,8.2,13.7,8.4,13.4c0.1-0.3,0.1-0.6-0.1-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.1-0.4-0.2-0.6-0.3C6.9,11.8,6.7,11.8,6.5,12zM13.9,10c0.6,1.1,0.9,2,0.8,2.8c-0.1,0.8-0.6,1.4-1.5,2c-0.3,0.2-0.6,0.3-0.8,0.4l-0.4-0.7c0.3-0.1,0.5-0.2,0.7-0.3c0.6-0.3,1-0.8,1.1-1.3s0-1.1-0.4-1.8l0,0c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.5,0.3-1,0.4-1.4,0.2s-0.8-0.5-1.2-1C9.5,11,9.4,10.5,9.5,9.9s0.5-1,1-1.3c0.4-0.2,0.8-0.3,1.2-0.3c0.4,0,0.8,0.2,1.2,0.4C13.3,9.1,13.6,9.5,13.9,10z M11,9.4c-0.3,0.2-0.5,0.4-0.5,0.7s0,0.6,0.2,1c0.2,0.3,0.4,0.5,0.7,0.6c0.3,0.1,0.5,0,0.9-0.1c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.1-0.6-0.1-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.1-0.4-0.2-0.6-0.3C11.4,9.2,11.2,9.3,11,9.4z M18.6,7.8c0.6,1,0.8,1.8,0.8,2.4s-0.4,1.2-1,1.5c-0.6,0.4-1.2,0.4-1.8,0.1c-0.6-0.3-1.2-0.9-1.7-1.9C14.2,9,14,8.2,14,7.5c0-0.6,0.4-1.2,1-1.5c0.6-0.4,1.2-0.4,1.8-0.1C17.5,6.2,18,6.9,18.6,7.8z M15.7,9.5c0.4,0.8,0.8,1.3,1.2,1.5c0.3,0.2,0.7,0.3,1,0.1c0.3-0.2,0.5-0.5,0.5-0.9c0-0.4-0.3-1-0.7-1.8c-0.4-0.7-0.8-1.2-1.2-1.5c-0.4-0.2-0.7-0.3-1-0.1S15,7.3,15,7.7C15,8.1,15.3,8.7,15.7,9.5z M19,0.9L18.5,0L0,10.5l0.5,0.9L19,0.9z" />
      </IconSVG>
    ),
    search: (
      <IconSVG viewBox="0 0 12 12">
        <path
          d="M8.307 5.078c0-.89-.316-1.65-.948-2.282a3.106 3.106 0 0 0-2.282-.948c-.89 0-1.65.316-2.282.948a3.11 3.11 0 0 0-.948 2.282c0 .89.316 1.651.948 2.282a3.116 3.116 0 0 0 2.282.948c.89 0 1.65-.315 2.282-.948a3.113 3.113 0 0 0 .948-2.282zm3.418 6.648a.887.887 0 0 1-.649.274.856.856 0 0 1-.649-.274L7.954 9.26c-.86.596-1.82.894-2.877.894a4.987 4.987 0 0 1-1.972-.4 5.078 5.078 0 0 1-1.623-1.082A5.078 5.078 0 0 1 .401 7.049 4.987 4.987 0 0 1 0 5.077c0-.687.134-1.345.4-1.972a5.078 5.078 0 0 1 1.082-1.623A5.078 5.078 0 0 1 3.105.401 4.987 4.987 0 0 1 5.077 0c.687 0 1.345.134 1.972.4a5.078 5.078 0 0 1 1.623 1.082c.454.454.815.995 1.082 1.623.267.627.4 1.285.4 1.972a4.935 4.935 0 0 1-.894 2.877l2.473 2.473a.883.883 0 0 1 .267.65c0 .25-.092.466-.275.649z"
          fillRule="nonzero"
        />
      </IconSVG>
    ),
    'styled-arrow': (
      <IconSVG viewBox="0 0 25.3 20.4">
        <path
          d="M10.1,2.9c-2.6-1-5.2-1.8-7.9-2.3c-0.1,0-0.2,0-0.3,0C1.7,0.4,1.5,0.3,1.3,0.3c-0.1-0.5-0.9-0.4-1,0.1
                c-0.1,0.5-0.1,1,0,1.5c0,0.4-0.1,0.8-0.1,1.2C0.1,3.3,0.1,3.4,0.1,3.6c0,0.2,0,0.5,0,0.7c-0.2,3.3-0.2,6.6,0.2,9.9
                c0.1,1.1,1.7,1,1.7-0.1c0-0.7,0-1.4,0-2.1c0-0.1,0-0.2,0-0.2c0.1-1.8,0.2-3.5,0.3-5.3c0.2,0.3,0.4,0.7,0.6,1
                c1.5,2.4,3.5,4.6,5.6,6.4c4.4,3.8,9.9,6.1,15.7,6.6c1.1,0.1,1.5-1.6,0.4-1.8c-5.2-0.8-9.3-3.9-13.1-7.4C9,9,6.5,6.6,4.3,3.9
                C6,4.2,7.8,4.4,9.5,4.6C10.4,4.7,11.1,3.3,10.1,2.9z M11.5,12.6c0.6,0.5,1.3,1.1,1.9,1.6c-0.8-0.5-1.7-1.1-2.5-1.7
                c-2.1-1.6-4-3.4-5.7-5.5C4.9,6.5,4.4,6,4,5.3C6.3,7.9,8.9,10.3,11.5,12.6z"
          fillRule="nonzero"
        />
      </IconSVG>
    ),
    test: (
      <IconSVG viewBox="0 0 19.2 18">
        <path d="M15,18H0V0h15v4.5h-1V1H1v16h13v-3.5h1V18z M5.6,7.1l1.9,1l1.9-1l0.7,0.7l0.7-0.7L9.6,5.9l-2.1,1l-2.1-1L4.5,6.8l-1-1L2.1,7.1l0.7,0.7l0.6-0.6l1,1L5.6,7.1z M5.6,10.1l1.9,1l1.9-1l0.7,0.7l0.7-0.7L9.6,8.9l-2.1,1l-2.1-1L4.5,9.8l-1-1l-1.4,1.4l0.7,0.7l0.6-0.6l1,1L5.6,10.1z M5.6,13.1l1.9,1l2.2-1.1l-0.4-0.9l-1.8,0.9l-2.1-1l-0.9,0.9l-1-1l-1.4,1.4l0.7,0.7l0.6-0.6l1,1L5.6,13.1z M13.1,12.6l6.1-6.1l-2.7-2.7l-6.1,6.1l-0.6,3.3L13.1,12.6z M16.6,5.2l1.3,1.3l-5.2,5.2l-1.5,0.3l0.3-1.5L16.6,5.2z" />
      </IconSVG>
    ),
    tick: (
      <IconSVG viewBox="-488.5 34.8 500 432">
        <path
          className="st0"
          d="M-3.7 46.2c-18.7-16.6-47.5-15-64.2 3.7L-317.2 330l-91.9-103.3c-16.7-18.7-45.4-20.4-64.2-3.7-18.7 16.7-20.4 45.4-3.7 64.2l159.8 179.6L0 110.4c16.7-18.7 15-47.5-3.7-64.2z"
        />
      </IconSVG>
    ),
    toefl: (
      <IconSVG viewBox="0 0 21.6 5.3">
        <path d="M2.5,5.2H1.4V1H0V0.1h3.9V1H2.5V5.2z M9.3,2.6c0,0.9-0.2,1.5-0.6,2s-1,0.7-1.8,0.7S5.5,5.1,5.1,4.6s-0.6-1.1-0.6-2c0-0.9,0.2-1.5,0.6-2S6.1,0,6.9,0c0.8,0,1.4,0.2,1.8,0.7C9.1,1.1,9.3,1.8,9.3,2.6z M5.6,2.6c0,0.6,0.1,1,0.3,1.3c0.2,0.3,0.5,0.4,1,0.4c0.9,0,1.3-0.6,1.3-1.7c0-1.2-0.4-1.7-1.3-1.7c-0.4,0-0.8,0.1-1,0.4S5.6,2.1,5.6,2.6z M13.4,5.2h-3V0.1h3V1h-1.9v1.1h1.7V3h-1.7v1.3h1.9V5.2z M15.5,5.2h-1.1V0.1h2.9V1h-1.9v1.3h1.7v0.9h-1.7V5.2z M18.4,5.2V0.1h1.1v4.2h2.1v0.9H18.4z" />
      </IconSVG>
    ),
    toeic: (
      <IconSVG viewBox="0 0 20.5 5.3">
        <path d="M2.5,5.2H1.4V1H0V0.1h3.9V1H2.5V5.2z M9.3,2.6c0,0.9-0.2,1.5-0.6,2s-1,0.7-1.8,0.7S5.5,5.1,5.1,4.6s-0.6-1.1-0.6-2c0-0.9,0.2-1.5,0.6-2S6.1,0,6.9,0c0.8,0,1.4,0.2,1.8,0.7C9.1,1.1,9.3,1.8,9.3,2.6z M5.6,2.6c0,0.6,0.1,1,0.3,1.3c0.2,0.3,0.5,0.4,1,0.4c0.9,0,1.3-0.6,1.3-1.7c0-1.2-0.4-1.7-1.3-1.7c-0.4,0-0.8,0.1-1,0.4S5.6,2.1,5.6,2.6z M13.4,5.2h-3V0.1h3V1h-1.9v1.1h1.7V3h-1.7v1.3h1.9V5.2z M14.4,5.2V0.1h1.1v5.1H14.4z M19,0.9c-0.4,0-0.7,0.2-1,0.5s-0.3,0.7-0.3,1.3c0,1.1,0.4,1.7,1.3,1.7c0.4,0,0.8-0.1,1.3-0.3V5c-0.4,0.2-0.9,0.3-1.4,0.3c-0.7,0-1.3-0.2-1.7-0.7s-0.6-1.1-0.6-2c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.5-0.7,0.8-0.9C18.1,0.1,18.5,0,19,0c0.5,0,1,0.1,1.5,0.4l-0.4,0.9C20,1.2,19.8,1.1,19.6,1C19.4,0.9,19.2,0.9,19,0.9z" />
      </IconSVG>
    ),
    trash: (
      <IconSVG viewBox="0 0 15 17">
        <path d="M0 2.8v-.9c0-.4.4-.8.8-.8h3.7l.3-.6c.2-.3.5-.5.8-.5h3.8c.3 0 .6.2.7.4l.3.6h3.8c.4 0 .8.4.8.8v.9c0 .2-.2.4-.4.4H.4C.2 3.2 0 3 0 2.8zm13.9 1.9l-.7 10.8c-.1.8-.8 1.5-1.6 1.5H3.4c-.8 0-1.6-.7-1.6-1.5L1.1 4.7c0-.2.2-.4.4-.4h12c.2-.1.4.1.4.4z" />
      </IconSVG>
    ),
    twitter: (
      <IconSVG viewBox="0 0 22 18">
        <path d="M22 2.1c-.8.4-1.7.6-2.6.7.9-.6 1.6-1.5 2-2.5-.9.5-1.9.9-2.9 1.1-.9-.9-2-1.4-3.3-1.4-2.5 0-4.5 2-4.5 4.5 0 .4 0 .7.1 1C7 5.3 3.7 3.5 1.5.8c-.4.7-.6 1.5-.6 2.3 0 1.5.7 2.9 2 3.8-.7 0-1.4-.2-2-.6v.1c0 2.2 1.5 4 3.6 4.5-.7.2-1.4.2-2 .1.6 1.8 2.3 3.1 4.2 3.2-1.9 1.4-4.3 2-6.7 1.8 2.1 1.3 4.5 2 6.9 2 8.3 0 12.8-6.9 12.8-12.9v-.6c.9-.7 1.7-1.5 2.3-2.4z" />
      </IconSVG>
    ),
    upload: (
      <IconSVG viewBox="-223 233 53 35">
        <path
          className="st0"
          d="M-180.3 246.2c-1.5-7.5-8.2-13.2-16.2-13.2-6.4 0-11.9 3.6-14.7 8.8-6.6.7-11.8 6.3-11.8 13 0 7.2 5.9 13.1 13.2 13.1h28.7c6.1 0 11-4.9 11-10.9.1-5.7-4.4-10.4-10.2-10.8zm-11.8 6.5v8.8h-8.8v-8.8h-6.6l11-10.9 11 10.9h-6.6z"
        />
      </IconSVG>
    ),
    user: (
      <IconSVG viewBox="0 0 16 16">
        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 1c3.9 0 7 3.1 7 7 0 1.4-.4 2.8-1.2 3.9-.3-.2-.6-.3-1.1-.6-.1-.1-.3-.1-.5-.2-.4-.2-.8-.4-1.2-.5-.2-.1-.4-.2-.5-.2-.7-.3-1-.6-1-.9 0-.2 0-.3.2-.4 0-.1.3-.4.4-.5.4-.6.6-1.2.7-2.1C10.9 4.4 9.8 3 8 3 6.1 3 5 4.4 5.1 6.3c.1.9.3 1.5.7 2.1.1.2.3.5.4.6.1.2.2.3.2.4 0 .1-.2.3-.5.5-.2.2-.4.3-.6.4 0 0-1.1.5-1.7.8-.1.1-.3.1-.4.2-.5.2-.8.4-1.1.6C1.4 10.8 1 9.4 1 8c0-3.9 3.1-7 7-7zm0 14c-2.1 0-3.9-.9-5.2-2.3.2-.1.5-.3.9-.5.1-.1.2-.1.4-.2.6-.3 1.7-.8 1.7-.8s.1 0 .2-.1c.2-.1.3-.2.5-.3.5-.4.8-.9.8-1.4 0-.4-.1-.7-.3-1 0-.1-.3-.4-.4-.5-.3-.4-.4-.9-.5-1.6C6 4.9 6.7 4 8 4c1.2 0 1.9.9 1.8 2.3 0 .7-.2 1.2-.5 1.6-.1.1-.4.4-.4.6-.2.3-.3.6-.3 1 0 .8.6 1.3 1.6 1.8.1 0 .3.1.5.2.4.2.8.4 1.2.5.2.1.3.1.4.2.4.2.7.4.9.5C11.9 14.1 10.1 15 8 15z" />
      </IconSVG>
    ),
    'public-program': (
      <IconSVG viewBox="0 0 24 15.2">
        <path d="M22.5 0h-21C.7 0 0 .6 0 1.4v12.3c0 .8.7 1.4 1.5 1.4h21c.8 0 1.5-.6 1.5-1.4V1.4c0-.8-.7-1.4-1.5-1.4zM5.4 3.9c.9 0 1.7.7 1.7 1.6s-.8 1.6-1.7 1.6-1.6-.7-1.6-1.6c0-.4.2-.8.5-1.1s.7-.5 1.1-.5zm3.4 6.6c0 .7-.7 1.2-1.5 1.2H3.6c-.8 0-1.5-.6-1.5-1.2V9.3c0-1 .6-1.9 1.5-2.3.4.5 1.1.9 1.8.9s1.5-.4 1.9-.9c.9.5 1.5 1.3 1.5 2.3zm12.6-.5h-8.2c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h8.2c.2 0 .4.2.4.4s-.2.4-.4.4zm0-2.1h-8.2c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h8.2c.2 0 .4.2.4.4s-.2.4-.4.4zm0-2.2h-8.2c-.2 0-.4-.2-.4-.4s.1-.3.3-.3h8.2c.2 0 .4.2.4.4s-.1.3-.3.3z" />
      </IconSVG>
    ),
    map: (
      <IconSVG viewBox="0 0 43 36">
        <path d="M43 34.7l-2.3-17.4c0-.4-.4-.8-.8-.9L36 15.1c-.4.7-.7 1.4-1.1 2l3.5 1.2 2 15-8.3-2.6-.6-8.8c-.4.2-.9.3-1.4.3-.3 0-.7 0-1-.1l.6 8.7-7.2 2.5v-15l2.9-1.1-1.1-2-2.9 1.2-8-3.2c-.3-.1-.6-.1-.9 0l-9.6 3.4c-.4.1-.7.5-.8.9L0 34.7c0 .4.1.7.4 1s.7.3 1.1.2l10.4-3.2 9.2 3.2c.3.1.5.1.8 0l9.2-3.2 10.4 3.2h.4c.3 0 .5-.1.7-.3.3-.1.4-.5.4-.9zm-32.2-4l-8.3 2.6 2-15 7.3-2.5-1 14.9zm9.5 2.6l-7.2-2.5 1-15 6.2 2.5v15zm8.3-13.8c.4.7 1.4.7 1.7 0 0 0 4-8 4.9-9.9.9-1.9 1-4 0-6C33.6.3 29.7-1 26.5.7S22 6.4 23.6 9.6s5 9.9 5 9.9zm.9-15.9c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1c0-1.7 1.4-3.1 3.1-3.1z" />
      </IconSVG>
    ),
    list: (
      <IconSVG viewBox="0 0 28 24">
        <path d="M3 0c1.7 0 3 1.3 3 3S4.7 6 3 6 0 4.7 0 3s1.3-3 3-3zm0 9c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm0 9c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm7.5-13h16.9c.1 0 .3-.1.4-.1.2-.2.2-.3.2-.4v-3c0-.3-.3-.5-.5-.5h-17c-.3 0-.5.3-.5.5v2.9c0 .3.3.6.5.6zm17 5h-17c-.3 0-.5.3-.5.5v2.9c0 .3.3.5.5.5h16.9c.1 0 .3-.1.4-.1.1-.1.1-.3.1-.4v-2.9c.1-.2-.2-.5-.4-.5zm0 9h-17c-.3 0-.5.3-.5.5v2.9c0 .3.3.5.5.5h16.9c.1 0 .3-.1.4-.1.1-.1.1-.3.1-.4v-2.9c.1-.3-.2-.5-.4-.5z" />
      </IconSVG>
    ),
    calendar: (
      <IconSVG viewBox="0 0 15 17">
        <path d="M6 8H4v2h2V8zm3 0H7v2h2V8zm3 0h-2v2h2V8zm1.3-6.3h-.8V0h-1.7v1.7H4.2V0H2.5v1.7h-.8c-1 0-1.7.8-1.7 1.7v11.9c0 .9.7 1.7 1.7 1.7h11.7c.9 0 1.7-.8 1.7-1.7V3.4c-.1-.9-.9-1.7-1.8-1.7zM13 15H2V6h11v9z" />
      </IconSVG>
    ),
    arobase: (
      <IconSVG viewBox="0 0 14 14">
        <path d="M6.5 14c1.3 0 2.3-.1 3.3-.5.3-.1.4-.4.3-.7l-.1-.2c-.1-.3-.4-.4-.6-.3-.8.3-1.7.4-2.5.4-3.1 0-5.3-1.9-5.3-5.3 0-3.8 2.8-6.3 5.9-6.3 3.2 0 4.9 2 4.9 4.6 0 2.3-1.1 3.4-2 3.4-.6 0-.7-.6-.5-1.8l.6-3.7c-.5-.1-1.5-.3-2.4-.3-3 0-4.8 2.2-4.8 4.7 0 1.6 1 2.6 2.3 2.6 1.1 0 2-.5 2.7-1.5.1 1.1.8 1.5 1.8 1.5 2.2 0 3.9-1.9 3.9-4.8C14 2.4 11.4 0 7.7 0 3.1 0 0 3.6 0 7.7 0 11.6 3 14 6.5 14zm1.6-7.1C7.9 8 7.1 9 6.4 9s-1-.5-1-1.2c0-1.5 1.1-2.9 2.4-2.9.3 0 .5 0 .6.1l-.3 1.9z" />
      </IconSVG>
    ),
    www: (
      <IconSVG viewBox="0 0 14 4">
        <path d="M0 0l.9 4h.9l.5-2.6.6 2.6h.9l.9-4h-1l-.4 2.4L2.8 0h-.9l-.5 2.4L.9 0H0zm4.7 0l.9 4h.9L7 1.4 7.5 4h.9l.9-4h-.9L8 2.4 7.5 0h-1L6 2.4 5.6 0h-.9zm4.6 0l.9 4h.9l.5-2.6.6 2.6h.9l.9-4h-.9l-.4 2.4-.6-2.4h-.9l-.5 2.4-.4-2.4h-1z" />
      </IconSVG>
    ),
    phone: (
      <IconSVG viewBox="0 0 14 14">
        <path d="M.1 2C.3 1.3 1.5.2 1.5.2S2-.2 2.7 0c.8.3 1.2 1 1.7 2.3.6 1.4.6 1.4.3 1.8-.2.5-1 1.3-1 1.3.3.9 3.9 4.5 4.8 4.8 0 0 .8-.7 1.3-1 .5-.2.5-.2 1.8.3 1.4.5 2 .9 2.3 1.7.2.7-.2 1.3-.2 1.3s-1 1.2-1.8 1.4C7.8 15.1-1.1 6.2.1 2z" />
      </IconSVG>
    ),
    print: (
      <IconSVG viewBox="-241 243 16 16">
        <path d="M-226.5 246.5h-1v3.5h-11v-3.5h-1c-.8 0-1.5.7-1.5 1.5v6.5c0 .8.7 1.5 1.5 1.5h1v1.5c0 .8.7 1.5 1.5 1.5h8c.8 0 1.5-.7 1.5-1.5V256h1c.8 0 1.5-.7 1.5-1.5V248c0-.8-.7-1.5-1.5-1.5zm-3 10.5h-7v-3.5h7v3.5z" />
        <path d="M-229 244.5c0-.8-.7-1.5-1.5-1.5h-5c-.8 0-1.5.7-1.5 1.5v4h8v-4z" />
      </IconSVG>
    ),
    interrogation: (
      <IconSVG viewBox="0 0 8 14">
        <path d="M2.34273 8.65944V8.01735C2.34273 7.46204 2.46131 6.97903 2.69848 6.56833C2.93565 6.15763 3.36948 5.7209 4 5.25813C4.60738 4.82429 5.00795 4.47144 5.20174 4.19957C5.39552 3.92769 5.49241 3.62401 5.49241 3.2885C5.49241 2.91251 5.35358 2.62618 5.07592 2.4295C4.79826 2.23283 4.4107 2.13449 3.91323 2.13449C3.04555 2.13449 2.0564 2.41793 0.94577 2.98482L0 1.0846C1.28996 0.361529 2.65798 0 4.10412 0C5.29574 0 6.24295 0.286331 6.94577 0.859002C7.64859 1.43167 8 2.19522 8 3.14967C8 3.78598 7.85539 4.3355 7.56616 4.79826C7.27693 5.26103 6.72741 5.78163 5.91757 6.36009C5.36225 6.77079 5.01085 7.08315 4.86334 7.29718C4.71583 7.51121 4.64208 7.79175 4.64208 8.13883V8.65944H2.34273ZM2.06508 11.6269C2.06508 11.141 2.19523 10.7737 2.45553 10.5249C2.71584 10.2762 3.09472 10.1518 3.59219 10.1518C4.07231 10.1518 4.44396 10.2791 4.70716 10.5336C4.97036 10.7881 5.10195 11.1526 5.10195 11.6269C5.10195 12.0839 4.96891 12.444 4.70282 12.7072C4.43673 12.9704 4.06652 13.102 3.59219 13.102C3.10629 13.102 2.7303 12.9732 2.46421 12.7158C2.19812 12.4584 2.06508 12.0954 2.06508 11.6269Z" />
      </IconSVG>
    ),
    landingPageTestType: (
      <IconSVG viewBox="0 0 61 61">
        <g transform="translate(-215.405 -1172)">
          <circle cx="245.9" cy="1202.5" r="20" />
          <path
            fill="#FFF"
            d="M245.9,1178c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5s24.5-11,24.5-24.5S259.4,1178,245.9,1178z
          M256.5,1212.7c0,0.6-0.4,1-1,1h-19.9c-0.6,0-1-0.4-1-1v-19.9c0-0.6,0.4-1,1-1H249c0.6,0,1,0.4,1,1s-0.4,1-1,1h-12.4v17.9h17.9
          v-7.4c0-0.6,0.4-1,1-1s1,0.4,1,1V1212.7z M259.5,1191.3l-11.6,14.9c-0.2,0.2-0.5,0.4-0.8,0.4c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.7-0.3
          l-4.8-5.3c-0.4-0.4-0.3-1,0.1-1.4c0.4-0.4,1-0.3,1.4,0.1l4,4.4l10.8-14c0.3-0.4,1-0.5,1.4-0.2
          C259.8,1190.2,259.9,1190.9,259.5,1191.3z"
          />
        </g>
      </IconSVG>
    ),
    landingPagePrepTool: (
      <IconSVG viewBox="0 0 61 61">
        <g transform="translate(-543.405 -1172)">
          <circle className="st0" cx="573.9" cy="1202.5" r="20" />
          <g>
            <path
              fill="#FFF"
              d="M582.4,1190.3c-0.3-0.3-0.9-0.3-1.2,0l-0.8,0.8l4.3,4.3l0.8-0.8c0.3-0.3,0.3-0.9,0-1.2L582.4,1190.3z"
            />
            <polygon fill="#FFF" points="564.2,1210.7 565,1211.5 569.6,1209.9 565.8,1206.1" />
            <path
              fill="#FFF"
              d="M573.9,1178c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5s24.5-11,24.5-24.5S587.4,1178,573.9,1178z
            M582.5,1200.3l-10.9,10.9c-0.1,0.1-0.2,0.2-0.4,0.2l-6.7,2.4l0,0l0,0l-2.2,0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.1-0.7-0.3
            c-0.3-0.3-0.4-0.7-0.2-1l3.2-8.9c0-0.1,0.1-0.3,0.2-0.4l10.9-11c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-9.9,9.9l4.3,4.3
            l9.8-9.8c0.4-0.4,1-0.4,1.4,0C582.9,1199.3,582.9,1199.9,582.5,1200.3z M586.9,1196l-2.2,2.2l-7.1-7.1l2.2-2.2
            c1.1-1.1,2.9-1.1,4.1,0l3,3.1C588,1193,588,1194.8,586.9,1196z"
            />
          </g>
        </g>
      </IconSVG>
    ),
    b2b: (
      <IconSVG viewBox="0 0 46.2 50.02">
        <path
          fill="none"
          strokeLinejoin="round"
          strokeWidth="2px"
          strokeLinecap="round"
          d="M33.152 35.832v-1.223a.9.9 0 01.9-.9h5.155a.9.9 0 01.894.9v1.223m5.097 6.203v6.09a.9.9 0 01-.9.895H28.945a.9.9 0 01-.894-.895v-6.09m8.66 1.188v-2.522m1.033 1.119H45.2v-3.787a.9.9 0 00-.9-.894H28.943a.9.9 0 00-.894.894v3.787h7.455"
        />
        <path
          fill="none"
          strokeLinejoin="round"
          strokeWidth="2px"
          strokeLinecap="round"
          d="M20.073 1c-8.283 0-8.744 5.009-8.164 11.1s4.414 11.093 8.164 11.093S27.656 18.2 28.236 12.1 28.356 1 20.073 1z"
        />
        <path
          fill="none"
          strokeLinejoin="round"
          strokeWidth="2px"
          strokeLinecap="round"
          d="M22.524 5.917s-.859 4.072-7 4.164m6.277-2.476a22.866 22.866 0 006.348 2.864m-9.38 22.237l-1.151 9.128m3.76-9.128l1.151 9.128m-21.457 0l.707-9.419c.7-5.2 3.825-5.78 3.825-5.78s3.693-.962 7.308-1.567l3.164 6.4m21.398-2.15a4.637 4.637 0 00-2.931-2.683s-3.693-.962-7.308-1.567l-3.164 6.4m-3.986 2.524l-3.313-3.74 3.292-3.738h.021l3.29 3.738z"
        />
      </IconSVG>
    ),
    b2c: (
      <IconSVG>
        <path
          fill="none"
          strokeLinejoin="round"
          strokeWidth="2px"
          strokeLinecap="round"
          d="M20.074 1c-8.283 0-8.743 5.009-8.164 11.1s4.415 11.094 8.164 11.094 7.583-4.993 8.163-11.094S28.357 1 20.074 1z"
        />
        <path
          strokeLinejoin="round"
          strokeWidth="2px"
          strokeLinecap="round"
          d="M22.525 5.917s-.859 4.072-7 4.164m6.278-2.476a22.867 22.867 0 006.348 2.864M39.078 41.834l-.709-9.419c-.7-5.2-3.825-5.78-3.825-5.78s-3.693-.962-7.308-1.567a10.311 10.311 0 01-14.324 0c-3.616.605-7.308 1.567-7.308 1.567s-3.126.579-3.825 5.78l-.707 9.419"
        />
      </IconSVG>
    ),
    cbt: (
      <IconSVG viewBox="0 0 22 21">
        <path
          d="M1.548 1.027c-.262.078-.456.298-.525.595-.02.088-.023.921-.023 6.83 0 5.988.003 6.74.023 6.832a.8.8 0 00.474.573l.089.038 3.575.005 3.573.004v2.499H8.33c-.222 0-.427.005-.455.01-.073.018-.164.123-.188.217-.032.135.036.29.152.343.075.036 6.247.036 6.322 0 .114-.053.183-.212.15-.348-.02-.09-.114-.194-.186-.212a6.404 6.404 0 00-.455-.01h-.404v-2.499l3.575-.004 3.573-.005.09-.038a.8.8 0 00.473-.573c.02-.093.023-.844.023-6.833 0-5.986-.003-6.74-.023-6.832-.07-.299-.266-.516-.535-.594-.078-.023-1.2-.026-9.45-.025-8.122 0-9.37.004-9.444.027zm18.527 7.375l-.005 6.392H1.93l-.005-6.392-.003-6.39h18.156l-.003 6.39z"
          stroke="currentColor"
          fill="currentColor"
        />
        <path d="M11 5h5M6 8h10M6 12h8" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      </IconSVG>
    ),
    pbt: (
      <IconSVG viewBox="0 0 22 21">
        <path
          d="M7.666 18.333H2.333A1.333 1.333 0 011 17V2.333A1.333 1.333 0 012.333 1h13.332a1.333 1.333 0 011.333 1.333v6M7.666 5h5.333m-8 4h8m-8 4h4m11.449-2.116a1.881 1.881 0 00.003-2.66l-.008-.006a1.878 1.878 0 00-2.658 0L11.002 15l-.666 3.333 3.333-.666 3.39-3.391 3.389-3.392z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconSVG>
    ),
  };

  return <>{icons[name]}</>;
};

export default React.memo(
  Icon,
  ({ name: prevName, className: prevClassName }, { name: nextName, className: nextClassName }) =>
    prevName === nextName && prevClassName === nextClassName
);
