import { gql } from '@apollo/client';

import { Auth } from '../fragments';

export const USER_REGISTRATION = gql`
  mutation USER_REGISTRATION($user: UserInput!, $recaptchaToken: String!) {
    authResult: registerUser(user: $user, recaptchaToken: $recaptchaToken) {
      ... on Auth {
        ...Auth
      }
      ... on Error {
        reason
      }
    }
  }
  ${Auth}
`;
