import { gql } from '@apollo/client';

export const CHANGE_USER_PASSWORD = gql`
  mutation CHANGE_USER_PASSWORD($currentPassword: String!, $newPassword: String!) {
    authResult: changeUserPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      ... on ChangePassword {
        status
      }
      ... on Error {
        reason
      }
    }
  }
`;
