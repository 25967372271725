import React from 'react';
import classNames from 'classnames';

import HtmlTitle from '../../../Element/Title';

import './styles.scss';

const Title = ({ children, className }) => {
  return (
    <HtmlTitle lvl={2} className={classNames('title', className)}>
      {children}
    </HtmlTitle>
  );
};

export default Title;
