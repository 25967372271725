import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import './styles.scss';

const TextareaField = ({ className, hasError, ...props }) => {
  const allowedTextareaFieldProps = pick(props, Object.keys(TextareaField._propTypes));

  return (
    <textarea
      className={classNames('textarea', 'textarea--dotted', className, {
        'textarea--error': hasError,
        'has-value': props.value !== '',
      })}
      {...allowedTextareaFieldProps}
    />
  );
};

TextareaField._propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
};

TextareaField.propTypes = TextareaField._propTypes;

TextareaField.defaultProps = {
  type: 'textarea',
  value: '',
  onChange: () => {},
};

export default TextareaField;
