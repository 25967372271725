import { useApolloClient } from '@apollo/client';

import { NEW_NOTIFICATION } from '../graphql/mutations';

const useNotification = () => {
  const apolloClient = useApolloClient();

  const newNotification = ({ content, type = 'success' }) => {
    apolloClient.mutate({
      mutation: NEW_NOTIFICATION,
      variables: {
        input: { content, type },
      },
    });
  };

  return {
    newNotification,
  };
};

export default useNotification;
