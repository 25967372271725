import { gql } from '@apollo/client';

import { Auth } from '../fragments';

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($password: String!, $token: String!) {
    resetPassword(newPassword: $password, token: $token) {
      ...Auth
    }
  }
  ${Auth}
`;
