import { env } from './env';
import { isSsr } from './ssr';

export const getVersion = (headers = {}) =>
  isSsr
    ? global.version
    : fetch(`${env('RAZZLE_B2C_WEBSITE_URL')}/version.json`, { headers })
        .then((response) => response.json())
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error, 'Could not get hash version');
        });

export default () => {
  setInterval(
    () => {
      if (env('RAZZLE_STAGE') !== 'dev') {
        getVersion().then((data) => {
          if (data.hash !== window.hashVersion) {
            window.location.reload();
          }
        });
      }
    },
    1000 * 60 * 15
  );
};
