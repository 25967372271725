import React from 'react';
import CheckboxField from '../Checkbox';
import { useTranslation } from 'react-i18next';
import { CONTACT_ROLES } from '../../../../constants';

const ContactRolesFields = ({ form, push, remove }) => {
  const { t } = useTranslation();

  let contactRoles = [];
  for (const role in CONTACT_ROLES) {
    contactRoles.push({
      value: role,
      label: t(`contact.form.lead.roles.${role.toLowerCase()}`),
      tooltipText: t(`contact.form.lead.role-tooltip.${role.toLowerCase()}`),
    });
  }

  return contactRoles
    .map((option, key) => (
      <div className="lead-form__checkbox-tooltip" key={key}>
        <CheckboxField
          id={`checkbox_${option.value}`}
          label={option.label}
          name={`checkbox_${option.value}`}
          value={form.values.roles.includes(option.value)}
          onChange={(e) => {
            if (e.target.checked) {
              push(option.value);
            } else {
              const idx = form.values.roles.indexOf(option.value);
              remove(idx);
            }
          }}
          icon={'info'}
          tooltipContent={option.tooltipText}
        />
      </div>
    ))
    .reduce((row, element, index) => {
      index % 2 === 0 && row.push([]);
      row[row.length - 1].push(element);

      return row;
    }, [])
    .map((rowContent, key) => {
      return (
        <div className="lead-form__roles-column" key={key}>
          {rowContent}
        </div>
      );
    });
};

export default ContactRolesFields;
