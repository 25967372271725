import React from 'react';

import CheckboxField from './index';

const CheckboxGroup = ({ arrayHelpers: { form, push, remove }, name, options, ...props }) =>
  options.map((option, key) => (
    <CheckboxField
      key={key}
      id={`checkbox_${option.value}`}
      label={option.label}
      name={`checkbox_${option.value}`}
      value={form.values[name].includes(option.value)}
      onChange={(e) => {
        if (e.target.checked) {
          push(option.value);
        } else {
          const idx = form.values[name].indexOf(option.value);
          remove(idx);
        }
      }}
      {...props}
    />
  ));

export default CheckboxGroup;
