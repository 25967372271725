import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxField from '../Checkbox/index';
import { PRODUCTS } from '../../../../constants';

import './styles.scss';

const ProductsFields = ({ form, push, remove }) => {
  const { t } = useTranslation();

  let products = [];
  for (const product in PRODUCTS) {
    products.push({
      value: product,
      label: t(`contact.form.label.organisation.product.${product.toLowerCase()}`),
      tooltipText: t(`contact.form.label.organisation.product-tooltip.${product.toLowerCase()}`),
    });
  }

  return products.map((option, key) => (
    <div className="product-fields" key={key}>
      <CheckboxField
        id={`checkbox_${option.value}`}
        label={option.label}
        name={`checkbox_${option.value}`}
        value={form.values.products.includes(option.value)}
        onChange={(e) => {
          if (e.target.checked) {
            push(option.value);
          } else {
            const idx = form.values.products.indexOf(option.value);
            remove(idx);
          }
        }}
        icon={'info'}
        tooltipContent={option.tooltipText}
      />
    </div>
  ));
};

export default ProductsFields;
