import { gql } from '@apollo/client';

import { Image, Link, Meta } from '../fragments';
import { ECBlock } from '../fragments/ecBlock';

export const RESULT_PAGE_PRACTICE_TEST = gql`
  query RESULT_PAGE_PRACTICE_TEST($lang: String!, $country: String!, $sessionId: Int!) {
    resultPagePracticeTest(lang: $lang, country: $country, sessionId: $sessionId) {
      id
      slug
      CEFRLevel
      dateOfTest
      testTypeFamily {
        id
        slug
        color
        name
        testTypes(lang: $lang, country: $country) {
          id
        }
      }
      summaryBox {
        title
        content(country: $country, lang: $lang)
        link {
          ...Link
        }
      }
      blocks {
        ...ECBlock
      }
      links {
        ...Link
      }
      documents {
        ...Link
      }
      meta {
        ...Meta
      }
    }
  }
  ${Image}
  ${ECBlock}
  ${Link}
  ${Meta}
`;
