import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

import { Link } from '@ets-global/b2c-website-ui';

import './tooltip.scss';

const Tooltip = ({ children, icon, position, width, label, onClick, className }) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target) && isActive) {
        setIsActive(false);
      } else if (ref && ref.current && ref.current.contains(event.target) && isActive) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  const toggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsActive(!isActive);
  };

  return (
    <div
      className={classNames('tooltip', `tooltip--${position}`, { 'tooltip--active': isActive }, className)}
      ref={ref}
    >
      <Link onClick={onClick ? onClick : toggle} icon={icon}>
        {label}
      </Link>

      <div
        className="tooltip__content"
        style={{
          width: width,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
