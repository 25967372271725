import { gql } from '@apollo/client';

export const PLACE_AUTOCOMPLETE_RESULT = gql`
  query PlaceAutocompleteResult($input: PlaceInput!) {
    placeAutocompleteResult(input: $input) {
      prediction
      placeId
    }
  }
`;
