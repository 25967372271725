import { gql } from '@apollo/client';

export const NEW_NOTIFICATION = gql`
  mutation NEW_NOTIFICATION($input: NotificationInput!) {
    newNotification(input: $input) @client
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DELETE_NOTIFICATION($id: Int!) {
    deleteNotification(id: $id) @client
  }
`;
