import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';

import Icon from '@ets-global/b2c-website-ui/Icon';

const FlashMessage = ({ message, ttl, type = 'success', isDismissible = true, onDismiss = () => {} }) => {
  const [classname, setClassname] = useState('flash-message--opening');
  let closingTimeout = null;

  useEffect(() => {
    const ttlTimeout = setTimeout(() => {
      closeFlashMessage();
    }, ttl);

    return () => {
      clearTimeout(ttlTimeout);
      clearTimeout(closingTimeout);
    };
  }, []);

  const closeFlashMessage = () => {
    closingTimeout = setTimeout(() => {
      onDismiss();
    }, 1000);
    setClassname('flash-message--closing');
  };

  const alertClass = (type) => {
    const classes = {
      success: 'flash-message--success',
      warning: 'flash-message--warning',
      error: 'flash-message--error',
    };

    return classes[type];
  };

  return (
    <div className={classNames('flash-message', alertClass(type), classname)} role="alert">
      <span className="alert__message">{message}</span>

      {isDismissible && (
        <button className="flash-message__dismiss" onClick={closeFlashMessage} data-cypress="flash-message-close">
          <Icon name={'plus'} />
        </button>
      )}
    </div>
  );
};

FlashMessage.propTypes = {
  message: string.isRequired,
  type: string,
  isDismissible: bool,
};

export default FlashMessage;
