import './AutocompleteField.scss';

import classNames from 'classnames';
import { useCombobox, UseComboboxProps } from 'downshift';
import React, { RefObject, useEffect, useMemo, useState } from 'react';

import { TextField, TextFieldProps } from '../Text';
import { AutocompleteOption, AutocompleteOptionLabel } from './AutocompleteOption';

export interface AutocompleteItem {
  label: string;
  value: string;
}

export interface AutocompleteFieldOptions {
  /**
   * The input props of autocomplete field
   */
  inputProps?: Omit<TextFieldProps, 'value' | 'onChange'>;
  /**
   * The items of autocomplete field
   */
  items?: AutocompleteItem[];
  /**
   * The label for loading of autocomplete field
   */
  loadingLabel?: string;
  /**
   * The label for empty items of autocomplete field
   */
  emptyItemsLabel?: string;
  /**
   * The callback that display option labels of autocomplete field
   */
  renderOptionLabel?: (options: { item: AutocompleteItem; inputValue: string }) => React.ReactNode;
  /**
   * The Minimum number of characters to start the search
   */
  minLengthToSearch?: number;
  /**
   * The label to inform on the minimum number of characters to start the search
   */
  minLettersLabel?: string;
  /**
   * Loading boolean
   */
  loading?: boolean;
}

export interface AutocompleteFieldProps
  extends Omit<UseComboboxProps<AutocompleteItem>, 'items'>,
    AutocompleteFieldOptions {}

export const AutocompleteField = React.forwardRef<HTMLInputElement, AutocompleteFieldProps>(
  (
    {
      items = [],
      loadingLabel,
      emptyItemsLabel,
      renderOptionLabel,
      defaultHighlightedIndex = 0,
      minLengthToSearch = 1,
      minLettersLabel,
      loading,
      ...props
    },
    ref
  ) => {
    const [inputItems, setInputItems] = useState<AutocompleteItem[]>(items);

    useEffect(() => {
      setInputItems(items);
    }, [items]);

    const {
      isOpen,
      inputValue,
      setInputValue,
      selectedItem,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
    } = useCombobox<AutocompleteItem>({
      ...props,
      items: inputItems,
      itemToString: props?.itemToString ? props.itemToString : (item: AutocompleteItem): string => item?.label,
      defaultHighlightedIndex,
      onInputValueChange: async (options) => {
        if (props.onInputValueChange) {
          props.onInputValueChange(options);
        }
        const { inputValue } = options;
        if (inputValue.length < minLengthToSearch) {
          setInputItems([]);
          return;
        }

        setInputItems(items);
      },
      onStateChange: (changes) => {
        if (changes.type === useCombobox.stateChangeTypes.InputBlur) {
          if (selectedItem) {
            setInputValue(selectedItem.label);
            return;
          }
          setInputValue(props?.initialInputValue ?? '');
        }
      },
      onSelectedItemChange: (changes) => {
        props.onSelectedItemChange(changes);
        (ref as RefObject<HTMLInputElement>)?.current?.blur();
      },
    });

    const options = useMemo(
      () =>
        inputItems.map((item, index) => (
          <AutocompleteOption
            key={`${item.value}${index}`}
            label={
              renderOptionLabel ? (
                renderOptionLabel({ item, inputValue })
              ) : (
                <AutocompleteOptionLabel item={item} inputValue={inputValue} />
              )
            }
            highlighted={highlightedIndex === index}
            {...getItemProps({ item, index })}
          />
        )),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [inputValue, inputItems, highlightedIndex, getItemProps]
    );

    const onAutoCompleteFieldFocus = (e): void => {
      e.target.value = '';
    };

    const onAutoCompleteFieldBlur = (e): void => {
      e.target.value = selectedItem ? selectedItem.label : props.initialInputValue;
    };

    return (
      <div className="autocomplete-field-container" data-test="places-autocomplete">
        <div {...getComboboxProps()}>
          <TextField
            {...getInputProps({
              ref,
              onFocus: onAutoCompleteFieldFocus,
              onBlur: onAutoCompleteFieldBlur,
              ...props.inputProps,
            })}
          />
        </div>
        <ul
          {...getMenuProps()}
          className={classNames('autocomplete-menu', {
            'autocomplete-menu--open': isOpen,
          })}
        >
          {isOpen &&
            (loading ? (
              <AutocompleteOption label={loadingLabel} />
            ) : inputValue.length < minLengthToSearch ? (
              <AutocompleteOption label={minLettersLabel} />
            ) : options.length > 0 ? (
              options
            ) : (
              <AutocompleteOption label={emptyItemsLabel} />
            ))}
        </ul>
      </div>
    );
  }
);
