import { useEffect, useRef } from 'react';

const useTimeout = ({ callback, delay }) => {
  let timeoutId = useRef();
  let currentCallback = useRef();

  useEffect(() => {
    currentCallback.current = callback;
  });

  const stop = () => {
    clearTimeout(timeoutId.current);
  };

  const start = () => {
    timeoutId.current = setTimeout(() => currentCallback.current({ stop }), delay);
  };

  const restart = () => {
    stop();
    start();
  };

  useEffect(() => {
    if (delay !== null) {
      start();

      return () => stop();
    }
  }, [delay]);

  return { timeoutId: timeoutId.current, start, restart, stop };
};

export default useTimeout;
