import { gql } from '@apollo/client';

const ECRoundImageBlock = gql`
  fragment ECRoundImageBlock on ECRoundImageBlock {
    title
    subtitle
    text(country: $country, lang: $lang)
    images {
      ...Image
    }
    imagePosition
    backgroundColor
    link {
      ...Link
    }
  }
`;

const ECSquareImageBlock = gql`
  fragment ECSquareImageBlock on ECSquareImageBlock {
    title
    text(country: $country, lang: $lang)
    images {
      ...Image
    }
    imagePosition
    backgroundColor
    link {
      ...Link
    }
  }
`;

const ECRichTextEditorBlock = gql`
  fragment ECRichTextEditorBlock on ECRichTextEditorBlock {
    title
    text(country: $country, lang: $lang)
    backgroundColor
    link {
      ...Link
    }
  }
`;

const ECVideoBlock = gql`
  fragment ECVideoBlock on ECVideoBlock {
    title
    video
    text(country: $country, lang: $lang)
    backgroundColor
  }
`;

const ECQuoteBlock = gql`
  fragment ECQuoteBlock on ECQuoteBlock {
    quote
    author
    backgroundColor
  }
`;

const ECStepsBlock = gql`
  fragment ECStepsBlock on ECStepsBlock {
    title
    steps
    backgroundColor
  }
`;

const ECIconsBlock = gql`
  fragment ECIconsBlock on ECIconsBlock {
    title
    icons {
      icon {
        ...Image
      }
      title
      description
    }
    backgroundColor
  }
`;

const ECSessionsBlock = gql`
  fragment ECSessionsBlock on ECSessionsBlock {
    display
  }
`;

const ECPreparationToolsBlock = gql`
  fragment ECPreparationToolsBlock on ECPreparationToolsBlock {
    displayOnlinePrepTool
    displayBookPrepTool
  }
`;

const ECSmallVideoBlock = gql`
  fragment ECSmallVideoBlock on ECSmallVideoBlock {
    title
    description(country: $country, lang: $lang)
    video
    backgroundColor
    link {
      ...Link
    }
  }
`;

const ECHorizontalIconContentListBlock = gql`
  fragment ECHorizontalIconContentListBlock on ECHorizontalIconContentListBlock {
    title
    subtitle
    backgroundColor
    horizontalIconContentItems: items {
      title
      description(country: $country, lang: $lang)
      icon {
        ...Image
      }
      link {
        ...Link
      }
      exposeProctorexamCheckRequirementsLink
    }
  }
`;

const ECIconBulletPointListBlock = gql`
  fragment ECIconBulletPointListBlock on ECIconBulletPointListBlock {
    title
    subtitle
    backgroundColor
    iconBulletPointItems: items {
      icon {
        ...Image
      }
      description(country: $country, lang: $lang)
    }
  }
`;

const ECLinksBlock = gql`
  fragment ECLinksBlock on ECLinksBlock {
    title
    subtitle
    backgroundColor
    linkItems: items {
      title
      description(country: $country, lang: $lang)
      link {
        ...Link
      }
    }
  }
`;

const ECFaqBlock = gql`
  fragment ECFaqBlock on ECFaqBlock {
    title
    subtitle
    backgroundColor
    faqItems: items {
      question
      answer(country: $country, lang: $lang)
    }
  }
`;

const ECTableBlock = gql`
  fragment ECTableBlock on ECTableBlock {
    title
    subtitle
    backgroundColor
    tableItems: items {
      title
      description(country: $country, lang: $lang)
    }
  }
`;

const ECLegalSentenceForHomeTestBlock = gql`
  fragment ECLegalSentenceForHomeTestBlock on ECLegalSentenceForHomeTestBlock {
    title
    display
    backgroundColor
  }
`;

export const ECBlock = gql`
  fragment ECBlock on ECBlock {
    ... on ECRoundImageBlock {
      ...ECRoundImageBlock
    }
    ... on ECSquareImageBlock {
      ...ECSquareImageBlock
    }
    ... on ECVideoBlock {
      ...ECVideoBlock
    }
    ... on ECRichTextEditorBlock {
      ...ECRichTextEditorBlock
    }
    ... on ECQuoteBlock {
      ...ECQuoteBlock
    }
    ... on ECSessionsBlock {
      ...ECSessionsBlock
    }
    ... on ECPreparationToolsBlock {
      ...ECPreparationToolsBlock
    }
    ... on ECStepsBlock {
      ...ECStepsBlock
    }
    ... on ECIconsBlock {
      ...ECIconsBlock
    }
    ... on ECSmallVideoBlock {
      ...ECSmallVideoBlock
    }
    ... on ECHorizontalIconContentListBlock {
      ...ECHorizontalIconContentListBlock
    }
    ... on ECIconBulletPointListBlock {
      ...ECIconBulletPointListBlock
    }
    ... on ECLinksBlock {
      ...ECLinksBlock
    }
    ... on ECFaqBlock {
      ...ECFaqBlock
    }
    ... on ECTableBlock {
      ...ECTableBlock
    }
    ... on ECLegalSentenceForHomeTestBlock {
      ...ECLegalSentenceForHomeTestBlock
    }
  }
  ${ECRoundImageBlock}
  ${ECSquareImageBlock}
  ${ECVideoBlock}
  ${ECRichTextEditorBlock}
  ${ECQuoteBlock}
  ${ECSessionsBlock}
  ${ECPreparationToolsBlock}
  ${ECStepsBlock}
  ${ECIconsBlock}
  ${ECSmallVideoBlock}
  ${ECHorizontalIconContentListBlock}
  ${ECIconBulletPointListBlock}
  ${ECLinksBlock}
  ${ECFaqBlock}
  ${ECTableBlock}
  ${ECLegalSentenceForHomeTestBlock}
`;

export const ECDiscountBlock = gql`
  fragment ECDiscountBlock on ECDiscountBlock {
    title
    discounts(lang: $lang, country: $country, referrer: $referrer) {
      code
      title
      subtitle
      text(country: $country, lang: $lang)
      link {
        ...Link
      }
      product {
        __typename
        ... on DiscountProductTestType {
          testTypeId
          color
        }
        ... on DiscountProductPrepTool {
          prepToolId
        }
        title
        price {
          value
          discountedPrice
          discountType
          discountAmount
          currency
        }
      }
    }
  }
`;
