import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';

import { Icon } from '@ets-global/b2c-website-ui';

import { useRouter } from '../../../hooks';

import './styles.scss';

const SocialShare = ({ title }) => {
  const { currentRoute, generateUrl } = useRouter();
  const shareUrl = generateUrl(currentRoute.path, currentRoute.params);

  return (
    <Fragment>
      <LinkedinShareButton url={shareUrl} title={title} windowWidth={750} windowHeight={600}>
        <div className="share-button">
          <Icon name="linkedin" />
        </div>
      </LinkedinShareButton>
      <FacebookShareButton url={shareUrl}>
        <div className="share-button">
          <Icon name="facebook" />
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <div className="share-button">
          <Icon name="twitter" />
        </div>
      </TwitterShareButton>
    </Fragment>
  );
};

SocialShare.propTypes = {
  title: PropTypes.string,
};

export default SocialShare;
