import { gql } from '@apollo/client';

export const GLOBAL_DOCUMENTS = gql`
  query GLOBAL_DOCUMENTS($lang: String!, $country: String!) {
    globalDocuments(lang: $lang, country: $country) {
      termsAndConditions
      privacy
      testTakersWithDisabilitiesUrl: testTakersWithDisabilities
    }
  }
`;
