import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import omit from 'lodash/omit';

import RouterContext from './RouterContext';
import { COOKIE_MAX_AGE } from '../../constants';
import { isBrowser } from '../../helpers/ssr';
import TraceHeader from '../../helpers/traceHeader';
import { Route } from 'react-router';
import { LocalizedRedirect } from '../../components/Routing';

export const RouterProvider = ({ routes, partnerDomain, children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies();
  const langCountryMatch = location.pathname.match(/([a-z]{2})\/([a-z]{2})/);

  if (langCountryMatch < 3) {
    return <LocalizedRedirect to="/" />;
  }

  const [, country, lang] = [...langCountryMatch];

  const parents = matchRoutes(routes, location.pathname);
  const lastRoute = parents[parents.length - 1];

  const currentRoute = {
    pathname: location.pathname,
    ...omit(lastRoute.route, ['exact', 'component', 'render', 'routes', 'exact', 'strict']),
    ...lastRoute.match,
    search: location.search,
  };

  useEffect(() => {
    if (isBrowser && (!cookies.locale || lang.toLowerCase() !== cookies.locale.toLowerCase())) {
      setCookie('locale', lang, { path: '/', maxAge: COOKIE_MAX_AGE });
    }
    if (i18n.language.toLowerCase() !== lang.toLowerCase()) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    if (isBrowser && (!cookies.country || country.toLowerCase() !== cookies.country.toLowerCase())) {
      setCookie('country', country, { path: '/', maxAge: COOKIE_MAX_AGE });
    }
  }, [country]);

  useEffect(() => {
    const traceHeader = TraceHeader.getInstance();
    traceHeader.updateTokenProcess();
  }, [location.pathname]);

  return useMemo(
    () => (
      <RouterContext.Provider value={{ country, lang, currentRoute, partnerDomain }}>
        {children(renderRoutes(routes))}
      </RouterContext.Provider>
    ),
    [location.pathname, location.search],
  );
};

export const Router = ({ routerComponent: RouterComponent = BrowserRouter, routes, children, ...routerProps }) => (
  <RouterComponent {...routerProps}>
    <Route
      render={({ staticContext }) => {
        let patnerDomain = staticContext && staticContext.partnerDomain ? staticContext.partnerDomain : null;
        if (isBrowser) {
          patnerDomain = window.partnerDomain || null;
        }

        return (
          <RouterProvider routes={routes} partnerDomain={patnerDomain}>
            {children}
          </RouterProvider>
        );
      }}
    />
  </RouterComponent>
);
