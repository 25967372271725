import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import Field from './Field';

const FormikField = ({ component: FieldComponent, formik, ...props }) => (
  <Field FieldComponent={FieldComponent} formik={formik} {...props} />
);

FormikField.propTypes = {
  component: PropTypes.func.isRequired,
};

export default connect(FormikField);
