import { gql } from '@apollo/client';

export const GENERATE_TESTING_TOKEN = gql`
  mutation GENERATE_TESTING_TOKEN(
    $testingSlug: String!
    $destinationUrlIsAlreadyPassed: String!
    $destinationUrlIsNotAlreadyPassed: String!
    $region: String!
    $country: String!
    $userStatus: String!
  ) {
    token: generateTestingToken(
      testingSlug: $testingSlug
      destinationUrlIsAlreadyPassed: $destinationUrlIsAlreadyPassed
      destinationUrlIsNotAlreadyPassed: $destinationUrlIsNotAlreadyPassed
      region: $region
      country: $country
      userStatus: $userStatus
    )
  }
`;
