import './SelectOrNumberField.scss';

import classNames from 'classnames';
import pick from 'lodash/pick';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { allowedSelectFieldProps, SelectField, SelectFieldProps } from '../Select';
import { allowedTextFieldProps, TextField, TextFieldProps } from '../Text';

interface SelectOrNumberFieldOptions {
  /**
   * The method called when the value changes of select or number field
   */
  setFieldValue: (value: number) => void;
  /**
   * The value of select or number field
   */
  value?: string | number;
  /**
   * The select or number field is mandatory
   */
  isMandatory?: boolean;
  /**
   * The select or number field allow zero
   */
  allowZero?: boolean;
}

export type SelectOrNumberFieldProps = SelectOrNumberFieldOptions &
  (Omit<TextFieldProps, 'value'> | Omit<SelectFieldProps, 'value' | 'options'>);

const ENTER_KEY = 13;
const castStringToNumber = (value?: string | number): null | number =>
  value ? (typeof value === 'string' ? parseFloat(value) : value) : null;

export const SelectOrNumberField: React.FC<SelectOrNumberFieldProps> = ({
  className,
  setFieldValue,
  value: initialValue,
  isMandatory,
  allowZero = true,
  ...props
}) => {
  const selectFieldProps = pick(props, allowedSelectFieldProps) as SelectFieldProps;
  const textFieldProps = pick(props, allowedTextFieldProps) as TextFieldProps;
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<number>(castStringToNumber(initialValue));
  const isSelect = initialValue < 10;

  const options = useMemo(() => {
    const numberOptions = Array.from({ length: 11 }).map((x, i) => ({
      value: i,
      label: i === 10 ? `${i}+` : i,
    }));

    if (isMandatory || !allowZero) {
      numberOptions.shift();
    }

    return numberOptions;
  }, [isMandatory, allowZero]);

  useEffect(() => {
    setValue(castStringToNumber(initialValue));

    if (textFieldRef?.current) {
      textFieldRef.current.focus();
    }
  }, [initialValue, textFieldRef]);

  const onBlur = (): void => {
    if (value !== initialValue) {
      setFieldValue(value);
    }
  };

  const onKeyDown = (e): void => {
    if (e.keyCode === ENTER_KEY && value !== initialValue) {
      setFieldValue(value);
    }
  };

  return isSelect ? (
    <SelectField
      {...selectFieldProps}
      options={options}
      value={value}
      onChange={(e): void => setFieldValue(castStringToNumber(e.target.value))}
      className={classNames('select-or-text', className)}
    />
  ) : (
    <TextField
      {...textFieldProps}
      ref={textFieldRef}
      type="number"
      min={allowZero ? 0 : 1}
      value={value}
      onChange={(e): void => setValue(castStringToNumber(e.target.value))}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className={classNames('select-or-text', className)}
    />
  );
};
