import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useSSR } from 'react-i18next';
import { StaticRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { env } from '../../helpers/env';
import routes from '../../routes';
import { AppProvider } from '../../store/App';
import { AuthProvider } from '../../store/Auth';
import i18n from '../../store/i18n';
import { ModalProvider, ModalRoot } from '../../store/Modal';
import { PurchaseProvider } from '../../store/Purchase';
import { Router } from '../../store/Router/Router';
import ErrorBoundary from '../ErrorBoundary';
import FlashMessages from '../FlashMessages/FlashMessages';
import { LoadedPage } from '../LoadedPage';
import ScrollToTop from '../ScrollToTop';

const RootBrowser = ({ client }) => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  if (env('NODE_ENV') !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
  }

  return (
    <ErrorBoundary>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <HelmetProvider>
          <Helmet titleTemplate="%s | ETS Global" />
          <I18nextProvider i18n={i18n}>
            <ApolloProvider client={client}>
              <Router routerComponent={BrowserRouter} routes={routes}>
                {(routing) => (
                  <AuthProvider>
                    <AppProvider>
                      <FlashMessages />
                      <PurchaseProvider>
                        <LoadedPage>
                          <ModalProvider>
                            <ScrollToTop />
                            <ModalRoot />
                            {routing}
                          </ModalProvider>
                        </LoadedPage>
                      </PurchaseProvider>
                    </AppProvider>
                  </AuthProvider>
                )}
              </Router>
            </ApolloProvider>
          </I18nextProvider>
        </HelmetProvider>
      </CookiesProvider>
    </ErrorBoundary>
  );
};

const RootServer = ({ client, i18n, url, context, cookies, helmetContext }) => {
  return (
    <CookiesProvider cookies={cookies} defaultSetOptions={{ path: '/' }}>
      <HelmetProvider context={helmetContext}>
        <Helmet titleTemplate="%s | ETS Global" />
        <I18nextProvider i18n={i18n}>
          <ApolloProvider client={client}>
            <Router routerComponent={StaticRouter} location={url} context={context} routes={routes}>
              {(routing) => (
                <AuthProvider>
                  <AppProvider>
                    <FlashMessages />
                    <PurchaseProvider>
                      <LoadedPage>
                        <ModalProvider>
                          <ModalRoot />
                          {routing}
                        </ModalProvider>
                      </LoadedPage>
                    </PurchaseProvider>
                  </AppProvider>
                </AuthProvider>
              )}
            </Router>
          </ApolloProvider>
        </I18nextProvider>
      </HelmetProvider>
    </CookiesProvider>
  );
};

export { RootBrowser, RootServer };
