import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { useRouter } from '../../hooks';
import ChangeStatusCode from './changeStatusCode';

const LocalizedRedirect = ({ to, status }) => {
  const { generatePath } = useRouter();

  if (typeof to === 'string') {
    to = generatePath(to);
  } else if (to && to.pathname) {
    to = { pathname: generatePath(to.pathname), search: to.search, state: to?.state };
  }

  return (
    <ChangeStatusCode status={status && [301, 302].includes(status) ? status : 302}>
      <Redirect to={to} />
    </ChangeStatusCode>
  );
};

LocalizedRedirect.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  status: PropTypes.number,
};

export default LocalizedRedirect;
