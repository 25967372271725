import React, { useState } from 'react';

import ModalContext from './ModalContext';

const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    component: null,
    props: {},
  });

  const showModal = (component, props = {}) => {
    setState({
      component,
      props,
    });
  };

  const hideModal = () =>
    setState({
      component: null,
      props: {},
    });

  return <ModalContext.Provider value={{ ...state, showModal, hideModal }}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
