import { gql } from '@apollo/client';

import { Image, Meta } from '../fragments';

export const PREPTOOL_VERSION_FRAGMENT = gql`
  fragment PreparationToolVersion on PreparationToolVersion {
    name
    slug
    articleStockId
    shortDescription(country: $country, lang: $lang)
    longDescription(country: $country, lang: $lang)
    price {
      value
      discountedPrice
      currency
    }
    relatedFamily {
      id
      type
      testTypeFamilyName
    }
    image {
      book {
        ...Image
      }
      url
      alt
    }
    levels
    levelAlternative
    isbn
    author
    meta {
      ...Meta
    }
  }
  ${Image}
  ${Meta}
`;

export const PREPTOOLS_VERSIONS = gql`
  query PREPARATION_TOOLS_VERSION(
    $preparationToolVersionInput: PreparationToolVersionInput
    $lang: String!
    $country: String!
  ) {
    preparationToolVersion(preparationToolVersionInput: $preparationToolVersionInput, country: $country, lang: $lang) {
      ...PreparationToolVersion
    }
  }
  ${PREPTOOL_VERSION_FRAGMENT}
`;

export const PREPTOOLS_VERSION = gql`
  query PREPARATION_TOOL_VERSION($lang: String!, $country: String!, $slug: String!, $discount: DiscountInput) {
    preparationTool(lang: $lang, country: $country, slug: $slug, discount: $discount) {
      ...PreparationToolVersion
    }
  }
  ${PREPTOOL_VERSION_FRAGMENT}
`;
