import React from 'react';
import { number } from 'prop-types';

const Title = ({ lvl, children, ...props }) => React.createElement('h' + lvl, props, children);

Title.propTypes = {
  lvl: number.isRequired,
};

export default Title;
