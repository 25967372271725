import { gql } from '@apollo/client';

import { AlternateLanguage } from '../fragments/common';

export const ALTERNATE_LANGUAGES = gql`
  query ALTERNATE_LANGUAGES($lang: String!, $params: [AlternateLanguageInput]!) {
    alternateLanguages(lang: $lang, params: $params) {
      ...AlternateLanguage
    }
  }
  ${AlternateLanguage}
`;
