import { gql } from '@apollo/client';

export const User = gql`
  fragment User on User {
    id
    civility
    firstname
    lastname
    email
    preferredLanguage
    preferredCountry: country
    isB2B
    hasOptIn
    hasPurchases
  }
`;
