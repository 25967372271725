import { SITE_LOCALES } from '../../constants';
import { env } from '../../helpers/env';

const authorizedEnv = ['dev', 'demo', 'qualif'];
const authorizedLanguages = [SITE_LOCALES.ENGLISH, SITE_LOCALES.FRENCH];
if (authorizedEnv.includes(env('RAZZLE_STAGE'))) {
  authorizedLanguages.push(SITE_LOCALES.DEBUGGING_TRANSLATION);
}

const i18nConfig = {
  load: 'languageOnly',
  preload: authorizedLanguages,
  whitelist: authorizedLanguages,
  returnEmptyString: false,
  defaultNS: 'messages',
  ns: 'messages',
  backend: {
    loadPath: `${env('RAZZLE_B2C_WEBSITE_TRANSLATIONS_ENDPOINT_URI')}/{{ns}}.{{lng}}.json`,
    crossDomain: true,
  },
  react: {
    bindI18n: 'languageChanged',
  },
};

export default i18nConfig;
