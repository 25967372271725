import { createContext } from 'react';

const AppContext = createContext({
  countries: [],
  country: {
    name: null,
    regionName: null,
    iso2: null,
    emoIso2: null,
    locales: [],
    isInOutOfFootprintSubRegion: null,
  },
  city: null,
  region: null,
  coordinates: {
    latitude: null,
    longitude: null,
  },
});

export default AppContext;
