import useRouter from './useRouter';

const getBCP47Format = (locale: string, country: string): string => `${locale.toLowerCase()}-${country.toUpperCase()}`;

export const numberByLocale = (options: {
  value: number;
  lang: string;
  country: string;
  style?: string;
  currency?: string;
}): string => {
  if (options.currency) {
    return new Intl.NumberFormat(getBCP47Format(options.lang, options.country), {
      style: 'currency',
      currency: options.currency,
    }).format(options.value);
  }

  return new Intl.NumberFormat(getBCP47Format(options.lang, options.country), {
    style: options.style || 'decimal',
    ...(options.style === 'percent' ? { minimumFractionDigits: 1 } : {}),
  }).format(options.value);
};

export type UseNumberByLocale = (options: { currency: string; value: number; style?: string }) => string;

export const useNumberByLocale = (): UseNumberByLocale => {
  const { lang, countryIso2 } = useRouter();
  return (options) => numberByLocale({ ...options, lang, country: countryIso2 });
};
