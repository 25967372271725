import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { Link as InternalAnchor } from 'react-router-dom';

import { useRouter } from '../../../hooks';
import { isUrlExternal } from '../../helper/urlChecker';

const ExternalAnchor = ({ children, ...props }) => (
  <a target="_blank" rel="noopener" {...props}>
    {children}
  </a>
);

const Anchor = ({ className, children, to = null, params = {}, search = null, prevSearch = null, ...props }) => {
  const { generatePath, generatePrismicDocumentTypePath, generateCustomLink, countryIso2, lang } = useRouter();

  let allowedLinkProps = pick(props, [
    'disabled',
    'aria-label',
    'data-cypress',
    'style',
    'target',
    'onClick',
    'onMouseOver',
    'style',
  ]);

  let ComponentAnchor = InternalAnchor;
  allowedLinkProps.hrefLang = `${(params.locale || lang).toLowerCase()}-${(
    params.country || countryIso2
  ).toLowerCase()}`;

  if (props.target === '_blank') {
    allowedLinkProps.rel = 'noopener';
  }

  if (!isEmpty(props.pageType)) {
    allowedLinkProps.to = generateCustomLink(props.pageType, params);
  } else if (!isEmpty(props.documentType) && !isEmpty(props.slug)) {
    allowedLinkProps.to = generatePrismicDocumentTypePath(props.documentType, props.slug);
  } else if (to && !isUrlExternal(to)) {
    allowedLinkProps.to = {
      pathname: generatePath(to, params),
    };
    if (search) {
      allowedLinkProps.to.search = search;
    }
    if (prevSearch) {
      allowedLinkProps.to.state = {
        prevSearch,
      };
    }
  } else {
    allowedLinkProps.href = to;
    allowedLinkProps.hrefLang = 'x-default';
    ComponentAnchor = ExternalAnchor;
  }

  return (
    <ComponentAnchor className={className} {...allowedLinkProps}>
      {children}
    </ComponentAnchor>
  );
};
Anchor.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
  to: PropTypes.string,
  params: PropTypes.object,
  search: PropTypes.string,
  prevSearch: PropTypes.string,
  documentType: PropTypes.oneOf([
    'NEWS',
    'CASE_STUDY',
    'TESTIMONIAL',
    'LEGAL_INFORMATION',
    'PROFILE',
    'PROGRAMME',
    'TEST_TYPE_FAMILY',
    'FAQ_CATEGORY',
    'FAQ_CONTENT',
    'BLOG_PAGE',
    'HOMEPAGE',
    'BASIC_CONTENT',
    'LANDING_PAGE',
    'PREPARATION_TOOL_VERSION',
    'CUSTOM_LINK',
    'PRACTICE_TEST',
  ]),
  slug: PropTypes.string,
  target: PropTypes.string,
};

export default Anchor;
