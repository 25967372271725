import { gql } from '@apollo/client';

export const PURCHASE_LINE_FRAGMENT = gql`
  fragment PurchaseLineFragment on PurchaseLine {
    id
    isDiscount
    isVoucher
    quantity
    articleName
    articleFamily
    discountName
    discountCode
    voucherCode
    price {
      value
      currency
      tax
      valueTaxFree
    }
  }
`;

export const PURCHASE_TAX_FRAGMENT = gql`
  fragment PurchaseTaxFragment on PurchaseTax {
    amount
    taxRate
    transTax
  }
`;

export const PURCHASES = gql`
  query PURCHASES {
    purchases {
      id
      registrationId
      date
      status
      displayStatus
      invoice {
        id
        identifier
        availableLocales
      }
      status
      total
      totalWithTaxes
      canEditScoreAddress
    }
  }
`;

export const PURCHASE_DETAILS = gql`
  query PURCHASE_DETAILS($purchaseId: ID, $lang: String!, $country: String!) {
    purchase(purchaseId: $purchaseId) {
      id
      total
      totalWithTaxes
      requiresDeliveryAddress
      purchaseLines {
        ...PurchaseLineFragment
      }
      purchaseTaxes {
        ...PurchaseTaxFragment
      }
      creditNotes {
        id
        identifier
        availableLocales
        currency
        date
        totalWithTaxes
      }
      openSession {
        id
        scheduledAt
        startAt
        endAt
        duration
        timeZone
        requiresDeliveryAddress
        testTypeFamily {
          id
          name
          color
          shortDescription(country: $country, lang: $lang)
        }
        testType {
          id
          program
          format
        }
        testingSite {
          name
          wheelchairAccess
          address {
            streetLine1
            streetLine2
            city
            postalCode
          }
        }
        businessName
      }
    }
  }
  ${PURCHASE_LINE_FRAGMENT}
  ${PURCHASE_TAX_FRAGMENT}
`;
