import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@ets-global/b2c-website-ui';

import ErrorMessage from '../../../../components/Base/ErrorMessage/ErrorMessage';

import './styles.scss';

const FieldWrapper = ({
  id,
  className,
  label,
  icon,
  required,
  children,
  errorMessage,
  instructions,
  hasValue = false,
  showError = true,
  disabled = false,
  selectFull = false,
}) => {
  const childElement = React.cloneElement(children, {
    ...children.props,
    ref: useRef(),
  });

  return (
    <div
      className={classNames('field-wrapper', className, {
        'field-wrapper--focus': false,
        'field-wrapper--has-value': hasValue,
        'field-wrapper--select-full': selectFull,
      })}
    >
      <div className={'field-wrapper__wrapper'}>
        {childElement}
        {icon && (
          <button onClick={() => childElement.ref?.current.focus()} type={'button'} className={'field-wrapper__icon'}>
            <Icon name={icon} />
          </button>
        )}
      </div>
      {label && (
        <label
          htmlFor={id}
          className={classNames('field-wrapper__label', {
            'label-required': required,
            'field-wrapper__label--disabled': disabled,
          })}
        >
          {label}
        </label>
      )}
      {showError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {instructions && <span className="field__instructions">{instructions}</span>}
    </div>
  );
};

FieldWrapper.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.node,
  icon: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  instructions: PropTypes.string,
  hasValue: PropTypes.bool,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  selectFull: PropTypes.bool,
};

export default FieldWrapper;
