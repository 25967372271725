import React from 'react';
import classNames from 'classnames';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import Loader from '@ets-global/b2c-website-ui/Loader';
import Anchor from '@ets-global/b2c-website-ui/Anchor';
import { DOCUMENT_TYPE } from '../../../constants';

import './styles.scss';

const Button = ({ className, type = 'button', disabled = false, loading = false, children, ...props }) => {
  const classes = classNames(className, 'button', {
    'button--disabled': disabled,
    'button--loading': loading,
  });

  if (props.to || props.documentType || props.slug || props.pageType) {
    return (
      <Anchor className={classes} disabled={disabled} {...props}>
        {loading ? <Loader /> : children}
      </Anchor>
    );
  }

  let allowedButtonProps = pick(props, ['style', 'aria-label', 'data-cypress', 'onClick']);

  return (
    <button className={classes} disabled={disabled || loading} type={type} {...allowedButtonProps}>
      <span className={'button__inner'}>{loading ? <Loader /> : children}</span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
  to: PropTypes.string,
  params: PropTypes.object,
  search: PropTypes.string,
  prevSearch: PropTypes.string,
  documentType: PropTypes.oneOf(Object.values(DOCUMENT_TYPE)),
  slug: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  loading: false,
};

export default Button;
