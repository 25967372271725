import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import { Tooltip } from '../../../../components/Base';

import './styles.scss';
import ErrorMessage from '../../../../components/Base/ErrorMessage/ErrorMessage';

const RadioField = ({ value, label, icon, tooltipContent, className, color, ...props }) => {
  const allowedRadioFieldProps = pick(props, ['id', 'name', 'onChange', 'disabled', 'onBlur', 'onFocus']);

  return (
    <div
      className={classNames(
        'radio',
        {
          'radio--with-tooltip': icon,
          'radio--disabled': props.disabled,
        },
        className,
      )}
    >
      <label
        htmlFor={props.id}
        className={classNames('radio__label', `radio__label--${color}`, { 'radio__label--error': props.hasError })}
      >
        <input
          type="radio"
          className={classNames('radio__element', {
            'radio__element--disabled': props.disabled,
          })}
          value={props.id}
          checked={value === props.id}
          {...allowedRadioFieldProps}
        />
        <span />
        <span className="radio__label-text">{label}</span>
        {icon && tooltipContent && (
          <Tooltip position={'top-left'} icon={icon} width={'400px'}>
            <p>{tooltipContent}</p>
          </Tooltip>
        )}
      </label>
      {props.hasError && props.showError && props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </div>
  );
};

RadioField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  icon: PropTypes.string,
  tooltipContent: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
};

RadioField.defaultProps = {
  onChange: () => {},
};

export default RadioField;
