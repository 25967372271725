import './styles.scss';

import classNames from 'classnames';
import pick from 'lodash/pick';
import React, { InputHTMLAttributes } from 'react';

interface TextFieldOptions {
  /**
   * The text field is in error
   */
  hasError?: boolean;
  /**
   * The text field is dotted
   */
  dotted?: boolean;
  /**
   * The text field is rounded
   */
  rounded?: boolean;
}

export interface TextFieldProps extends TextFieldOptions, InputHTMLAttributes<HTMLInputElement> {}

export const allowedHtmlInputProps: (keyof InputHTMLAttributes<HTMLInputElement>)[] = [
  'id',
  'name',
  'value',
  'defaultValue',
  'className',
  'type',
  'min',
  'max',
  'disabled',
  'placeholder',
  'readOnly',
  'autoComplete',
  'onChange',
  'onFocus',
  'onBlur',
  'onKeyDown',
];

export const allowedTextFieldProps: (keyof TextFieldProps)[] = [
  'hasError',
  'dotted',
  'rounded',
  ...allowedHtmlInputProps,
];

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, hasError, dotted = true, rounded = false, ...props }, ref) => {
    const allowedProps = pick(props, allowedHtmlInputProps);
    return (
      <input
        {...allowedProps}
        className={classNames(
          'input',
          { 'input--dotted': dotted && !rounded },
          { 'input--rounded': rounded },
          { 'input--error': hasError },
          { 'input--read-only': props.readOnly },
          { 'input--disabled': props.disabled },
          { 'has-value': props.defaultValue || props.value !== '' },
          className
        )}
        ref={ref}
      />
    );
  }
);
