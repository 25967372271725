export const generateScoreForPassword = (password) => {
  const specialChars = [
    ',',
    ';',
    ':',
    '!',
    '?',
    '.',
    '/',
    '§',
    '!',
    'ù',
    '%',
    'µ',
    '*',
    '£',
    '$',
    '^',
    '=',
    '+',
    '}',
    '°',
    ')',
    ']',
    'à',
    '@',
    'ç',
    '_',
    'è',
    '`',
    '-',
    '|',
    '(',
    '[',
    "'",
    '{',
    '"',
    '#',
    '&',
    '~',
  ];

  let passwordLength = password.length;
  let score = passwordLength;

  for (let i = 0; i < passwordLength; i++) {
    let nbOfSameLetters = testSameLetters(password, i);

    if (!isNaN(parseInt(password[i]))) {
      score += i * 1.5;
      continue;
    }

    if (specialChars.includes(password[i])) {
      score += i * 2;
      continue;
    }

    if (password[i].toUpperCase() === password[i]) {
      score += (passwordLength - (i - 1)) * 1.5;
      continue;
    }

    if (nbOfSameLetters > 1) {
      score += (passwordLength - (i - 1)) * 1.1;
      continue;
    }
    score += (passwordLength - (i - 1)) * 1.2;
  }

  if (score > 100) {
    return 100;
  }

  if (score < 0) {
    return 0;
  }

  return Math.floor(score);
};

function testSameLetters(password, i) {
  return password.split(password[i]).length - 1;
}
