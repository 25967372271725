import { gql } from '@apollo/client';

export const ADD_PURCHASE_LINE = gql`
  mutation ADD_PURCHASE_LINE(
    $registrationId: String
    $purchaseLine: PrepToolInput!
    $country: String!
    $referrer: String
  ) {
    addPurchaseLine: opsPurchaseline(registrationId: $registrationId) {
      purchaseLineAdded: add(purchaseLine: $purchaseLine, country: $country, referrer: $referrer) {
        ... on PurchaseLineAdded {
          registrationId
          purchaseLineId
        }
        ... on PurchaseCannotBeCreated {
          reason
        }
      }
    }
  }
`;
