import { gql } from '@apollo/client';

import { Link } from '../fragments';

export const LEGAL_INFORMATIONS = gql`
  query LEGAL_INFORMATIONS($lang: String!, $country: String!) {
    legalInformations(lang: $lang) {
      slug
      title
      content(country: $country, lang: $lang)
      redirectLink {
        ...Link
      }
    }
  }
  ${Link}
`;
