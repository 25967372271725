import './styles.scss';

import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';

import { useNumberByLocale } from '../../../hooks/useNumberByLocale';

export interface PriceProps {
  price: number;
  currency: string;
  discountedPrice?: number;
  orientation?: ['horizontal', 'vertical'];
  className?: string;
  isInsteadOfDisplayed: boolean;
  withIncludedTax?: boolean;
}

export const Price: React.FC<PriceProps> = ({
  price,
  currency,
  discountedPrice = null,
  orientation = 'horizontal',
  isInsteadOfDisplayed = false,
  className = null,
}) => {
  const numberByLocale = useNumberByLocale();
  return (
    <div className={classNames('price', `price--${orientation}`, className)}>
      <span className="price__displayed">
        {numberByLocale({
          currency,
          value: Number.isFinite(discountedPrice) ? discountedPrice : price,
        })}
      </span>

      {Number.isFinite(discountedPrice) && (
        <div>
          {isInsteadOfDisplayed && (
            <span className="price__prefix">
              <Trans>price.prefix.instead-of</Trans>
            </span>
          )}
          <span className="price__crossed">{numberByLocale({ currency, value: price })}</span>
        </div>
      )}
    </div>
  );
};
