import { createHttpLink } from '@apollo/client';

import endpointUri from '../../helpers/gateway';
import { isBrowser } from '../../helpers/ssr';

const httpLink = createHttpLink({
  uri: ({ operationName }) => (isBrowser && window.Cypress ? `${endpointUri}?${operationName}` : endpointUri),
  fetch: fetch,
  credentials: 'include',
});

export default httpLink;
