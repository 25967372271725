import { gql } from '@apollo/client';

import { Image, Link, Meta } from '../fragments';
import { ECBlock } from '../fragments/ecBlock';

export const PRACTICE_TEST = gql`
  query PRACTICE_TEST($lang: String!, $country: String!, $slug: String!) {
    practiceTest(lang: $lang, country: $country, slug: $slug) {
      id
      slug
      testingSlug
      name
      testTypeFamilyColor
      baseline
      titleDescription
      description(country: $country, lang: $lang)
      backgroundImage {
        ...Image
      }
      boxTitle
      boxDescription(country: $country, lang: $lang)
      buttonLabel
      blocks {
        ...ECBlock
      }
      links {
        ...Link
      }
      documents {
        ...Link
      }
      meta {
        ...Meta
      }
    }
  }
  ${Image}
  ${Link}
  ${ECBlock}
  ${Meta}
`;
