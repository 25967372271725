import { useCookies } from 'react-cookie';

import { AUTH_COOKIE_DOMAIN, COOKIE_PROMO_CODE_OR_REGISTRATION_ID_MAX_AGE } from '../constants';
import { decodeBase64, encodeToBase64 } from '../helpers/base64';

const useBasketCookie = () => {
  let [cookies, setCookie, removeCookie] = useCookies(['registrationId', 'promoCodeOrVoucher']);

  const setRegistrationId = (registrationId) => {
    setCookie('registrationId', registrationId, {
      path: '/',
      domain: AUTH_COOKIE_DOMAIN,
      maxAge: COOKIE_PROMO_CODE_OR_REGISTRATION_ID_MAX_AGE,
    });
  };

  const setPromoCodeOrVoucher = (infoPromoCodeOrVoucher) => {
    setCookie('promoCodeOrVoucher', encodeToBase64(JSON.stringify(infoPromoCodeOrVoucher)), {
      path: '/',
      domain: AUTH_COOKIE_DOMAIN,
      maxAge: COOKIE_PROMO_CODE_OR_REGISTRATION_ID_MAX_AGE,
    });
  };

  const purchaseChange = (purchase) => {
    if (!purchase) {
      if (cookies.registrationId) {
        resetBasket();
      }

      return false;
    }

    if (!cookies.registrationId || cookies.registrationId !== purchase.registrationId) {
      setRegistrationId(purchase.registrationId);
    }

    return true;
  };

  const resetBasket = () => {
    removeRegistrationId();
    removePromoCodeOrVoucher();
  };

  const getBasket = () => {
    return cookies.registrationId || null;
  };

  const getPromoCodeOrVoucher = () => {
    if (cookies.promoCodeOrVoucher) {
      const base64Decoded = decodeBase64(cookies.promoCodeOrVoucher);

      return JSON.parse(base64Decoded);
    }

    return null;
  };

  const removePromoCodeOrVoucher = () => {
    if (!cookies.promoCodeOrVoucher) {
      return;
    }

    removeCookie('promoCodeOrVoucher', {
      domain: AUTH_COOKIE_DOMAIN,
      path: '/',
    });
  };

  const removeRegistrationId = () => {
    if (!cookies.registrationId) {
      return;
    }

    removeCookie('registrationId', { domain: AUTH_COOKIE_DOMAIN, path: '/' });
  };

  return {
    purchaseChange,
    resetBasket,
    getBasket,
    setRegistrationId,
    setPromoCodeOrVoucher,
    getPromoCodeOrVoucher,
    removeRegistrationId,
  };
};

export default useBasketCookie;
