import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const LocationTitle = ({ children, className }) => (
  <p className={classNames('location-title', className)}>{children}</p>
);

export default LocationTitle;
