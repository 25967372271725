import { dequal } from 'dequal';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ARTICLE_FAMILIES } from '../constants';
import { translateProductNameCallback } from '../helpers/i18n';
import { isBrowser } from '../helpers/ssr';
import { AppContext } from '../store/App';
import { AuthContext } from '../store/Auth';
import useBasketCookie from './useBasketCookie';
import useRouter from './useRouter';

const GTM_EVENTS = {
  DATA_LAYER_READY: 'dataLayerReady',
  ECOMMERCE: 'ecommerce',
  EVENT_GA: 'eventGA',
  FUNNEL_ETS_GLOBAL: 'funnelEtsGlobal',
  EVENT_VIRTUAL_PAGE: 'eventVirtualPage',
};

const GTM_EVENT_CATEGORIES = {
  FORM: 'form',
  NAVIGATION: 'navigation',
};

const clearItemInDataLayer = (itemDataLayer) =>
  Object.entries(itemDataLayer).reduce((item, [key, value]) => {
    if (key !== 'gtm.uniqueEventId') {
      item[key] = value;
    }

    return item;
  }, {});

const useDataLayer = () => {
  const { i18n, t } = useTranslation();
  const { user, isAuthenticated } = useContext(AuthContext);
  const { country } = useContext(AppContext);
  const translateProductName = translateProductNameCallback(i18n, t);
  const { currentRoute } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();

  const pushEvent = (eventDataLayer = {}) => {
    if (isBrowser && typeof window.dataLayer !== 'undefined') {
      const currentEventDataLayer = clearItemInDataLayer(eventDataLayer);
      const eventsDataLayer = window.dataLayer.filter((item) => Object.values(GTM_EVENTS).includes(item.event));
      const previousEventDataLayer = eventsDataLayer.length > 0 ? clearItemInDataLayer(eventsDataLayer.pop()) : null;
      if (
        currentEventDataLayer.event === GTM_EVENTS.DATA_LAYER_READY ||
        !dequal(previousEventDataLayer, currentEventDataLayer)
      ) {
        window.dataLayer.push(currentEventDataLayer);
      }
    }
  };

  const pushEventLoadedPage = (currentRoute, extraData = {}) => {
    const currentEventDataLayer = {
      event: GTM_EVENTS.DATA_LAYER_READY,
      userStatus: isAuthenticated ? (user?.hasPurchases ? 'purchaser' : 'loggedInUser') : 'anonymous',
      templatePage: currentRoute.templatePage || 'other pages',
      ...extraData,
      regionCountry: `${country.regionName} - ${country.name}`,
    };
    pushEvent(currentEventDataLayer);
  };

  const pushEventFunnelEtsGlobal = ({ name, type, stepName, stepNumber }) => {
    return pushEvent({
      event: GTM_EVENTS.FUNNEL_ETS_GLOBAL,
      funnelType: type,
      funnelName: name,
      funnelStepName: stepName,
      funnelStepNumber: stepNumber,
    });
  };

  const pushEventLoadedPlacementTestFunnel = ({ stepName, stepNumber }) =>
    pushEventFunnelEtsGlobal({
      name: 'placement test funnel',
      stepName,
      stepNumber,
    });

  const pushEventLoadedAccountCreationFunnel = ({ stepName, stepNumber }) =>
    pushEventFunnelEtsGlobal({
      name: 'account creation funnel',
      stepName,
      stepNumber,
    });

  const pushEventLoadedCartPage = ({ eventCommand, purchase }) => {
    if (purchase.get('purchaseLines').size === 0) {
      return;
    }

    const promoCodeOrVoucher = getPromoCodeOrVoucher();
    const voucherOrDiscountPurchaseLines = purchase
      .get('purchaseLines')
      .filter((purchaseLine) => purchaseLine.get('isVoucher') || purchaseLine.get('isDiscount'));
    const shippingFees = purchase
      .get('purchaseLines')
      .find((purchaseLine) => purchaseLine.get('articleFamily') === ARTICLE_FAMILIES.SHIPPING_FEES);

    let orderCoupons;
    let orderAffiliations;
    if (voucherOrDiscountPurchaseLines.size > 0) {
      orderCoupons = voucherOrDiscountPurchaseLines
        .map((purchaseLine) =>
          purchaseLine.get('isDiscount') ? purchaseLine.get('discountCode') : purchaseLine.get('voucherCode')
        )
        .join(', ');
      orderAffiliations = voucherOrDiscountPurchaseLines
        .map((purchaseLine) =>
          purchaseLine.get('isDiscount')
            ? promoCodeOrVoucher?.discountCode
              ? 'registration button'
              : 'promoCode'
            : 'voucher'
        )
        .join(', ');
    }

    pushEvent({
      event: GTM_EVENTS.ECOMMERCE,
      eventCommand,
      orderId: purchase.get('id'),
      orderCurrencyCode: purchase.get('purchaseLines').first().get('price').get('currency'),
      orderRevenue: purchase.get('total'),
      orderTax: purchase.get('purchaseTaxes').reduce((total, purchaseTax) => {
        total += purchaseTax.get('amount');

        return total;
      }, 0),
      orderShipping: shippingFees ? shippingFees.get('price').get('valueTaxFree') : undefined,
      orderCoupons,
      orderAffiliations,
      products: purchase
        .get('purchaseLines')
        .filter((purchaseLine) => purchaseLine.get('articleFamily') !== ARTICLE_FAMILIES.SHIPPING_FEES)
        .map((purchaseLine) => {
          let productCategory;
          switch (purchaseLine.get('articleFamily')) {
            case ARTICLE_FAMILIES.TEST_TYPE:
              productCategory = 'Test';
              break;
            case ARTICLE_FAMILIES.BOOK:
              productCategory = 'Prep tools/Books';
              break;
            case ARTICLE_FAMILIES.ONLINE:
              productCategory = 'Prep tools/Online tools';
              break;
            default:
              productCategory = purchaseLine.get('price').get('valueTaxFree') > 0 ? 'Options' : 'Discount';
              break;
          }

          return {
            productId: purchaseLine.get('articleStock').get('id'),
            productName: translateProductName(purchaseLine.get('articleName')),
            productBrand: 'ETS Global',
            productCategory,
            productPrice: purchaseLine.get('price').get('valueTaxFree'),
            productQuantity: purchaseLine.get('quantity'),
          };
        })
        .toJS(),
    });
  };

  const pushEventGA = ({ eventCategory, eventAction, eventLabel, ...extraData }) =>
    pushEvent({
      event: GTM_EVENTS.EVENT_GA,
      eventCategory,
      eventAction,
      eventLabel,
      ...extraData,
    });

  const pushEventConfirmSubscriptionNewsletter = (isB2B = false) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.FORM,
      eventAction: 'newsletter',
      eventLabel: isB2B ? 'b2b' : 'b2c',
    });

  const pushEventLoginOrRegister = (isRegister = false) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.FORM,
      eventAction: 'login',
      eventLabel: isRegister ? 'new account' : 'existing account',
    });

  const pushEventSubmitContactForm = (isB2B = false) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.FORM,
      eventAction: 'contact',
      eventLabel: isB2B ? 'b2b' : 'b2c',
    });

  const pushEventPictureAdded = (pageUrl) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.FORM,
      eventAction: 'picture added',
      eventLabel: pageUrl,
    });

  /**
   * @todo
   */
  const pushEventFormError = (errorMessage) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.FORM,
      eventAction: 'error',
      eventLabel: errorMessage,
    });

  const pushEventClickOnB2CBanner = (bannerSlug) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.NAVIGATION,
      eventAction: 'click',
      eventLabel: 'b2cBanner',
      bannerSlug,
    });

  /**
   * @param {string} pdfType (candidate signed admission form | candidate admission form | refund invoice | invoice | candidate handbook | Test LP)
   * @param {string} pdfName (used for pdfType = 'Test LP')
   */
  const pushEventPdfDownloaded = (pdfType, pdfName = '') =>
    pushEvent({
      event: GTM_EVENTS.EVENT_GA,
      eventCategory: GTM_EVENT_CATEGORIES.NAVIGATION,
      eventAction: 'click',
      eventLabel: 'pdf download',
      pdfType:
        typeof pdfType === 'string' ? pdfType : `additional document - ${currentRoute.templatePage || 'other pages'}`,
      pdfName,
    });

  const pushEventShowInfoPrepTools = ({ productId, productName }) =>
    pushEvent({
      event: GTM_EVENTS.EVENT_VIRTUAL_PAGE,
      eventCommand: 'infoPrepTools',
      productId,
      productName,
    });

  const pushEventLoadedPurchaseFunnel = ({ type, stepName, stepNumber }) =>
    pushEventFunnelEtsGlobal({
      name: 'purchase funnel',
      type,
      stepName,
      stepNumber,
    });

  const pushEventLoadedB2BContactFunnel = ({ stepName, stepNumber }) =>
    pushEventFunnelEtsGlobal({
      name: 'b2b contact funnel',
      stepName,
      stepNumber,
    });

  const pushEventViewMyDigitalScoreReport = () =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.NAVIGATION,
      eventAction: 'click',
      eventLabel: 'viewDigitalScoreReport',
    });

  const pushEventOpenSharingBoxDigitalScoreReport = () =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.NAVIGATION,
      eventAction: 'click',
      eventLabel: 'openSharingBox',
    });

  const pushEventShareDigitalScoreReport = () =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.FORM,
      eventAction: 'pushEventShareDigitalScoreReport',
      eventLabel: undefined,
    });

  const pushEventFaq = (eventLabel) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.NAVIGATION,
      eventAction: 'faq',
      eventLabel: `${eventLabel.substring(0, 30)}...`,
    });

  const pushEventGAClickNavigation = ({ eventLabel, ...extraData }) =>
    pushEventGA({
      eventCategory: GTM_EVENT_CATEGORIES.NAVIGATION,
      eventAction: 'click',
      eventLabel,
      ...extraData,
    });

  return {
    pushEventLoadedPage,
    pushEventLoadedPlacementTestFunnel,
    pushEventLoadedAccountCreationFunnel,
    pushEventLoadedCartPage,
    pushEventConfirmSubscriptionNewsletter,
    pushEventLoginOrRegister,
    pushEventSubmitContactForm,
    pushEventPictureAdded,
    pushEventFormError,
    pushEventClickOnB2CBanner,
    pushEventPdfDownloaded,
    pushEventShowInfoPrepTools,
    pushEventLoadedPurchaseFunnel,
    pushEventLoadedB2BContactFunnel,
    pushEventViewMyDigitalScoreReport,
    pushEventOpenSharingBoxDigitalScoreReport,
    pushEventShareDigitalScoreReport,
    pushEventFaq,
    pushEventGAClickNavigation,
  };
};

export default useDataLayer;
