import geolocation from './geolocation';
import notification from './notification';

export default {
  Query: {
    ...geolocation.Query,
  },
  Mutation: {
    ...notification.Mutation,
  },
};
