import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router';

const ChangeStatusCode = ({ children, status }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext && status) {
        staticContext.status = status;
      }

      return children;
    }}
  />
);

ChangeStatusCode.propTypes = {
  status: PropTypes.number.isRequired,
};

export default ChangeStatusCode;
