import { gql } from '@apollo/client';

export const MY_PRACTICE_TEST_SESSIONS = gql`
  query MY_PRACTICE_TEST_SESSIONS {
    user {
      id
      practiceTestSessionIds
      marketingInfo {
        candidateType
      }
    }
  }
`;
