import React from 'react';
import classNames from 'classnames';
import PropTypes, { string } from 'prop-types';

import { Title } from '../../../Element';

import './styles.scss';

const Subtitle = ({ component: C = Title, className, lvl = 2, modifier = '', children }) => {
  const props = {
    className: classNames(
      { subtitle: true, 'subtitle--blue': modifier === 'blue', 'subtitle--white': modifier === 'white' },
      className,
    ),
  };
  if (typeof C !== 'string') {
    props['lvl'] = lvl;
  }

  return <C {...props}>{children}</C>;
};

Subtitle.propTypes = {
  lvl: PropTypes.number,
  modifier: string,
  className: string,
};

export default Subtitle;
