import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import { Icon } from '@ets-global/b2c-website-ui';

import './styles.scss';

Modal.setAppElement('body');

const BaseModal = ({ title, children, footer, onClose = () => {}, inlineMode, isOpen = false, className }) => {
  return (
    <Modal
      onRequestClose={onClose}
      isOpen={isOpen}
      portalClassName={'modal'}
      className={classNames('modal__container', { inline: inlineMode }, className)}
      overlayClassName={'modal__overlay'}
    >
      <header className="modal__header">
        <p className="modal__title">{title}</p>
        {!inlineMode && (
          <button className="modal__close" aria-label="Close modal" onClick={onClose}>
            <Icon name={'plus-light'} />
          </button>
        )}
      </header>
      <main className="modal__content">{children}</main>
      {footer && <div className="modal__footer">{footer}</div>}
    </Modal>
  );
};

export default BaseModal;
