import { gql } from '@apollo/client';

import { Image, Link } from '../fragments';

export const B2C_BANNER = gql`
  query B2C_BANNER($lang: String!, $page: String, $documentId: String, $country: String!) {
    b2cBanner(lang: $lang, page: $page, documentId: $documentId, country: $country) {
      slug
      firstLine
      firstLineColor
      secondLine
      secondLineColor
      link {
        ...Link
      }
      crossColor
      longBannerBackground {
        ...Image
      }
      shortBannerBackground {
        ...Image
      }
    }
  }
  ${Image}
  ${Link}
`;
