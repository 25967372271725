import { gql } from '@apollo/client';

import { Image, Link, Meta } from '../fragments';

export const HOME = gql`
  query HOME($lang: String!, $country: String!) {
    home(lang: $lang, country: $country) {
      id
      slides {
        title
        marketingHook
        images {
          ...Image
        }
        link {
          ...Link
        }
        testTypeFamily(lang: $lang, country: $country) {
          id
          name
          testTypes {
            id
            isEproctored
          }
        }
      }
      personTypes {
        name
        purposes {
          name
          link {
            ...Link
          }
        }
      }
      programmeMarketingHook
      programmes {
        slug
        miniTitle
        miniDescription
        color
      }
      otherProgrammeInfos {
        title
        subtitle
        link {
          ...Link
        }
      }
      profiles {
        slug
        title
        description
        marketingHook
        images: backgroundImage {
          ...Image
        }
        testimonials {
          quote: title
          author: subtitle
          images {
            ...Image
          }
          smallImage {
            ...Image
          }
        }
      }
      etsInfoBlock {
        title
        description
        link {
          ...Link
        }
        infoBlocks {
          title
          description
        }
      }
      meta {
        ...Meta
      }
      displayNewsletterBlock
    }
  }
  ${Image}
  ${Link}
  ${Meta}
`;
