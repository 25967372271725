import './PasswordField.scss';

import classNames from 'classnames';
import pick from 'lodash/pick';
import React, { useState } from 'react';

import { generateScoreForPassword } from '../../../../helpers/getPasswordScore';
import { hasValidLength } from '../../../../helpers/Validators/PasswordValidator';
import Icon from '../../Icon';
import { allowedTextFieldProps, TextField, TextFieldProps } from '../Text';

interface PasswordFieldOptions {
  passwordHideLabel: string;
  passwordShowLabel: string;
  withStrength?: boolean;
}

export interface PasswordFieldProps extends PasswordFieldOptions, TextFieldProps {}

export const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ passwordHideLabel, passwordShowLabel, withStrength = false, ...props }, ref) => {
    const allowedProps = pick(props, allowedTextFieldProps);
    const [showPassword, setShowPassword] = useState(false);
    const [strength, setStrength] = useState<{
      score: null | number;
      value: string;
      changed: boolean;
    }>({
      score: null,
      value: '',
      changed: false,
    });

    const passwordStrength = (e): void => {
      const eventTargetValue = e.target.value;
      if (!eventTargetValue || eventTargetValue === '') {
        setStrength({
          score: null,
          value: eventTargetValue,
          changed: true,
        });

        if (props.onChange) {
          props.onChange(e);
        }
        return;
      }

      if (!passwordMeetsRequiredCriteria(eventTargetValue)) {
        setStrength({
          score: 0,
          value: eventTargetValue,
          changed: true,
        });

        if (props.onChange) {
          props.onChange(e);
        }
        return;
      }

      setStrength({
        score: generateScoreForPassword(eventTargetValue),
        value: eventTargetValue,
        changed: true,
      });

      if (props.onChange) {
        props.onChange(e);
      }
    };

    const passwordMeetsRequiredCriteria = (passwordValue): boolean => hasValidLength(passwordValue);

    return (
      <>
        <TextField {...allowedProps} type={showPassword ? 'text' : 'password'} onChange={passwordStrength} ref={ref} />
        <button
          type="button"
          className={classNames('field-wrapper__switcher', {
            'field-wrapper__switcher--hide': showPassword,
            'field-wrapper__switcher--show': !showPassword,
          })}
          onClick={(): void => setShowPassword(!showPassword)}
          aria-label={showPassword ? passwordHideLabel : passwordShowLabel}
        >
          <Icon name={showPassword ? 'eye-closed' : 'eye'} />
        </button>
        {withStrength && (
          <span
            className={classNames('password__strength', {
              'password__strength--type--bad': strength.score === 0,
              'password__strength--type--small': strength.score < 25.0 && strength.score > 0,
              'password__strength--type--low': strength.score > 25.0,
              'password__strength--type--medium': strength.score > 50.0,
              'password__strength--type--good': strength.score > 75.0,
              'password__strength--type--strong': strength.score === 100.0,
            })}
          />
        )}
      </>
    );
  }
);
