import { gql } from '@apollo/client';

import { AlternateLanguage, Image, ItsMaintenanceWindow, Link } from '../fragments';
import { ECBlock } from '../fragments/ecBlock';

export const TEST_LANDING_PAGE = gql`
  query TEST_LANDING_PAGE($lang: String!, $country: String!, $token: String, $isHomeTest: Boolean = true) {
    testLandingPage(lang: $lang, token: $token, isHomeTest: $isHomeTest) {
      backgroundImage {
        ...Image
      }
      baseline(country: $country, lang: $lang)
      sessionIsStarted
      sessionIsFinished
      testDuration
      candidateName
      homeSessionId
      alternateLanguages {
        ...AlternateLanguage
      }
      needHelp {
        title
        icon {
          ...Image
        }
        link {
          ...Link
        }
        tawkId
      }
      blocks {
        ...ECBlock
      }
      redirectLink {
        ...Link
      }
      nextItsMaintenanceWindow {
        ...ItsMaintenanceWindow
      }
      testTypeOrHomeTestName
    }
  }
  ${AlternateLanguage}
  ${Image}
  ${Link}
  ${ECBlock}
  ${ItsMaintenanceWindow}
`;
