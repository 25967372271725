import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classNames from 'classnames';

import { Icon } from '@ets-global/b2c-website-ui';

import { addressToString } from '../../../helpers/Formatter/StringFormatter';
import LocationTitle from '../Title/LocationTitle';

import './styles.scss';

const Address = ({ addressName, wheelchairAccess = false, address, className, hasPin = false }) => {
  return (
    <div className={classNames('address', className)}>
      <LocationTitle className={'address__label'}>
        {addressName} {wheelchairAccess && <Icon className={'address__icon'} name={'disabled'} />}
      </LocationTitle>
      <p className={'address__address'}>
        {hasPin && <Icon className={'address__icon'} name={'pin'} />}
        {addressToString(address)}
      </p>
    </div>
  );
};

Address.propTypes = {
  addressName: PropTypes.string.isRequired,
  wheelchairAccess: PropTypes.bool,
  address: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string,
  hasPin: PropTypes.bool,
};

export default Address;
