import kebabCase from 'lodash/kebabCase';
import set from 'lodash/set';
import unset from 'lodash/unset';
import qs from 'qs';
import { generatePath } from 'react-router';

import { DOCUMENT_TYPE, FILTER_PREPARATION_TOOL, PAGE_TYPE, PRISMIC_CUSTOM_TYPE } from '../constants';
import { paths } from '../paths';
import { env } from './env';
import BadRequestError from './Errors/BadRequestError';
import BrokenLinkError from './Errors/BrokenLinkError';

const containsEncodedComponents = (str) => decodeURI(str) !== decodeURIComponent(str);

export const getParams = (search) => {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    decoder: (str, defaultEncoder, charset, type) => {
      if (type === 'key') {
        return defaultEncoder(str);
      }

      if (!Number.isNaN(Number(str)) && typeof str === 'string' && str.trim() !== '') {
        return Number(str);
      }

      if (str !== null && (str.toLowerCase() === 'true' || str.toLowerCase() === 'false')) {
        return str.toLowerCase() === 'true';
      }

      try {
        const strDecoded = decodeURIComponent(str.replace(/\+/g, ' '));

        if (/<(|\/|[^/>][^>]+|\/[^>][^>]+)>/.test(strDecoded)) {
          throw new Error(`Attempted attack xss: ${strDecoded}`);
        }

        return strDecoded;
      } catch (error) {
        throw new BadRequestError(`Invalid URL: ${error.message}`);
      }
    },
  });
};

export const setParams = (queryObject, sort = (a, b) => a.localeCompare(b)) => {
  return qs.stringify(queryObject, {
    encodeValuesOnly: true,
    strictNullHandling: true,
    arrayFormat: 'brackets',
    sort,
  });
};

export const setParam = ({ search, key, value }, sort) => {
  search = getParams(search);
  if (value === null) {
    unset(search, key);
  } else {
    set(search, key, value);
  }

  return setParams(search, sort);
};

export const setParamsSearch = (params) => {
  if (params.testTypeIds) {
    params.testTypeIds = params.testTypeIds.sort();
  }

  return setParams(params, (a, b) => {
    if (b === 'testTypeIds') {
      return 1;
    }

    if (a === 'testTypeIds') {
      return -1;
    }

    if (!Number.isNaN(Number(a)) && !Number.isNaN(Number(b))) {
      return a - b;
    }

    return a.localeCompare(b);
  });
};

// generate an array with all public routes
export const generateAllRoutes = (allPaths, routes) => {
  for (let route of routes) {
    const routePath = route.path;

    if (routePath && !allPaths.find((path) => path.path === routePath)) {
      const item = {
        path: routePath,
        pathName: Object.keys(paths).find((key) => paths[key] === routePath),
      };

      allPaths.push(item);
    }

    if (route.routes) {
      generateAllRoutes(allPaths, route.routes);
    }
  }

  return allPaths;
};

export const generateLocalizedPath = (options) => {
  let pathname = options.pathname;
  const params = {
    country: options.country,
    locale: options.lang,
    ...(options.params || {}),
  };

  if (options.search) {
    if (!containsEncodedComponents(options.search)) {
      options.search = qs.stringify(
        qs.parse(options.search, {
          ignoreQueryPrefix: true,
        }),
        {
          strictNullHandling: true,
          arrayFormat: 'brackets',
          encodeValuesOnly: true,
        }
      );
    }
  }

  try {
    let path = generatePath(pathname, params);
    if (options.search) {
      path += options.search.charAt(0) !== '?' ? `?${options.search}` : options.search;
    }

    return path;
  } catch (e) {
    throw new BadRequestError(`Invalid URL (${options.pathname} with params ${JSON.stringify(options)})`);
  }
};

export const generateUrl = ({ country, lang, pathname, params = {}, search }) =>
  env('RAZZLE_B2C_WEBSITE_URL') + generateLocalizedPath({ country, lang, pathname, params, search });

export const generateCustomLink = ({ country, lang, pageType, params = {}, absolute = false }) => {
  let pathname = null;
  let search = null;

  switch (pageType) {
    case PAGE_TYPE.CONTACT_US_INDIVIDUAL:
      pathname = paths.CONTACT;
      break;
    case PAGE_TYPE.CONTACT_US_PROFESSIONAL:
      pathname = paths.CONTACT_ORGANISATION;
      break;
    case PAGE_TYPE.SESSION_SEARCH:
      if (params.testTypeFamily && params.testTypeFamily.testTypes) {
        search = setParamsSearch({
          testTypeIds: params.testTypeFamily.testTypes.map((testType) => testType.id),
          showMap: false,
        });
      }

      pathname = paths.SESSION_SEARCH;
      break;
    case PAGE_TYPE.SESSION_REGISTER_CANDIDATE:
      pathname = paths.SESSION_REGISTER;
      break;
    case PAGE_TYPE.PREPARATION_TOOLS:
      search = {};
      if (params.testTypeFamily) {
        search.testTypeFamilyName = params.testTypeFamily.name;
      }

      if (params.filterPreparationTool === FILTER_PREPARATION_TOOL.ONLINE) {
        search = {
          ...search,
          online: true,
          book: false,
        };
      }

      if (params.filterPreparationTool === FILTER_PREPARATION_TOOL.BOOK) {
        search = {
          ...search,
          book: true,
          online: false,
        };
      }

      pathname = paths.PREP_TOOLS;
      search = setParams(search);
      break;
    default:
      throw new Error('unsupported page type');
  }

  return (absolute ? generateUrl : generateLocalizedPath)({
    country,
    lang,
    pathname,
    params,
    search,
  });
};

export const generatePrismicDocumentTypePath = ({
  country,
  lang,
  documentType,
  slug,
  customLinkParams,
  absolute = false,
}) => {
  let pathname = '';
  let params = {
    slug,
  };

  switch (documentType) {
    case DOCUMENT_TYPE.NEWS:
    case DOCUMENT_TYPE.CASE_STUDY:
    case DOCUMENT_TYPE.TESTIMONIAL:
      pathname = paths.BLOG_ARTICLE;
      params.editorialContentType = kebabCase(documentType);
      break;
    case DOCUMENT_TYPE.LEGAL_INFORMATION:
      pathname = paths.LEGAL_INFORMATION;
      break;
    case DOCUMENT_TYPE.FAQ_CATEGORY:
      pathname = paths.HELP_CENTER;
      break;
    case DOCUMENT_TYPE.PROFILE:
      pathname = paths.PROFILE;
      break;
    case DOCUMENT_TYPE.PROGRAMME:
    case PRISMIC_CUSTOM_TYPE.PRODUCT_PROGRAMME_FAMILY:
      pathname = paths.PROGRAMME;
      break;
    case DOCUMENT_TYPE.TEST_TYPE_FAMILY:
      pathname = paths.TEST_TYPE_FAMILY;
      break;
    case DOCUMENT_TYPE.BASIC_CONTENT:
      pathname = paths.BASIC_CONTENT;
      params.editorialContentType = 'content';
      break;
    case DOCUMENT_TYPE.LANDING_PAGE:
    case PRISMIC_CUSTOM_TYPE.LANDING:
      pathname = paths.LANDING_PAGE;
      params.editorialContentType = 'landing-page';
      break;
    case DOCUMENT_TYPE.BLOG_PAGE:
      pathname = paths.BLOG;
      break;
    case DOCUMENT_TYPE.HOMEPAGE:
      pathname = paths.HOME;
      break;
    case DOCUMENT_TYPE.PREPARATION_TOOL_VERSION:
      pathname = paths.PREP_TOOL;
      break;
    case DOCUMENT_TYPE.PRACTICE_TEST:
      pathname = paths.PRACTICE_TEST;
      break;
    case DOCUMENT_TYPE.LANDING_PAGE_PARTNER:
      pathname = paths.LANDING_PAGE_PARTNER;
      params.editorialContentType = 'landing-page-partner';
      break;
    case DOCUMENT_TYPE.CUSTOM_LINK:
      return generateCustomLink({
        country,
        lang,
        pageType: customLinkParams.pageType,
        params: customLinkParams,
        absolute,
      });
    case DOCUMENT_TYPE.BROKEN_LINK:
      throw new BrokenLinkError(`unsupported document type ${documentType} for slug ${slug}`);
    default:
      throw new Error(`unsupported document type ${documentType} for slug ${slug}`);
  }

  return (absolute ? generateUrl : generateLocalizedPath)({
    country,
    lang,
    pathname,
    params,
  });
};
