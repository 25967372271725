import { parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js';
import metadata from 'libphonenumber-js/metadata.min.json';

const usePhoneNumber = () => {
  const countryCodes = metadata.country_calling_codes;

  const extractPhoneNumber = (phoneNumber) => {
    try {
      const phone = parsePhoneNumberFromString(phoneNumber);

      return { countryCode: phone.countryCallingCode, nationalNumber: phone.nationalNumber };
    } catch (e) {
      return { countryCode: null, nationalNumber: phoneNumber };
    }
  };

  const getCountryCode = (country) => {
    try {
      return getCountryCallingCode(country.toUpperCase());
    } catch (e) {
      return 1;
    }
  };

  const isValidNumber = (phoneNumber) => {
    try {
      const phone = parsePhoneNumberFromString(phoneNumber);
      if (!phone) {
        return false;
      }

      return phone.isValid();
    } catch (e) {
      return false;
    }
  };

  return { countryCodes, extractPhoneNumber, getCountryCode, isValidNumber };
};

export default usePhoneNumber;
