import { isSsr } from '../helpers/ssr';
import { env } from './env';

// Use Gateway's internal domain name when available (only from server-side)
const endpointUri =
  isSsr && env('RAZZLE_GATEWAY_INTERNAL_ENDPOINT_URI') !== undefined
    ? env('RAZZLE_GATEWAY_INTERNAL_ENDPOINT_URI')
    : env('RAZZLE_GATEWAY_ENDPOINT_URI');

export default endpointUri;
