import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';

import { AutocompleteItem } from '../AutocompleteField';

export interface OptionLabelProps {
  /**
   * The autocomplete item of option label
   */
  item: AutocompleteItem;
  /**
   * The input value of option label
   */
  inputValue: string;
}

export const AutocompleteOptionLabel: React.FC<OptionLabelProps> = ({ item, inputValue }) => {
  const matches = match(item.label, inputValue);
  const parts = parse(item.label, matches);

  return parts.map((part, index) => (
    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
      {part.text}
    </span>
  ));
};
