import './AutocompleteOption.scss';

import classNames from 'classnames';
import React, { LiHTMLAttributes } from 'react';

interface AutocompleteOptionOptions {
  /**
   * The label of autocomplete item
   */
  label: React.ReactNode;
  /**
   * The autocomplete item is highlighted
   */
  highlighted?: boolean;
}

export interface AutocompleteOptionProps extends AutocompleteOptionOptions, LiHTMLAttributes<HTMLLIElement> {}

export const AutocompleteOption: React.FC<AutocompleteOptionProps> = ({ label, highlighted, ...props }) => (
  <li
    className={classNames('autocomplete-item', {
      'autocomplete-item--highlighted': highlighted,
    })}
    {...props}
  >
    {label}
  </li>
);
