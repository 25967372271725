import { gql } from '@apollo/client';

export const OPTION_OF_PRACTICE_TEST = gql`
  query OPTION_OF_PRACTICE_TEST($isFrenchAndDomTomRegion: Boolean, $candidateType: CandidateTypeOfPracticeTest!) {
    options: optionsOfPracticeTest(isFrenchAndDomTomRegion: $isFrenchAndDomTomRegion, candidateType: $candidateType) {
      classLevels
      schoolTypes
      degreeLevels
      activityStatus
      reasons
    }
  }
`;
