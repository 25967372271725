import React from 'react';

import classNames from 'classnames';
import { string, any } from 'prop-types';

import './style.scss';

const Tag = ({ className, children }) => {
  return <span className={classNames('tag', className)}>{children}</span>;
};

Tag.propTypes = {
  children: any.isRequired,
  className: string,
};

export default Tag;
