import { gql } from '@apollo/client';

export const ADDRESSES_LIST = gql`
  query ADDRESSES_LIST {
    addresses {
      id
      name
      civility {
        id
        name
      }
      firstname
      lastname
      email
      company
      streetLine1
      streetLine2
      postalCode
      city
      state
      country {
        name
        iso2
      }
      phoneNumber
      isDefaultShippingAddress
      socialSecurityNumber
    }
  }
`;
