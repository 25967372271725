import { gql } from '@apollo/client';

export const VOUCHER_FRAGMENT = gql`
  fragment Voucher on Voucher {
    isCpf
    productsAndServices {
      id
      quantity
    }
    testTypeIds
    prepToolIds
  }
`;

export const VOUCHER = gql`
  query VOUCHER($code: String!, $candidate: CandidateInput!) {
    voucher(code: $code, candidate: $candidate) {
      ...Voucher
      code
      countryIso2
      shouldNotifyCandidateForScoring
      isCpfRequired
      candidate {
        lastname
        firstname
        email
        externalId
        cpfRef
        cefrLevel
      }
    }
  }
  ${VOUCHER_FRAGMENT}
`;
