import React from 'react';
import ModalContext from './ModalContext';

const ModalRoot = () => (
  <ModalContext.Consumer>
    {({ component: Component, props, hideModal }) =>
      Component ? <Component {...props} onRequestClose={hideModal} /> : null
    }
  </ModalContext.Consumer>
);

export default ModalRoot;
