import { gql } from '@apollo/client';

const OPEN_SESSION_FRAGMENT = gql`
  fragment OpenSessionFragment on OpenSession {
    id
    scheduledAt
    timeZone
    businessName
    testTypeFamily {
      id
      name
      color
      shortDescription(country: $country, lang: $lang)
    }
    testType {
      id
      program
      format
      isPhotoRequired
      isDateOfBirthRequired
    }
    testingSite {
      name
      wheelchairAccess
      address {
        streetLine1
        streetLine2
        city
        postalCode
      }
    }
  }
`;

export const CANDIDATE_FRAGMENT = gql`
  fragment CandidateFragment on Candidate {
    identificationId
    token
    firstname
    lastname
    email
    dateOfBirth
    phoneNumber
    pictureUrl
    pictureId
    externalId
    cpfRef
    cefrLevel
    productsAndServices {
      id
      quantity
    }
  }
`;

export const PAST_APPOINTMENTS_SESSIONS = gql`
  query PAST_APPOINTMENTS_SESSIONS($filters: [Filter], $lang: String!, $country: String!) {
    appointmentsSessions(filters: $filters, incomingSession: false, lang: $lang) {
      openSession {
        ...OpenSessionFragment
      }
      candidates {
        ...CandidateFragment
        status
        appointmentId
        admissionForm
        isCancellableByClient
        productsAndServices {
          translationKey
        }
        scoring {
          totalScore
          scores {
            translationKey
            value
          }
          scoreStatusSubReasons
        }
        digitalDocumentKey
        documentType
        documentUrl
      }
    }
  }
  ${OPEN_SESSION_FRAGMENT}
  ${CANDIDATE_FRAGMENT}
`;

export const INCOMING_APPOINTMENTS_SESSIONS = gql`
  query INCOMING_APPOINTMENTS_SESSIONS($filters: [Filter], $lang: String!, $country: String!) {
    appointmentsSessions(filters: $filters, incomingSession: true, lang: $lang) {
      openSession {
        startAt
        endAt
        duration
        testType {
          id
          candidateHandbook {
            url
          }
          candidatePreparationChecklist {
            text
          }
          scoreReport {
            url
          }
        }
        testingSite {
          address {
            coordinates {
              latitude
              longitude
            }
          }
        }
        ...OpenSessionFragment
      }
      candidates {
        ...CandidateFragment
        admissionForm
        appointmentId
        isCancellableByClient
        productsAndServices {
          translationKey
        }
      }
    }
  }
  ${OPEN_SESSION_FRAGMENT}
  ${CANDIDATE_FRAGMENT}
`;
