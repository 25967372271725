import React from 'react';
import { useMutation } from '@apollo/client';

import { useQuery } from '../../hooks';
import { DELETE_NOTIFICATION } from '../../graphql/mutations';
import { GET_NOTIFICATIONS } from '../../graphql/queries';
import FlashMessage from './FlashMessage';

import './styles.scss';

const FlashMessages = () => {
  const {
    data: { notifications = [] },
  } = useQuery(GET_NOTIFICATIONS, { ssr: false });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);

  return (
    <div className={'flash-messages'}>
      {notifications &&
        notifications.map((notification) => (
          <FlashMessage
            key={notification.id}
            message={notification.content}
            onDismiss={() => deleteNotification({ variables: { id: notification.id } })}
            type={notification.type}
            ttl={10000}
          />
        ))}
    </div>
  );
};

export default FlashMessages;
