import { gql } from '@apollo/client';

import { EditorialContent, EditorialContentDetails, Meta } from '../fragments';
import { ECDiscountBlock } from '../fragments/ecBlock';

export const BLOG = gql`
  query BLOG($lang: String!, $country: String!) {
    editorialContentTypes: editorialContentExtendedTypes
    testTypeFamilies(lang: $lang, country: $country, checkByCountry: false) {
      id
      name
    }
    blogPage(lang: $lang) {
      title
      subtitle
      mainContent(lang: $lang, country: $country) {
        headerImage {
          ...Image
        }
        ...EditorialContent
      }
      meta {
        ...Meta
      }
      displayNewsletterBlock
    }
  }
  ${Meta}
  ${EditorialContent}
`;

export const EDITORIAL_CONTENT_LIST = gql`
  query EDITORIAL_CONTENT_LIST(
    $lang: String!
    $country: String!
    $editorialContentType: EditorialContentType
    $testTypeFamilyIds: [ID]
    $cursor: String
    $limit: Int
  ) {
    itemsWithCursor: editorialContentList(
      lang: $lang
      country: $country
      editorialContentType: $editorialContentType
      testTypeFamilyIds: $testTypeFamilyIds
      cursor: $cursor
      limit: $limit
    ) {
      hasNextPage
      cursor
      items: editorialContents {
        ...EditorialContent
        listImage {
          ...Image
        }
      }
    }
  }
  ${EditorialContent}
`;

export const NEWS = gql`
  query NEWS($lang: String!, $country: String!, $slug: String!) {
    editorialContent(lang: $lang, country: $country, slug: $slug, editorialContentType: NEWS) {
      ...EditorialContentDetails
    }
  }
  ${EditorialContentDetails}
`;

export const TESTIMONIAL = gql`
  query TESTIMONIAL($lang: String!, $country: String!, $slug: String!) {
    editorialContent(lang: $lang, country: $country, slug: $slug, editorialContentType: TESTIMONIAL) {
      ...EditorialContentDetails
    }
  }
  ${EditorialContentDetails}
`;

export const CASE_STUDY = gql`
  query CASE_STUDY($lang: String!, $country: String!, $slug: String!) {
    editorialContent(lang: $lang, country: $country, slug: $slug, editorialContentType: CASE_STUDY) {
      ...EditorialContentDetails
    }
  }
  ${EditorialContentDetails}
`;

export const BASIC_CONTENT = gql`
  query BASIC_CONTENT($lang: String!, $country: String!, $slug: String!) {
    editorialContent(lang: $lang, country: $country, slug: $slug, editorialContentType: BASIC_CONTENT) {
      ...EditorialContentDetails
    }
  }
  ${EditorialContentDetails}
`;

export const LANDING_PAGE = gql`
  query LANDING_PAGE($lang: String!, $country: String!, $slug: String!) {
    editorialContent(lang: $lang, country: $country, slug: $slug, editorialContentType: LANDING_PAGE) {
      ...EditorialContentDetails
      ... on LandingPage {
        testTypeFamilies {
          id
          testTypes(lang: $lang, country: $country) {
            id
            isEproctored
          }
        }
      }
    }
  }
  ${EditorialContentDetails}
`;

export const LANDING_PAGE_PARTNER = gql`
  query LANDING_PAGE_PARTNER($lang: String!, $country: String!, $slug: String!, $referrer: String) {
    editorialContent(
      lang: $lang
      country: $country
      slug: $slug
      editorialContentType: LANDING_PAGE_PARTNER
      referrer: $referrer
    ) {
      ...EditorialContentDetails
      ... on LandingPagePartner {
        titleSummary
        partnerLogo {
          ...Image
        }
      }
      blocks {
        ... on ECDiscountBlock {
          ...ECDiscountBlock
        }
      }
    }
  }
  ${ECDiscountBlock}
  ${EditorialContentDetails}
`;
