import React from 'react';
import classNames from 'classnames';

import './styles.scss';
import PropTypes from 'prop-types';

const Loader = ({ className }) => {
  return (
    <div className={classNames('loader', className)}>
      <div className="loader__dots" />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
