import { Loader } from '@ets-global/b2c-website-ui';
import React, { useMemo } from 'react';

import { COUNTRIES, GEOLOCATION } from '../../graphql/queries';
import { isSsr } from '../../helpers/ssr';
import { useRouter } from '../../hooks';
import useQuery from '../../hooks/useQuery';
import AppContext from './AppContext';

const AppProvider = ({ children }) => {
  const { countryEmoIso2, lang } = useRouter();

  const {
    data: { countries = [] },
  } = useQuery(COUNTRIES);

  const {
    data: { geolocation = {} },
  } = useQuery(GEOLOCATION, { variables: { lang }, skip: isSsr });

  const getCountry = () => {
    let country = {
      name: null,
      regionName: null,
      iso2: null,
      emoIso2: countryEmoIso2,
      locales: [],
      isInOutOfFootprintSubRegion: null,
    };
    if (countries.length > 1) {
      country = countries.find(({ emoIso2 }) => emoIso2 === countryEmoIso2.toUpperCase());

      if (!country || !country.locales) {
        throw new Error('No locale found for this country');
      }

      country = {
        ...country,
        regionName: country.region.name,
        locales: country.locales.map((locale) => ({
          ...locale,
          lang: locale.code.toLowerCase(),
        })),
      };
    }

    return country;
  };

  let coordinates = {
    latitude: 0,
    longitude: 0,
  };
  if (geolocation.coordinates) {
    let latitude = parseFloat(geolocation.coordinates.latitude);
    let longitude = parseFloat(geolocation.coordinates.longitude);
    if (latitude > 90) {
      latitude = 90;
    }
    if (latitude < -90) {
      latitude = -90;
    }
    if (longitude > 180) {
      longitude = 180;
    }
    if (longitude < -180) {
      longitude = -180;
    }

    coordinates = {
      latitude,
      longitude,
    };
  }

  return useMemo(() => {
    // SSR we don't want to render site without countries
    if (countries.length === 0) return <Loader />;

    return (
      <AppContext.Provider
        value={{
          country: getCountry(),
          countries,
          city: geolocation ? geolocation.city : '',
          region: geolocation ? geolocation.region : '',
          coordinates,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }, [countries, countryEmoIso2, geolocation.city]);
};

export default AppProvider;
