import { useQuery as useApolloQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { getParams } from '../helpers/router';
import useAuth from './useAuth';
import useNotification from './useNotification';

const useQuery = (query, options = {}) => {
  const { newNotification } = useNotification();
  const location = useLocation();
  const { t } = useTranslation();
  const { logout } = useAuth();

  const notifyError = (error) => {
    newNotification({
      content: t('common.error-server-message'),
      type: 'error',
    });

    // Remove wrong token on 401 response
    if (
      error &&
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.code &&
      error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
    ) {
      logout();
    }

    if (typeof options.onError === 'function') {
      options.onError(error);
    }
  };

  const { prismicRef } = getParams(location.search);
  let context = {};

  if (prismicRef) {
    context.headers = {
      'prismic-ref': prismicRef,
    };
  }

  const { data = {}, ...apolloResult } = useApolloQuery(query, {
    ...options,
    context,
    onError: notifyError,
  });

  return {
    data,
    ...apolloResult,
    loading: options.skip ? false : apolloResult.loading,
  };
};

export default useQuery;
