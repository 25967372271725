import { gql } from '@apollo/client';

import { Image, Link, Meta } from '../fragments';

const PROFILE_FRAGMENT = gql`
  fragment Profile on Profile {
    slug
    publicationDate
    marketingHook
    title
    baseline
    images {
      ...Image
    }
    meta {
      ...Meta
    }
    description(country: $country, lang: $lang)
    scenarios {
      title
      subtitle
      description(country: $country, lang: $lang)
      links {
        ...Link
      }
      editorialContentHighlighted {
        ... on Testimonial {
          quote: title
          author: subtitle
          images {
            ...Image
          }
          smallImage {
            ...Image
          }
        }
        ... on CaseStudy {
          slug
          title
          images {
            ...Image
          }
          smallImage {
            ...Image
          }
        }
      }
      testTypeFamilies(lang: $lang, country: $country) {
        slug
        id
        name
        shortDescription(country: $country, lang: $lang)
        informationBoxContent(country: $country, lang: $lang)
        testTypes {
          id
        }
      }
    }
    redirectLink {
      ...Link
    }
  }
  ${Image}
  ${Link}
  ${Meta}
`;

export const PROFILE = gql`
  query PROFILE($lang: String!, $slug: String!, $country: String!) {
    profile(lang: $lang, slug: $slug, country: $country) {
      ...Profile
    }
  }
  ${PROFILE_FRAGMENT}
`;
