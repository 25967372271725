import { ApolloClient, ApolloLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import { Cookies } from 'react-cookie';

import { isBrowser, isSsr } from '../helpers/ssr';
import { authLink, errorLink, httpLink, loggerLink, persistedQueryLink } from './link';
import { GET_NOTIFICATIONS } from './queries';
import resolvers from './resolvers';
import typeDefs from './typeDefs';

export const createApolloClient = (cookies = new Cookies(), traceHeader, possibleTypes) => {
  let cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      const lang = cookies.get('locale');
      switch (object.__typename) {
        case 'Purchase':
          return `${object.__typename}:${object.registrationId || object.id}`;
        case 'Image':
          // eslint-disable-next-line no-case-declarations
          const keys = [object.__typename];
          if (object.source) keys.push(object.source);
          keys.push(object.url);

          return keys.join(':');
        case 'TestTypeFamily':
        case 'TestType':
        case 'Home':
        case 'OpenSession':
          return `${object.__typename}:${lang}:${object.id}`;
        default:
          if (object.__typename && object.id === null) {
            // eslint-disable-next-line no-console
            console.warn(`[ApolloCache]: Missing cache key for '__typename:${object.__typename}'`, object);

            return;
          }

          return defaultDataIdFromObject(object);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          TestType: {
            merge(existing = [], incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
    possibleTypes: isBrowser ? window.__APOLLO_POSSIBLE_TYPES__ : possibleTypes,
    freezeResults: true,
  });

  if (isBrowser) {
    cache = cache.restore(window.__APOLLO_STATE__);
  }

  const client = new ApolloClient({
    ssrMode: isSsr,
    link: ApolloLink.from([persistedQueryLink, errorLink, authLink(cookies), loggerLink(traceHeader), httpLink]),
    cache,
    assumeImmutableResults: true,
    resolvers,
    typeDefs,
  });

  const initStore = () => {
    cache.writeQuery({
      query: GET_NOTIFICATIONS,
      data: {
        notifications: [],
      },
    });
  };

  initStore();
  client.onResetStore(initStore);

  return client;
};
