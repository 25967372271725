export const isUrlExternal = (url) => {
  const regexExternalUrl = /^(https?:\/\/|tel:|mailto:)/;

  return !!url.match(regexExternalUrl);
};

export const isPdfUrl = (url) => {
  const regex = /^(https?:\/\/)+.+(.pdf)$/;

  return !!url.match(regex);
};
