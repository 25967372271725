import { useEffect } from 'react';
import { useDataLayer, useRouter } from '../../hooks';

export const LoadedPage = ({ children }) => {
  const { currentRoute } = useRouter();
  const { pushEventLoadedPage } = useDataLayer();

  useEffect(() => {
    if (currentRoute.skipDefaultEventLoadedPage) {
      return;
    }

    pushEventLoadedPage(currentRoute);
  }, [currentRoute.pathname]);

  return children;
};
