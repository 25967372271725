import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import classNames from 'classnames';

import { Icon, Anchor, Loader } from '@ets-global/b2c-website-ui';

import { DOCUMENT_TYPE } from '../../../constants';

import './styles.scss';

const Link = ({ className, icon, children, loading = false, ...props }) => {
  const classes = classNames('link', className);

  if (
    props.to === undefined &&
    props.documentType === undefined &&
    props.slug === undefined &&
    props.pageType === undefined
  ) {
    const allowedButtonProps = pick(props, ['onClick', 'onFocus', 'onMouseOver', 'data-cypress']);

    return (
      <button className={classes} type={'button'} {...allowedButtonProps}>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {icon && <Icon name={icon} />}
            {children}
          </Fragment>
        )}
      </button>
    );
  }

  return (
    <Anchor className={classes} {...props}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {icon && <Icon name={icon} />}
          {children}
        </Fragment>
      )}
    </Anchor>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.any,
  icon: PropTypes.string,
  to: PropTypes.string,
  params: PropTypes.object,
  search: PropTypes.string,
  prevSearch: PropTypes.string,
  documentType: PropTypes.oneOf(Object.keys(DOCUMENT_TYPE)),
  slug: PropTypes.string,
  loading: PropTypes.bool,
  target: PropTypes.string,
};

export default Link;
