import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import FieldWrapper from '../FieldWrapper';
import usePhoneNumber from '../../../../hooks/usePhoneNumber';
import { useRouter } from '../../../../hooks';

import './style.scss';

const PhoneNumberField = ({ field, form, className, required, labels }) => {
  const { countryCodes, extractPhoneNumber, getCountryCode, isValidNumber } = usePhoneNumber();
  const { countryIso2 } = useRouter();

  const extract = (phoneNumber) => {
    if (typeof phoneNumber === 'undefined' || !phoneNumber) {
      return { countryCode: getCountryCode(countryIso2), nationalNumber: '' };
    }

    return extractPhoneNumber(phoneNumber);
  };

  const [phoneNumber, setPhoneNumber] = useState(extract(field.value));

  const setFieldValue = (nationalNumber, phoneNumber) => {
    if (nationalNumber === '' || nationalNumber === null) {
      form.setFieldValue(field.name, '');

      return;
    }

    form.setFieldValue(field.name, phoneNumber);
  };

  const onChangeCountryCode = (e) => {
    const value = e.target.value;
    const newPhoneNumber = `+${value}${phoneNumber.nationalNumber}`;

    setPhoneNumber({ countryCode: value, nationalNumber: phoneNumber.nationalNumber });

    setFieldValue(phoneNumber.nationalNumber, newPhoneNumber);
  };

  const onChangeNationalNumber = (e) => {
    const value = e.target.value;
    const newPhoneNumber = `+${phoneNumber.countryCode}${value}`;

    setPhoneNumber({ countryCode: phoneNumber.countryCode, nationalNumber: value });

    if (isValidNumber(newPhoneNumber)) {
      setFieldValue(value, newPhoneNumber);
    }
  };

  const onBlur = () => {
    let formattedPhone = `+${phoneNumber.countryCode}${phoneNumber.nationalNumber}`;
    if (isValidNumber(formattedPhone)) {
      const phone = extractPhoneNumber(formattedPhone);
      setPhoneNumber({ countryCode: phoneNumber.countryCode, nationalNumber: phone.nationalNumber });

      formattedPhone = `+${phoneNumber.countryCode}${phone.nationalNumber}`;
    }

    setFieldValue(phoneNumber.nationalNumber, formattedPhone);

    form.setFieldTouched(field.name, true);
  };

  return (
    <div className={classNames('candidate-infos__phone')}>
      <FieldWrapper
        id={`country-${field.name}`}
        label={labels[0]}
        required={required}
        showError={false}
        hasValue={!!phoneNumber.countryCode && phoneNumber.countryCode !== ''}
        errorMessage={form.errors[field.name]}
      >
        <div className={'select'}>
          <select
            className={'select__element'}
            value={phoneNumber.countryCode}
            name={`country-${field.name}`}
            onChange={onChangeCountryCode}
            onBlur={onBlur}
            autoComplete={'tel tel-country-code'}
          >
            {Object.keys(countryCodes).map((countryCode) => (
              <option key={countryCode} value={countryCode}>
                {`+${countryCode}`}
              </option>
            ))}
          </select>
        </div>
      </FieldWrapper>
      <FieldWrapper
        id={`phone-${field.name}`}
        label={labels[1]}
        required={required}
        hasValue={!!phoneNumber.nationalNumber && phoneNumber.nationalNumber !== ''}
        showError={form.touched[field.name] !== undefined && form.errors[field.name] !== undefined}
        errorMessage={form.errors[field.name]}
      >
        <input
          className={classNames(
            'input',
            'input--dotted',
            { 'input--error': form.touched[field.name] && form.errors[field.name] },
            { 'has-value': phoneNumber.nationalNumber !== '' },
            className,
          )}
          type="tel"
          name={`phone-${field.name}`}
          value={phoneNumber.nationalNumber}
          onChange={onChangeNationalNumber}
          onBlur={onBlur}
          autoComplete={'tel tel-national'}
        />
      </FieldWrapper>
    </div>
  );
};

PhoneNumberField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  labels: PropTypes.array.isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }),
};

export default PhoneNumberField;
