import { gql } from '@apollo/client';

export const GEOLOCATION = gql`
  query GEOLOCATION($lang: String) {
    geolocation(lang: $lang) @client {
      coordinates {
        latitude
        longitude
      }
      countryCode
      city
      region
    }
  }
`;
