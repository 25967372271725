import { gql } from '@apollo/client';

export const ADD_ADDRESS_BY_REGISTRATION_ID = gql`
  mutation ADD_ADDRESS_BY_REGISTRATION_ID(
    $registrationId: String!
    $address: AddressInput!
    $isBilling: Boolean = false
    $updateBoth: Boolean = false
  ) {
    result: addAddressByRegistrationId(
      registrationId: $registrationId
      address: $address
      isBilling: $isBilling
      updateBoth: $updateBoth
    ) {
      ... on AddAddressSuccessfullySaved {
        success
      }
      ... on AddAdressCannotBeSaved {
        errors
      }
    }
  }
`;
