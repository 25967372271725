import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { paths } from '../../paths';
import { useRouter } from '../../hooks';

const ScrollToTop = () => {
  const location = useLocation();
  const { currentRoute } = useRouter();
  const routeRef = useRef(currentRoute.path);

  useEffect(() => {
    // avoid for help center with selected question
    if (currentRoute.path === paths.HELP_CENTER && routeRef.current === paths.HELP_CENTER) {
      return;
    }
    routeRef.current = currentRoute.path;

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;
