import { gql } from '@apollo/client';

export const REGION_AND_CITY_AND_COORDINATE_BY_PLACE_ID = gql`
  query REGION_AND_CITY_AND_COORDINATE_BY_PLACE_ID($placeId: String!) {
    regionAndCityAndCoordinateByPlaceId(placeId: $placeId) @client {
      coordinates {
        latitude
        longitude
      }
      city
      region
    }
  }
`;
