import { gql } from '@apollo/client';

export const COUNTRIES = gql`
  query COUNTRIES(
    $lang: String = "en"
    $withRegion: Boolean = true
    $withLocales: Boolean = true
    $withEuVatPrefix: Boolean = false
  ) {
    countries(lang: $lang) {
      name
      frenchName
      englishName
      iso2
      emoIso2
      order
      isInOutOfFootprintSubRegion
      region @include(if: $withRegion) {
        name
        order
      }
      locales @include(if: $withLocales) {
        name
        code
        main
      }
      euVatPrefix @include(if: $withEuVatPrefix)
    }
  }
`;
