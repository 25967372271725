import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';

import { sha256 } from '../../helpers/sha256';

const persistedQueryLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
  sha256,
});

export default persistedQueryLink;
