import { isBrowser } from './ssr';

const env = (variable) => {
  if (isBrowser) {
    return window.env ? window.env[variable] : undefined;
  }

  return process.env[variable];
};

const allowedKeys = ['PUBLIC_PATH', 'CLIENT_PUBLIC_PATH', 'NODE_ENV'];

const getClientEnv = () => {
  const env = Object.keys(process.env)
    .filter((key) => key.startsWith('RAZZLE_') || allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = process.env[key];

      return obj;
    }, {});

  return env;
};

export { env, getClientEnv };
