import { gql } from '@apollo/client';

export const TESTING_SITES = gql`
  query TESTING_SITES($filters: TestingSiteFilterInput!, $country: String!) {
    testingSites(filter: $filters, country: $country) {
      id
      address {
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;
