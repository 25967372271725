import { GET_NOTIFICATIONS } from '../../graphql/queries';

let nextNotificationId = 0;

const resolvers = {
  Mutation: {
    newNotification: (_, { input }, { cache }) => {
      const previous = cache.readQuery({ query: GET_NOTIFICATIONS });
      const nextNotification = {
        id: nextNotificationId++,
        content: input.content,
        type: input.type,
        completed: false,
        __typename: 'Notification',
      };
      const data = {
        notifications: previous.notifications.concat([nextNotification]),
      };
      cache.writeQuery({ query: GET_NOTIFICATIONS, data });

      return nextNotification;
    },
    deleteNotification: (_, variables, { cache }) => {
      const previous = cache.readQuery({ query: GET_NOTIFICATIONS });
      const data = {
        notifications: previous.notifications.filter((notification) => {
          return notification.id !== variables.id;
        }),
      };
      cache.writeQuery({ query: GET_NOTIFICATIONS, data });

      return null;
    },
  },
};

export default resolvers;
