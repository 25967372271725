import { gql } from '@apollo/client';

export const REGION_CITY_BY_COORDINATES = gql`
  query REGION_CITY_BY_COORDINATES($lang: String!, $coordinates: CoordinatesInput!) {
    regionCity(lang: $lang, coordinates: $coordinates) @client {
      city
      region
    }
  }
`;
