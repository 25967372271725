import React from 'react';
import { List as ImmutableList } from 'immutable';
import PropTypes from 'prop-types';

const List = ({ items, listClassNames = '', itemClassNames = '' }) => (
  <ul className={listClassNames}>
    {items.map((item, key) => (
      <li key={key} className={itemClassNames}>
        {item}
      </li>
    ))}
  </ul>
);

List.propTypes = {
  items: PropTypes.oneOfType([PropTypes.instanceOf(ImmutableList), PropTypes.array]).isRequired,
  listClassNames: PropTypes.string,
  itemClassNames: PropTypes.string,
};

export default List;
