import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Title } from '../../../Element';

import './styles.scss';

const SmallTitle = ({ component: C = Title, lvl = 3, children, className }) => {
  const props = { className: classNames('small-title', className) };
  if (typeof C !== 'string') {
    props['lvl'] = lvl;
  }

  return <C {...props}>{children}</C>;
};

SmallTitle.propTypes = {
  lvl: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SmallTitle;
