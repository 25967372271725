import { ApolloLink } from '@apollo/client';

const loggerLink = (traceHeader) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-token-global': traceHeader.getTokenGlobal(),
        'x-token-process': traceHeader.getTokenProcess(),
      },
    }));

    return forward(operation);
  });

export default loggerLink;
