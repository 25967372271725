import './SelectField.scss';

import classNames from 'classnames';
import pick from 'lodash/pick';
import React, { SelectHTMLAttributes } from 'react';

interface SelectFieldOptions {
  /**
   * The options of select field
   */
  options: {
    label: string | number;
    value: string | number;
    disabled?: boolean;
  }[];
  /**
   * The select field is in error
   */
  hasError?: boolean;
  /**
   * The select field is full
   */
  selectFull?: boolean;
}

export interface SelectFieldProps extends SelectFieldOptions, SelectHTMLAttributes<HTMLSelectElement> {}

export const allowedHtmlSelectProps: (keyof SelectHTMLAttributes<HTMLSelectElement>)[] = [
  'id',
  'name',
  'value',
  'className',
  'disabled',
  'autoComplete',
  'onChange',
  'onFocus',
  'onBlur',
  'onKeyDown',
];

export const allowedSelectFieldProps: (keyof SelectFieldProps)[] = [
  'options',
  'hasError',
  'selectFull',
  ...allowedHtmlSelectProps,
];

export const SelectField: React.FC<SelectFieldProps> = ({ className, hasError, options, selectFull, ...props }) => {
  const allowedProps = pick(props, allowedHtmlSelectProps);
  return (
    <div
      className={classNames('select', className, {
        'select--error': hasError,
        'select--empty': options.length <= 0,
        'select--disabled': props.disabled,
        'select--full': selectFull,
      })}
    >
      <select {...allowedProps} className="select__element">
        {options.map((item, key) => (
          <option key={key} value={item.value} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};
