import { env } from '../../helpers/env';

const resolvers = {
  Query: {
    geolocation(_, { lang }) {
      return fetch(`${env('RAZZLE_GEOIP_URL')}/geolocation?lang=${lang}`)
        .then((res) => {
          if (res.ok) {
            return res;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((response) => response.json())
        .then((geolocation) => ({
          __typename: 'Geolocation',
          coordinates: {
            __typename: 'Coordinates',
            latitude: parseFloat(geolocation.coordinate.latitude),
            longitude: parseFloat(geolocation.coordinate.longitude),
          },
          countryCode: geolocation.country,
          city: geolocation.city,
          region: geolocation.region,
        }))
        .catch(() => ({
          __typename: 'Geolocation',
          coordinates: {
            __typename: 'Coordinates',
            latitude: 48.8745445,
            longitude: 2.3001027,
          },
          countryCode: 'FR',
          city: 'Paris',
          region: 'Île-de-France',
        }));
    },
    coordinates(_, { region, city }) {
      return fetch(`${env('RAZZLE_GEOIP_URL')}/coordinate/${region}/${city}`)
        .then((res) => {
          if (res.ok) {
            return res;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((response) => response.json())
        .then((geolocation) => ({
          __typename: 'Coordinates',
          latitude: geolocation.latitude ? parseFloat(geolocation.latitude) : 0,
          longitude: geolocation.longitude ? parseFloat(geolocation.longitude) : 0,
        }))
        .catch(() => ({
          __typename: 'Coordinates',
          latitude: 48.8745445,
          longitude: 2.3001027,
        }));
    },
    regionCity(_, { lang, coordinates }) {
      if (!coordinates?.latitude || !coordinates?.longitude) {
        return {
          __typename: 'Geolocation',
          city: 'Paris',
          region: 'Île-de-France',
        };
      }

      return fetch(`${env('RAZZLE_GEOIP_URL')}/region-city/${lang}/${coordinates.latitude},${coordinates.longitude}`)
        .then((res) => {
          if (res.ok) {
            return res;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((response) => response.json())
        .then((geolocation) => ({
          __typename: 'Geolocation',
          city: geolocation.city,
          region: geolocation.region,
        }))
        .catch(() => ({
          __typename: 'Geolocation',
          city: 'Paris',
          region: 'Île-de-France',
        }));
    },
    regionAndCityAndCoordinateByPlaceId(_, { placeId }) {
      return fetch(`${env('RAZZLE_GEOIP_URL')}/place-id/${placeId}`)
        .then((res) => {
          if (res.ok) {
            return res;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((response) => response.json())
        .then((geolocation) => ({
          __typename: 'Geolocation',
          coordinates: {
            __typename: 'Coordinates',
            latitude: parseFloat(geolocation.coordinate.latitude),
            longitude: parseFloat(geolocation.coordinate.longitude),
          },
          city: geolocation.city,
          region: geolocation.region,
        }))
        .catch(() => ({
          __typename: 'Geolocation',
          coordinates: {
            __typename: 'Coordinates',
            latitude: 48.8745445,
            longitude: 2.3001027,
          },
          city: 'Paris',
          region: 'Île-de-France',
        }));
    },
  },
};

export default resolvers;
