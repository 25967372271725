import { createContext } from 'react';

const PurchaseContext = createContext({
  basketIsEmpty: true,
  registrationId: null,
  purchase: null,
  loading: true,
  refetch: () => {},
  resetBasket: () => {},
});

export default PurchaseContext;
