import { gql } from '@apollo/client';

export const PARTNERS = gql`
  query PARTNERS($country: String!, $testTypeIds: [ID]) {
    partners(country: $country, testTypeIds: $testTypeIds) {
      name
      website
      phone
      address {
        streetLine1
        city
        postalCode
        country {
          name
        }
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;
