import './assets/styles/common.scss';
import './assets/styles/container.scss';

import { loadableReady } from '@loadable/component';
import React from 'react';
import { Cookies } from 'react-cookie';
import { hydrate } from 'react-dom';

import { RootBrowser } from './components/Root';
import { createApolloClient } from './graphql/client';
import checkVersion from './helpers/checkVersion';
import TraceHeader from './helpers/traceHeader';

const root = document.getElementById('root');

const traceHeader = TraceHeader.getInstance();
const apolloClient = createApolloClient(new Cookies(), traceHeader);

loadableReady(() => {
  hydrate(<RootBrowser client={apolloClient} />, root);
  checkVersion();
});

if (module.hot) {
  module.hot.accept();
}
