import { gql } from '@apollo/client';

import { User } from '../fragments';

export const USER = gql`
  query USER {
    user {
      ...User
    }
  }
  ${User}
`;
