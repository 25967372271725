import geolocation from './geolocation';
import notification from './notification';

const schema = `
    type Query {
        _empty: String!
    }
    type Mutation {
        _empty: String!
    }
`;

export default schema + notification + geolocation;
