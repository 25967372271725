import { gql } from '@apollo/client';

import { User } from './user';

export const Auth = gql`
  fragment Auth on Auth {
    token
    user {
      ...User
    }
  }
  ${User}
`;
