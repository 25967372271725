import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS {
    notifications @client {
      id
      content
      type
    }
  }
`;
