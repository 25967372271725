export const prefix = '/:country([a-z]{2})/:locale(fr|en|pl|dt)';

export const paths = {
  ERROR: `${prefix}/:statusCode(400|404|500)`,
  BAD_REQUEST: `${prefix}/400`,
  NOT_FOUND: `${prefix}/404`,
  INTERNAL_SERVER: `${prefix}/500`,
  HOME: `${prefix}`,
  FORGOTTEN_PASSWORD: `${prefix}/forgotten-password`,
  COUNTRY: `${prefix}/country`,
  RESET_PASSWORD: `${prefix}/reset-password/:token`,
  REGISTRATION: `${prefix}/user/registration`,
  ACCOUNT: `${prefix}/account`,
  ACCOUNT_INFORMATION: `${prefix}/account/information`,
  ACCOUNT_ADDRESSES: `${prefix}/account/addresses`,
  ACCOUNT_INCOMING_APPOINTMENTS_SESSIONS: `${prefix}/account/incoming-open-sessions`,
  ACCOUNT_PAST_APPOINTMENTS_SESSIONS: `${prefix}/account/passed-open-sessions`,
  ACCOUNT_PURCHASES: `${prefix}/account/orders`,
  LOGIN: `${prefix}/login`,
  LOGOUT: `${prefix}/logout`,
  UPLOAD_CANDIDATE_PICTURE: `${prefix}/upload-candidate-picture/:sessionId(\\d+)/:token`,
  SESSION: `${prefix}/session`,
  SESSION_SEARCH: `${prefix}/session/search/:region?/:city?`,
  SESSION_REGISTER_LAYOUT: `${prefix}/session/:sessionId(\\d+)/:registrationId?`,
  SESSION_REGISTER: `${prefix}/session/:sessionId(\\d+)/candidate`,
  SESSION_REGISTER_BY_REGISTRATION_ID: `${prefix}/session/:sessionId/:registrationId/candidate`,
  SESSION_CROSS_SELLING: `${prefix}/session/:sessionId(\\d+)/:registrationId/cross-selling`,
  PURCHASE_SUMMARY: `${prefix}/purchase/summary/:registrationId?`,
  PURCHASE_PAYMENT: `${prefix}/purchase/payment/:registrationId`,
  PURCHASE_PAYMENT_FINALISATION: `${prefix}/purchase/:type(success|fail)/:registrationId`,
  VOUCHER_USAGE: `${prefix}/voucher/:code?`,
  PARTNER_SEARCH: `${prefix}/partner/search`,
  BLOG: `${prefix}/blog`,
  BLOG_ARTICLE: `${prefix}/blog/:editorialContentType(news|case-study|testimonial)/:slug`,
  BASIC_CONTENT: `${prefix}/:editorialContentType(content)/:slug`,
  LANDING_PAGE: `${prefix}/:editorialContentType(landing-page)/:slug`,
  LANDING_PAGE_PARTNER: `${prefix}/:editorialContentType(landing-page-partner)/:slug`,
  LEGAL_INFORMATION: `${prefix}/legal-information/:slug?`,
  HELP_CENTER: `${prefix}/help-center/:slug?/:question?`,
  CONTACT: `${prefix}/contact/individual`,
  LANDING_CONTACT: `${prefix}/content/contact-ets-global`,
  CONTACT_ORGANISATION: `${prefix}/contact/organisation`,
  CONTACT_LEAD: `${prefix}/contact/organisation/:accountId/token/:token`,
  PROFILE: `${prefix}/profile/:slug`,
  PROGRAMME: `${prefix}/programme/:slug`,
  TEST_TYPE_FAMILY: `${prefix}/test-type-family/:slug`,
  PREP_TOOLS_LAYOUT: `${prefix}/preparation-tools/:registrationId?`,
  PREP_TOOLS: `${prefix}/preparation-tools`,
  PREP_TOOL: `${prefix}/preparation-tool/:slug`,
  PREP_TOOLS_BY_REGISTRATION_ID: `${prefix}/preparation-tools/:registrationId`,
  NEWSLETTER: `${prefix}/newsletter`,
  LANDING_PAGE_TOEIC_IN_AUTONOMY: `${prefix}/toeic-in-autonomy/:token`,
  LANDING_PAGE_REMOTE_PROCTORED_TEST: `${prefix}/remote-proctored-test/:token`,
  IMPERSONATE_USER: `${prefix}/impersonate-user`,
  PRACTICE_TEST: `${prefix}/practice-test/:slug`,
  PRACTICE_TEST_START: `${prefix}/practice-test/:slug/start`,
  PRACTICE_TEST_CONCLUSION_FORM: `${prefix}/practice-test/:slug/:sessionId/conclusion-form`,
  PRACTICE_TEST_RESULT_PAGE: `${prefix}/practice-test/:slug/:sessionId/result-page`,
  DIGITAL_SCORE_REPORT: `${prefix}/digital-score-report/:digitalDocumentKey`,

  // Styleguide pages
  STYLEGUIDE_MAINTENANCE: `${prefix}/styleguide/maintenance`,
  STYLEGUIDE_PARTNERS_PAGE: `${prefix}/styleguide/partners-page`,
  STYLEGUIDE_NFL_RESULTS: `${prefix}/styleguide/nfl-results`,
  STYLEGUIDE_UK_FORM: `${prefix}/styleguide/uk-form`,
};
