import { useContext } from 'react';

import {
  generateCustomLink,
  generateLocalizedPath,
  generatePrismicDocumentTypePath,
  generateUrl,
} from '../helpers/router';
import RouterContext from '../store/Router/RouterContext';

const useRouter = () => {
  const { country, lang, currentRoute, partnerDomain } = useContext(RouterContext);

  return {
    countryEmoIso2: country,
    countryIso2: country && country.toLowerCase() === 'fx' ? 'fr' : country,
    lang,
    currentRoute,
    partnerDomain,
    generatePath: (pathname, params = {}, search = null) =>
      generateLocalizedPath({ country, lang, pathname, params, search }),
    generatePrismicDocumentTypePath: (documentType, slug, absolute = false) =>
      generatePrismicDocumentTypePath({
        country,
        lang,
        documentType,
        slug,
        absolute,
      }),
    generatePrismicDocumentTypeOrCustomLinkPath: ({ documentType, slug, customLinkParams, absolute = false }) =>
      generatePrismicDocumentTypePath({
        country,
        lang,
        documentType,
        slug,
        customLinkParams,
        absolute,
      }),
    generateCustomLink: (pageType, params = {}) => generateCustomLink({ country, lang, pageType, params }),
    generateUrl: (pathname, params = {}, search = null) => generateUrl({ country, lang, pathname, params, search }),
  };
};

export default useRouter;
