import { gql } from '@apollo/client';

export const START_HOME_SESSION = gql`
  mutation START_HOME_SESSION($id: ID!) {
    startHomeSessionResult: startHomeSession(id: $id) {
      ... on StartHomeSessionSuccess {
        hasSucceeded
      }
      ... on StartHomeSessionError {
        reason
        hasSucceeded
      }
    }
  }
`;
