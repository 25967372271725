import { gql } from '@apollo/client';

import { Auth } from '../fragments';

export const LOGIN = gql`
  mutation LOGIN($username: String!, $password: String!, $recaptchaToken: String!, $rememberMe: Boolean) {
    authResult: login(
      username: $username
      password: $password
      recaptchaToken: $recaptchaToken
      rememberMe: $rememberMe
    ) {
      ... on Auth {
        ...Auth
      }
      ... on Error {
        reason
      }
    }
  }
  ${Auth}
`;

export const IMPERSONATE_USER = gql`
  mutation IMPERSONATE_USER($email: String!, $token: String!, $staffEmail: String!) {
    impersonateUser(email: $email, token: $token, staffEmail: $staffEmail) {
      ...Auth
    }
  }
  ${Auth}
`;
