import { useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import { ApolloError, OperationVariables } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import useNotification from './useNotification';

export const useLazyQuery = function <TData = unknown, TVariables = OperationVariables>(query, options) {
  const { newNotification } = useNotification();
  const { t } = useTranslation();
  const notifyError = (error: ApolloError): void => {
    newNotification({
      content: t('common.error-server-message'),
      type: 'error',
    });

    if (typeof options.onError === 'function') {
      options.onError(error);
    }
  };
  const [loadQuery, result] = useApolloLazyQuery<TData, TVariables>(query, {
    ...options,
    onError: notifyError,
    ssr: false,
  });

  return [loadQuery, { ...result, data: result.data || {} }];
} as typeof useApolloLazyQuery;
