import { gql } from '@apollo/client';

import { Image, Link, Meta } from './common';
import { ECBlock } from './ecBlock';

export const EditorialContent = gql`
  fragment EditorialContent on EditorialContentInterface {
    editorialContentType
    slug
    title
    subtitle
    date
    images {
      ...Image
    }
    links {
      ...Link
    }
    documents {
      ...Link
    }
    redirectLink {
      ...Link
    }
  }
  ${Image}
  ${Link}
`;

export const EditorialContentDetails = gql`
  fragment EditorialContentDetails on EditorialContentInterface {
    ...EditorialContent
    id
    summary(country: $country, lang: $lang)
    displayNewsletterBlock
    blocks {
      ...ECBlock
    }
    meta {
      ...Meta
    }
  }
  ${EditorialContent}
  ${ECBlock}
  ${Meta}
`;

export const Testimonial = gql`
  fragment Testimonial on Testimonial {
    slug
    quote: title
    author: subtitle
    date
    images {
      ...Image
    }
  }
  ${Image}
`;

export const News = gql`
  fragment News on News {
    slug
    title
    subtitle
    date
    images {
      ...Image
    }
  }
  ${Image}
`;
