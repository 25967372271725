import React from 'react';

import { UseNumberByLocale, useNumberByLocale } from '../../hooks/useNumberByLocale';

/**
 * @deprecated Use `useNumberByLocale` instead !
 */
export const NumberByLocale: React.FC<Parameters<UseNumberByLocale>[0]> = ({ currency, value, style = 'decimal' }) => {
  const numberByLocale = useNumberByLocale();
  return <>{numberByLocale({ value, currency, style })}</>;
};
