import { setContext } from '@apollo/client/link/context';

import { AUTH_TOKEN_KEY_B2C } from '../../constants';

const authLink = (cookies) =>
  setContext((_, { headers = {} }) => {
    const token = cookies.get(AUTH_TOKEN_KEY_B2C);

    /* @todo: We might no longer be able to send the token in the header and retrieve it from the cookies directly on the gateway */
    if (token) {
      headers['authorization'] = `token ${token}`;
    }

    return { headers };
  });

export default authLink;
