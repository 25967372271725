import { onError } from '@apollo/client/link/error';

import { AUTH_TOKEN_KEY_B2C } from '../../constants';
import { env } from '../../helpers/env';
import { isBrowser } from '../../helpers/ssr';

const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions.code === 'PERSISTED_QUERY_NOT_FOUND') {
        return;
      }

      if (env('RAZZLE_STAGE') !== 'dev' && extensions.code === 'GRAPHQL_VALIDATION_FAILED' && isBrowser) {
        window.location.reload();
      }

      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Query ${operation.query}, Error code: ${extensions.code}, Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (message === 'Unauthorized') {
        document.cookie = `${AUTH_TOKEN_KEY_B2C}=;expires=Thu, 01 Jan 1900 00:00:00 UTC;path=/;`;
      }
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

export default errorLink;
