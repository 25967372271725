import { gql } from '@apollo/client';

export const UPLOAD_CANDIDATE_PICTURE = gql`
  mutation UPLOAD_CANDIDATE_PICTURE($token: String, $sessionId: Int!, $file: String!) {
    candidatePictureResult: uploadCandidatePicture(token: $token, sessionId: $sessionId, file: $file) {
      ... on CandidatePicture {
        id
        uniqId
      }
      ... on CandidatePictureIsInvalid {
        errors {
          message
          reason
          parameters
        }
      }
    }
  }
`;
