import { gql } from '@apollo/client';

import { Image, Link, News, Testimonial } from '../fragments';

export const LAYOUT = gql`
  query LAYOUT($country: String!, $lang: String) {
    layout(country: $country, lang: $lang) {
      whoBlock {
        title
        testimonial {
          ...Testimonial
        }
        news {
          ...News
        }
        newsLink {
          ...Link
        }
        links {
          ...Link
        }
      }
      partnerBlock {
        title
        leftBlock {
          title
          description
          link {
            ...Link
          }
        }
        rightBlock {
          title
          link {
            ...Link
          }
          partners {
            images {
              ...Image
            }
          }
        }
      }
      youBlock {
        title
        testimonial {
          ...Testimonial
        }
        blocks {
          title
          link {
            ...Link
          }
          links {
            ...Link
          }
        }
      }
      testsBlock {
        title
        blocks {
          title
          description
          link {
            ...Link
          }
          links {
            ...Link
          }
        }
        news {
          ...News
        }
      }
      meetUsBlock {
        title
        addressTitle
        address
        phone
        contactLinkIsDisplayed
      }
    }
  }
  ${Testimonial}
  ${News}
  ${Link}
  ${Image}
`;
