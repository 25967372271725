import React from 'react';
import { useCookies } from 'react-cookie';

import { AUTH_TOKEN_KEY_B2C } from '../../constants';
import { USER } from '../../graphql/queries';
import useQuery from '../../hooks/useQuery';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
  const [cookies] = useCookies([AUTH_TOKEN_KEY_B2C]);
  const isAuthenticated = Boolean(cookies[AUTH_TOKEN_KEY_B2C]);
  const userQueryResult = useQuery(USER, { skip: !isAuthenticated });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userQueryResult.data?.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
