import React from 'react';
import { connect, getIn } from 'formik';

import Field from './Field';

const areEqual = (prevProps, nextProps) => {
  const { name } = prevProps;

  return (
    getIn(prevProps.formik.values, name) === getIn(nextProps.formik.values, name) &&
    getIn(prevProps.formik.errors, name) === getIn(nextProps.formik.errors, name) &&
    getIn(prevProps.formik.touched, name) === getIn(nextProps.formik.touched, name) &&
    Object.keys(prevProps).length === Object.keys(nextProps).length &&
    prevProps.formik.isSubmitting === nextProps.formik.isSubmitting &&
    prevProps.label === nextProps.label &&
    prevProps.text === nextProps.text &&
    (!prevProps.options || (prevProps.options && prevProps.options === nextProps.options))
  );
};

const FormikFastField = ({ component: FieldComponent, formik, ...props }) => (
  <Field FieldComponent={FieldComponent} formik={formik} {...props} />
);

export default connect(React.memo(FormikFastField, areEqual));
