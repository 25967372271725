import uniqid from 'uniqid';

class TraceHeader {
  static getInstance() {
    if (!TraceHeader.instance) {
      TraceHeader.instance = new TraceHeader();
    }

    return TraceHeader.instance;
  }

  setRequest(request) {
    this.tokenGlobal = request.headers['x-token-global'];
    this.tokenProcess = request.headers['x-token-process'];
  }

  getTokenGlobal() {
    if (!this.tokenGlobal) {
      this.tokenGlobal = this.generateToken('global');
    }

    return this.tokenGlobal;
  }

  getTokenProcess() {
    if (!this.tokenProcess) {
      this.tokenProcess = this.generateToken('process');
    }

    return this.tokenProcess;
  }

  updateTokenProcess() {
    this.tokenProcess = this.generateToken('process');

    return this;
  }

  generateToken(prefix) {
    return uniqid(`${prefix}_`);
  }
}

export default TraceHeader;
