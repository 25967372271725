import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import pl from 'date-fns/locale/pl';
import formatDateTz from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

let locales = { fr: fr, pl: pl, en: enUS };

export const dateToISOString = (date = new Date()) =>
  `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

export const formatDate = ({ date, format, language, timezone = 'UTC' }) => {
  if (!date) return;
  const formats = {
    'dddd LL': 'PPPP',
    L: 'P',
    LT: 'p',
    'DD/MM/YYYY': 'dd/MM/yyyy',
    'DD MMM': 'dd MMM',
    'MMMM YYYY': 'MMMM yyyy',
    PPPp: 'PPPp',
    Pp: 'Pp',
  };

  if (format && formats[format]) {
    format = formats[format];
  } else {
    format = "yyyy-MM-dd'T'HH:mm:ss.SSxxx";
  }

  if (date instanceof Date) {
    date = date.toISOString();
  }

  return formatDateTz(utcToZonedTime(date, timezone), format, {
    timeZone: timezone,
    locale: locales[language],
  });
};
