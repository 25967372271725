import { gql } from '@apollo/client';

import { Image, Meta } from '../fragments';

export const FAQ_CATEGORIES = gql`
  query FAQ_CATEGORIES($lang: String!, $country: String!) {
    faqCategories(lang: $lang, country: $country) {
      id
      slug
      name
      publicationDate
      meta {
        ...Meta
      }
      picto {
        ...Image
      }
      faqs {
        id
        slug
        question
        answer(country: $country, lang: $lang)
        targetAudience
        publicationDate
        meta {
          ...Meta
        }
      }
    }
  }
  ${Image}
  ${Meta}
`;
