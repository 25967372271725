import React, { useMemo } from 'react';

import { PURCHASE } from '../../graphql/queries';
import { useBasketCookie, useRouter } from '../../hooks';
import useQuery from '../../hooks/useQuery';
import PurchaseContext from './PurchaseContext';

const PurchaseProvider = ({ children }) => {
  const {
    currentRoute: { params },
  } = useRouter();
  const { getBasket, purchaseChange, resetBasket, removeRegistrationId } = useBasketCookie();
  let registrationId = params.registrationId;
  if (!registrationId) {
    registrationId = getBasket();
  }
  const {
    data: { purchase = null },
    loading,
    refetch,
  } = useQuery(PURCHASE, {
    variables: { registrationId },
    skip: registrationId === null,
    //component re-renders when a refetch
    notifyOnNetworkStatusChange: true,
    // no cache needed because purchase data in a context
    fetchPolicy: 'network-only',
  });

  let basketIsEmpty = true;
  if (registrationId) {
    if (!loading) {
      if (purchase && purchase.purchaseLines.length === 0) {
        resetBasket();
      } else {
        basketIsEmpty = !purchaseChange(purchase);
      }
    }
  }

  return useMemo(
    () => (
      <PurchaseContext.Provider
        value={{
          basketIsEmpty,
          registrationId,
          purchase,
          loading,
          refetch,
          resetBasket,
          removeRegistrationId,
        }}
      >
        {children}
      </PurchaseContext.Provider>
    ),
    [basketIsEmpty, registrationId, purchase, loading]
  );
};

export default PurchaseProvider;
