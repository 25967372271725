import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';

import { Title } from '../Base/Title';

import './styles.scss';

// Careful: this component is also used to render the 500 page on the server side,
// so it cannot use any routing dependency.
const Error = ({ code = 404 }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={t(`meta.title.error.${code}`)} />
      <main className={'main main--no-offset-top'}>
        <div className={'error-page container container--padding'}>
          <div className={'error-page__text'}>
            <Title>
              <Trans>{`error-page.${code}.title`}</Trans>
            </Title>
            <p>
              <Trans>{`error-page.${code}.content`}</Trans>
            </p>
            <a href={'/'} className={'button button--full'}>
              <Trans>error-page.back-home</Trans>
            </a>
          </div>
          <div className={`error-page__${code}`}>{code}</div>
        </div>
      </main>
    </Fragment>
  );
};

Error.propTypes = {
  code: PropTypes.number,
};

export default Error;
