import { gql } from '@apollo/client';

export const CIVILITIES = gql`
  query CIVILITIES {
    civilities {
      id
      name
    }
  }
`;
