import { gql } from '@apollo/client';

export const OPEN_SESSIONS_SEARCH = gql`
  query OPEN_SESSIONS_SEARCH(
    $filter: OpenSessionFilterInput!
    $country: String!
    $lang: String!
    $discount: DiscountInput
    $offset: Int
    $limit: Int
    $voucher: String
  ) {
    openSessionsSearch(
      filter: $filter
      country: $country
      lang: $lang
      discount: $discount
      offset: $offset
      limit: $limit
      voucher: $voucher
    ) {
      pageInfo {
        total
      }
      items {
        id
        lockedAt
        endAt
        scheduledAt
        timeZone
        testTypeFamily {
          id
          name
          color
        }
        testType {
          id
          program
          format
        }
        testingSite {
          name
          address {
            streetLine1
            streetLine2
            postalCode
            city
          }
          wheelchairAccess
        }
        price {
          value
          discountedPrice
          currency
        }
        seatsAvailable
        businessName
      }
    }
  }
`;
