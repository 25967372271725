import { createContext } from 'react';

const RouterContext = createContext({
  country: 'fr',
  lang: 'en',
  currentRoute: {
    params: {},
  },
  partnerDomain: null,
});

export default RouterContext;
