import React from 'react';
import classNames from 'classnames';

import './errorMessage.scss';

const ErrorMessage = ({ className, children }) => (
  <p className={classNames('error-message', className)} dangerouslySetInnerHTML={{ __html: children }} />
);

export default ErrorMessage;
