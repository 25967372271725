import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { isBrowser } from '../../helpers/ssr';
import i18nBackend from './i18nBackend';
import i18nConfig from './i18nConfig';

const i18n = i18next.use(i18nBackend);
if (isBrowser) {
  i18n.use(initReactI18next);
}

if (!i18n.isInitialized) {
  i18n.init(i18nConfig);
}

export default i18n;
