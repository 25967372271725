import { gql } from '@apollo/client';

export const Link = gql`
  fragment Link on Link {
    ... on LinkInternal {
      label
      pageType
      params {
        sessionId
        testTypeFamily {
          name
          testTypes(lang: $lang, country: $country) {
            id
          }
        }
        filterPreparationTool
      }
    }
    ... on PrismicLinkInternal {
      label
      documentType
      slug
    }
    ... on LinkExternal {
      label
      to: url
    }
  }
`;

export const Image = gql`
  fragment Image on Image {
    source
    id
    url
    dimensions {
      width
      height
    }
    alt
    copyright
  }
`;

export const AlternateLanguage = gql`
  fragment AlternateLanguage on AlternateLanguage {
    lang
    params {
      id
      name
      value
    }
  }
`;

export const Meta = gql`
  fragment Meta on Meta {
    title
    description
    socialMediaTitle
    socialMediaDescription
    image {
      ...Image
    }
    video {
      url
    }
  }
`;
