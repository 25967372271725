import { gql } from '@apollo/client';

export const ADDRESS_FORM = gql`
  query ADDRESS_FORM {
    civilities {
      id
      name
    }
    countries {
      name
      iso2
      emoIso2
    }
  }
`;
