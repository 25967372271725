import { gql } from '@apollo/client';

export const CONTACT_ORGANISATION = gql`
  mutation CONTACT_ORGANISATION($contact: ContactOrganisationCreateInput!) {
    createContactOrganisation(contact: $contact)
  }
`;

export const CONTACT_INDIVIDUAL = gql`
  mutation CONTACT_INDIVIDUAL($contact: ContactIndividualCreateInput!) {
    createContactIndividual(contact: $contact)
  }
`;

export const INFO_FORM_CONTACT_INDIVIDUAL = gql`
  query INFO_FORM_CONTACT_INDIVIDUAL {
    salesforce {
      subjectQuestions {
        label
        value
        subjectOptions {
          label
          value
        }
      }
    }
    civilities {
      id
      name
    }
  }
`;
