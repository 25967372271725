import 'intersection-observer';

import { useEffect, useState } from 'react';

import { isBrowser } from '../helpers/ssr';

const useIntersectionObserver = (ref, { threshold, root, rootMargin } = {}) => {
  // configure the state
  const [state, setState] = useState({
    inView: false,
    triggered: false,
    entry: undefined,
  });

  let observer = null;
  if (isBrowser) {
    observer = new IntersectionObserver(
      (entries, observerInstance) => {
        // checks to see if the element is intersecting
        if (entries[0].intersectionRatio <= 0) {
          return;
        }

        // unobserve the element
        observerInstance.disconnect();
        // if it is update the state, we set triggered as to not re-observe the element
        setState({
          inView: true,
          triggered: true,
          entry: observerInstance,
        });
      },
      {
        threshold: threshold || 0,
        root: root || null,
        rootMargin: rootMargin || '0%',
      }
    );
  }

  useEffect(() => {
    // check that the element exists, and has not already been triggered
    if (observer && ref.current && !state.triggered) {
      observer.observe(ref.current);
    }
  });

  return [state.inView, state.entry];
};

export default useIntersectionObserver;
