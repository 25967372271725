import { useCookies } from 'react-cookie';

import { COOKIE_MAX_AGE } from '../constants';
import { B2C_BANNER } from '../graphql/queries';
import useQuery from './useQuery';
import useRouter from './useRouter';

const useMarketingBanner = ({ page, documentId }) => {
  const { lang, countryIso2 } = useRouter();
  const {
    data: { b2cBanner },
    loading,
  } = useQuery(B2C_BANNER, {
    variables: { page, documentId, lang, country: countryIso2 },
  });
  const [cookies, setCookie] = useCookies();
  const removeBanner = () => {
    setCookie('banner_display', false, { path: '/', maxAge: COOKIE_MAX_AGE });
  };

  return {
    banner: b2cBanner,
    loading,
    isVisible: cookies['banner_display'] !== 'false',
    removeBanner,
  };
};

export default useMarketingBanner;
