import { gql } from '@apollo/client';

const COORDINATES = gql`
  query COORDINATES($city: String, $region: String) {
    coordinates(city: $city, region: $region) @client {
      latitude
      longitude
    }
  }
`;
export { COORDINATES };
