import React, { Component } from 'react';
import Error from '../../components/Error';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return <Error code={500} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
