import React from 'react';
import { getIn } from 'formik';

import FieldWrapper from '../FieldWrapper';
import { CheckboxField, RadioField } from '../index';

const Field = ({ FieldComponent, formik, className, ...props }) => {
  const { validate: _validate, validationSchema: _validationSchema, ...form } = formik;
  const field = {
    value: props.type === 'radio' || props.type === 'checkbox' ? props.value : getIn(formik.values, props.name),
    name: props.name,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  };

  const fieldComponent = (
    <FieldComponent
      {...form}
      {...field}
      {...props}
      hasError={form.touched[field.name] !== undefined && form.errors[field.name] !== undefined}
      errorMessage={form.errors[field.name]}
      onChange={(e) => {
        form.handleChange(e);
        if (props.onChange) {
          props.onChange(e);
        }
      }}
    />
  );

  if ([CheckboxField, RadioField].includes(FieldComponent)) {
    return fieldComponent;
  }

  return (
    <FieldWrapper
      {...props}
      className={className}
      hasValue={!!field.value && field.value !== ''}
      showError={form.touched[field.name] !== undefined && form.errors[field.name] !== undefined}
      errorMessage={form.errors[field.name]}
    >
      {fieldComponent}
    </FieldWrapper>
  );
};

export default Field;
