import React from 'react';

import { useBasketCookie, useRouter } from '../../hooks';
import { LocalizedRedirect } from '../../components/Routing';

function basketWithRegistrationIdReplacer(WrappedComponent, pathToReplace) {
  const withDisplayName = `basketWithRegistrationIdReplacer(${getDisplayName(WrappedComponent)})`;

  const BasketWithRegistrationIdReplacer = (props) => {
    const { getBasket } = useBasketCookie();
    const { generatePath, currentRoute } = useRouter();
    const registrationId = getBasket();

    if (registrationId && !currentRoute.params.registrationId) {
      return (
        <LocalizedRedirect
          to={{
            pathname: generatePath(pathToReplace, { registrationId, ...currentRoute.params }),
            search: currentRoute.search,
          }}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };

  BasketWithRegistrationIdReplacer.displayName = withDisplayName;

  return BasketWithRegistrationIdReplacer;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default basketWithRegistrationIdReplacer;
