export const capitalize = (str) => {
  if (typeof str !== 'string') {
    // eslint-disable-next-line no-console
    console.log(`this arg ${str} is not a string`);

    return str;
  }

  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const addressToString = (address, separator = ' - ', displayCountry) => {
  const streetLine = address.get('streetLine2')
    ? `${address.get('streetLine1')} ${address.get('streetLine2')}`
    : address.get('streetLine1');
  const country = displayCountry ? `${separator}${address.get('country').get('name')}` : '';

  return `${streetLine}${separator}${address.get('postalCode')}${separator}${address.get('city')}${country}`;
};

export const cropString = (string, maxLength) => {
  if (string.length <= maxLength) {
    return string;
  }

  return string.substring(0, maxLength) + '...';
};

export const replaceExponentSymbol = (string) => {
  const regex = /(?!<sup[\S\s]+>)(©|®|™)(?!<\/sup>)/g;
  if (typeof string === 'string') {
    return string.replace(regex, '<sup class="exponent-symbol">$1</sup>');
  }

  return string;
};

export const extractFilenameFromUrl = (string) => string.substring(string.lastIndexOf('/') + 1);
