import loadable from '@loadable/component';
import React from 'react';
import { renderRoutes } from 'react-router-config';

import { getParams } from './helpers/router';
import authenticated from './hoc/Authenticated';
import basketWithRegistrationIdReplacer from './hoc/BasketWithRegistrationIdReplacer';
import cantAccessLogged from './hoc/CantAccessLogged';
import { paths } from './paths';

const GoogleRecaptchaV3 = loadable(() => import('./containers/GoogleRecaptchaV3'));

const Layout = loadable(() => import('./components/Layout'));
const SessionLayout = loadable(() => import('./components/Layout/BuyingProcess/Session'));
const PrepToolsLayout = loadable(() => import('./components/Layout/BuyingProcess/PrepTools'));

const HomePage = loadable(() => import('./pages/Home'));
const Login = loadable(() => import('./pages/Login'));
const ErrorPage = loadable(() => import('./pages/Error'));

const Registration = loadable(() => import('./pages/Registration'));
const Country = loadable(() => import('./pages/Country'));
const ForgottenPassword = loadable(() => import('./pages/ForgottenPassword'));
const ResetPassword = loadable(() => import('./pages/ResetPassword'));

const IncomingAppointmentsSessions = loadable(() => import('./pages/Account/IncomingAppointmentsSessions'));
const PastAppointmentsSessions = loadable(() => import('./pages/Account/PastAppointmentsSessions'));
const Information = loadable(() => import('./pages/Account/Information'));
const Addresses = loadable(() => import('./pages/Account/Addresses'));
const Purchases = loadable(() => import('./pages/Account/Purchases'));

const UploadCandidatePicturePage = loadable(() => import('./pages/UploadCandidatePicture'));

const BuyingProcessLayout = loadable(() => import('./pages/BuyingProcess/Layout'));
const SessionRegister = loadable(() => import('./pages/BuyingProcess/Register'));
const SessionCrossSelling = loadable(() => import('./pages/BuyingProcess/CrossSelling'));
const PurchaseSummary = loadable(() => import('./pages/BuyingProcess/Summary'));
const PurchasePayment = loadable(() => import('./pages/BuyingProcess/Payment'));

const Blog = loadable(() => import('./pages/Blog'));
const Article = loadable(() => import('./pages/Blog/Article'));
const LegalInformation = loadable(() => import('./pages/LegalInformation'));
const HelpCenter = loadable(() => import('./pages/HelpCenter'));
const Contact = loadable(() => import('./pages/Contact'));
const ContactLead = loadable(() => import('./pages/ContactLead'));
const Profile = loadable(() => import('./pages/Profile'));
const Programme = loadable(() => import('./pages/Programme'));
const TestTypeFamily = loadable(() => import('./pages/TestTypeFamily'));
const PrepTools = loadable(() => import('./pages/PrepTools'));
const PrepToolPage = loadable(() => import('./pages/PrepTool'));
const Newsletter = loadable(() => import('./pages/Newsletter'));
const PurchaseFinalisation = loadable(() => import('./pages/BuyingProcess/PurchaseFinalisation'));
const TestLandingPage = loadable(() => import('./pages/TestLandingPage'));
const ImpersonateUser = loadable(() => import('./pages/ImpersonateUser'));
const PracticeTest = loadable(() => import('./pages/PracticeTest'));
const PracticeTestConclusionForm = loadable(() => import('./pages/PracticeTestConclusionForm'));
const PracticeTestResultPage = loadable(() => import('./pages/PracticeTestResultPage'));
const DigitalScoreReport = loadable(() => import('./pages/DigitalScoreReport'));
const RedirectSessionSearch = loadable(
  () => import('./containers/RedirectSessionSearchOrPartnerSearch/RedirectSessionSearch')
);
const RedirectPartnerSearch = loadable(
  () => import('./containers/RedirectSessionSearchOrPartnerSearch/RedirectPartnerSearch')
);

const routes = [
  {
    path: paths.UPLOAD_CANDIDATE_PICTURE,
    exact: true,
    component: UploadCandidatePicturePage,
  },
  {
    path: paths.LANDING_PAGE_TOEIC_IN_AUTONOMY,
    exact: true,
    component: TestLandingPage,
    templatePage: 'landing page toeic in autonomy',
    isHomeTest: true,
    cache: {
      noCache: true,
    },
    skipDefaultEventLoadedPage: true,
  },
  {
    path: paths.LANDING_PAGE_REMOTE_PROCTORED_TEST,
    exact: true,
    component: TestLandingPage,
    templatePage: 'landing page test',
    cache: {
      noCache: true,
    },
    skipDefaultEventLoadedPage: true,
  },
  {
    path: paths.DIGITAL_SCORE_REPORT,
    component: DigitalScoreReport,
    cache: {
      sMaxAge: 60,
    },
  },
  {
    path: paths.HOME,
    logoIsWhite: true,
    component: Layout,
    routes: [
      {
        path: paths.HOME,
        exact: true,
        component: HomePage,
        logoIsWhite: true,
        templatePage: 'homepage',
      },
      {
        path: paths.FORGOTTEN_PASSWORD,
        exact: true,
        component: GoogleRecaptchaV3,
        routes: [
          {
            component: cantAccessLogged(ForgottenPassword),
          },
        ],
      },
      {
        path: paths.RESET_PASSWORD,
        exact: true,
        component: cantAccessLogged(ResetPassword),
      },
      {
        path: paths.COUNTRY,
        exact: true,
        component: Country,
      },
      {
        path: paths.REGISTRATION,
        exact: true,
        component: GoogleRecaptchaV3,
        routes: [
          {
            component: cantAccessLogged(Registration),
          },
        ],
      },
      {
        path: paths.LOGIN,
        exact: true,
        component: GoogleRecaptchaV3,
        routes: [
          {
            component: cantAccessLogged(Login),
          },
        ],
      },
      {
        path: paths.ACCOUNT,
        exact: true,
        component: authenticated(IncomingAppointmentsSessions),
        templatePage: 'account pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.ACCOUNT_INCOMING_APPOINTMENTS_SESSIONS,
        exact: true,
        component: authenticated(IncomingAppointmentsSessions),
        templatePage: 'account pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.ACCOUNT_PAST_APPOINTMENTS_SESSIONS,
        exact: true,
        component: authenticated(PastAppointmentsSessions),
        templatePage: 'account pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.ACCOUNT_INFORMATION,
        exact: true,
        component: authenticated(Information),
        templatePage: 'account pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.ACCOUNT_PURCHASES,
        exact: true,
        component: authenticated(Purchases),
        templatePage: 'account pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.ACCOUNT_ADDRESSES,
        exact: true,
        component: authenticated(Addresses),
        templatePage: 'account pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.SESSION,
        // eslint-disable-next-line react/display-name
        render: ({ route }) => <SessionLayout>{renderRoutes(route.routes)}</SessionLayout>,
        routes: [
          {
            path: paths.SESSION_SEARCH,
            exact: true,
            component: RedirectSessionSearch,
            logoIsWhite: true,
            templatePage: 'session search pages',
          },
          {
            path: paths.SESSION_REGISTER_LAYOUT,
            component: BuyingProcessLayout,
            logoIsWhite: true,
            routes: [
              {
                path: paths.SESSION_REGISTER,
                exact: true,
                component: basketWithRegistrationIdReplacer(SessionRegister, paths.SESSION_REGISTER_BY_REGISTRATION_ID),
                logoIsWhite: true,
                templatePage: 'session search pages',
              },
              {
                path: paths.SESSION_REGISTER_BY_REGISTRATION_ID,
                exact: true,
                component: SessionRegister,
                logoIsWhite: true,
                templatePage: 'session search pages',
                cache: {
                  noCache: true,
                },
              },
              {
                path: paths.SESSION_CROSS_SELLING,
                exact: true,
                component: SessionCrossSelling,
                logoIsWhite: true,
                templatePage: 'session search pages',
                cache: {
                  noCache: true,
                },
              },
            ],
          },
          {
            component: ErrorPage,
          },
        ],
      },
      {
        path: paths.PURCHASE_SUMMARY,
        exact: true,
        component: PurchaseSummary,
        logoIsWhite: true,
        templatePage: 'checkout pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.PURCHASE_PAYMENT,
        exact: true,
        component: PurchasePayment,
        templatePage: 'checkout pages',
        logoIsWhite: true,
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.PURCHASE_PAYMENT_FINALISATION,
        exact: true,
        component: PurchaseFinalisation,
        logoIsWhite: true,
        templatePage: 'checkout pages',
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.HELP_CENTER,
        exact: true,
        component: HelpCenter,
        templatePage: 'faq pages',
      },
      {
        path: paths.CONTACT,
        exact: true,
        component: GoogleRecaptchaV3,
        routes: [
          {
            component: Contact,
          },
        ],
      },
      {
        path: paths.CONTACT_LEAD,
        exact: true,
        component: ContactLead,
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.CONTACT_ORGANISATION,
        exact: true,
        component: GoogleRecaptchaV3,
        routes: [
          {
            component: Contact,
          },
        ],
      },
      {
        path: paths.PARTNER_SEARCH,
        exact: true,
        component: RedirectPartnerSearch,
      },
      {
        path: paths.BLOG,
        exact: true,
        // eslint-disable-next-line react/display-name
        render: ({ location, history }) => {
          const params = getParams(location.search);

          return <Blog params={params} history={history} />;
        },
        templatePage: 'blog pages',
      },
      {
        path: paths.BLOG_ARTICLE,
        exact: true,
        component: Article,
        templatePage: 'blog pages',
      },
      {
        path: paths.BASIC_CONTENT,
        exact: true,
        component: Article,
      },
      {
        path: paths.LANDING_PAGE,
        exact: true,
        component: Article,
        templatePage: 'landing pages',
      },
      {
        path: paths.LANDING_PAGE_PARTNER,
        exact: true,
        component: Article,
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.LEGAL_INFORMATION,
        exact: true,
        component: LegalInformation,
      },

      {
        path: paths.PROFILE,
        exact: true,
        component: Profile,
        logoIsWhite: true,
        templatePage: 'profile pages',
      },
      {
        path: paths.PROGRAMME,
        exact: true,
        component: Programme,
        logoIsWhite: true,
        templatePage: 'programme pages',
      },
      {
        path: paths.TEST_TYPE_FAMILY,
        exact: true,
        component: TestTypeFamily,
        logoIsWhite: true,
        templatePage: 'test pages',
        skipDefaultEventLoadedPage: true,
      },
      {
        path: paths.PREP_TOOLS_LAYOUT,
        // eslint-disable-next-line react/display-name
        render: (props) => (
          <PrepToolsLayout>
            <BuyingProcessLayout {...props} />
          </PrepToolsLayout>
        ),
        logoIsWhite: true,
        routes: [
          {
            path: paths.PREP_TOOLS,
            exact: true,
            component: basketWithRegistrationIdReplacer(PrepTools, paths.PREP_TOOLS_BY_REGISTRATION_ID),
            templatePage: 'prep tools page',
            logoIsWhite: true,
          },
          {
            path: paths.PREP_TOOLS_BY_REGISTRATION_ID,
            exact: true,
            component: PrepTools,
            logoIsWhite: true,
            templatePage: 'prep tools page',
            cache: {
              noCache: true,
            },
          },
        ],
      },
      {
        path: paths.PREP_TOOL,
        logoIsWhite: true,
        exact: true,
        component: PrepToolPage,
        templatePage: 'prep tools page',
      },
      {
        path: paths.NEWSLETTER,
        exact: true,
        component: cantAccessLogged(Newsletter),
      },
      {
        path: paths.IMPERSONATE_USER,
        exact: true,
        component: ImpersonateUser,
        isInSitemap: false,
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.PRACTICE_TEST,
        logoIsWhite: true,
        exact: true,
        component: PracticeTest,
      },
      {
        path: paths.PRACTICE_TEST_CONCLUSION_FORM,
        exact: true,
        component: authenticated(PracticeTestConclusionForm),
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.PRACTICE_TEST_RESULT_PAGE,
        exact: true,
        component: authenticated(PracticeTestResultPage),
        cache: {
          noCache: true,
        },
      },
      {
        path: paths.ERROR,
        component: ErrorPage,
      },
      {
        component: ErrorPage,
      },
    ],
  },
];

export default routes;
